import React from "react";

import { Table, Modal, ModalBody, ModalFooter } from "reactstrap";
import { Button } from "antd";
import TheadComponent from "./TheadComponent/TheadComponent";

import classes from "./GarantiesModal.module.css";

function GarantiesModal(props) {
  const { modal, toggle, proposition, tarif } = props;

  const HospitalisationTitles = ["Honoraires", "Chambre particulière", "Soins"];

  const dentaire = ["Soins", "Prothèse", "Orthodontie"];

  const OptiqueTitles = [
    "Monture + Verres simples",
    "Monture + Verres complexes",
    "Lentille remboursé par la ss",
    "Chirurgie refractive",
  ];

  const SoinsCourantsTitles = [
    "Consultations",
    "Labo ",
    " Auxiliaires medicaux",
    "Médecine alternative",
    "Radiologie",
  ];

  const pharmacieTitles = [
    "Médicaments - Important",
    "Médicaments - Rendu modéré ",
    "Médicaments - Rendu faible",
    "Pharmacie non remboursée",
  ];
  const oreilleTitles = ["Audio prothése "];
  return proposition?.garanties ? (
    <div>
      <Modal size="lg" isOpen={modal} toggle={toggle}>
        <ModalBody>
          <Table
            style={{
              marginTop: "10px",
            }}
          >
            <thead style={{ backgroundColor: "#0f175e" }}>
              <tr>
                <th colSpan={2}>
                  <TheadComponent
                    tarif={tarif}
                    option={proposition?.produit?.produit_sante?.option}
                    nom_produit={
                      proposition?.produit?.produit_sante?.nom_produit
                    }
                    proposition={proposition}
                  />
                </th>
              </tr>
            </thead>

            <tbody>
              <tr className={classes.table_th}>
                <th colSpan={2}>
                  <img
                    alt="Hospitalisation"
                    src="https://mutuelle-direct.cloud.geoprod.com/images/garanties/Hospitalisation_2.png"
                  ></img>
                  HOSPITALISATION
                </th>
              </tr>

              {HospitalisationTitles?.map((title, index) => (
                <tr key={index}>
                  <td className={classes.propositionTableItem}>{title}</td>
                  <td key={index}>
                    {
                      proposition?.garanties?.find((a) => a?.libelle === title)
                        ?.valeur_affiche
                    }
                  </td>
                </tr>
              ))}
              <tr className={classes.table_th} style={{ width: "100%" }}>
                <th colSpan={2}>
                  <img
                    alt="DENTAIRE"
                    src="https://mutuelle-direct.cloud.geoprod.com/images/garanties/Dentaire_2.png"
                  ></img>
                  DENTAIRE
                </th>
              </tr>

              {dentaire?.map((title, index) => (
                <tr key={index}>
                  <td className={classes.propositionTableItem}>{title}</td>

                  <td key={index}>
                    {
                      proposition?.garanties.find((a) => a?.libelle === title)
                        ?.valeur_affiche
                    }
                  </td>
                </tr>
              ))}
              <tr className={classes.table_th} style={{ width: "100%" }}>
                <th
                  className={classes.table_th}
                  style={{ width: "100%" }}
                  colSpan={2}
                >
                  <img
                    alt="Optique"
                    src="https://mutuelle-direct.cloud.geoprod.com/images/garanties/Optique_2.png"
                  ></img>
                  OPTIQUE
                </th>
              </tr>

              {OptiqueTitles?.map((title, index) => (
                <tr key={index}>
                  <td className={classes.propositionTableItem}>{title}</td>

                  <td key={index}>
                    {
                      proposition?.garanties.find((a) => a?.libelle === title)
                        ?.valeur_affiche
                    }
                  </td>
                </tr>
              ))}
              <tr className={classes.table_th} style={{ width: "100%" }}>
                <th colSpan={2}>
                  <img
                    alt="SOINS COURANTS"
                    src="https://mutuelle-direct.cloud.geoprod.com/images/garanties/Soins courants_2.png"
                  ></img>
                  SOINS COURANTS
                </th>
              </tr>

              {SoinsCourantsTitles?.map((title, index) => (
                <tr key={index}>
                  <td className={classes.propositionTableItem}>{title}</td>

                  <td key={index}>
                    {
                      proposition?.garanties.find((a) => a?.libelle === title)
                        ?.valeur_affiche
                    }
                  </td>
                </tr>
              ))}
              <tr className={classes.table_th} style={{ width: "100%" }}>
                <th colSpan={2}>
                  <img
                    alt="pharmacie"
                    src="https://mutuelle-direct.cloud.geoprod.com/images/garanties/Pharmacie_2.png"
                  ></img>
                  PHARMACIE
                </th>
              </tr>

              {pharmacieTitles?.map((title, index) => (
                <tr key={index}>
                  <td className={classes.propositionTableItem}>{title}</td>

                  <td key={index}>
                    {
                      proposition?.garanties.find((a) => a?.libelle === title)
                        ?.valeur_affiche
                    }
                  </td>
                </tr>
              ))}
              <tr className={classes.table_th}>
                <th colSpan={2}>
                  <img
                    alt="oreille"
                    src="https://sdn.geoprod.com/static/uploads/cmp/353508955/1665402132.160585__ear.png"
                  ></img>
                  Audio prothése
                </th>
              </tr>
              {oreilleTitles?.map((title, index) => (
                <tr key={index}>
                  <td className={classes.propositionTableItem}>{title}</td>
                  <td key={index}>
                    {
                      proposition?.garanties.find((a) => a?.libelle === title)
                        ?.valeur_affiche
                    }
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </ModalBody>
        <ModalFooter>
          <Button
            size="large"
            type="primary"
            style={{ backgroundColor: "#8c8c8c", color: "white" }}
            onClick={toggle}
          >
            Fermer
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  ) : (
    <></>
  );
}

export default GarantiesModal;
