import "./App.css";
import AppContext from "./contexts/AppContext";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import LayoutAdhesion from "./components/LayoutAdhesion/LayoutAdhesion";

import Tarificateur from "./pages/Tarificateur/Tarificateur";
import LayoutTarificateur from "./components/LayoutTarificateur/LayoutTarificateur";
import DevisTarificateur from "./pages/Tarificateur/DevisTarificateur/DevisTarificateur";
import Adhesion from "./pages/Adhesion/Adhesion";
import Success from "./pages/Adhesion/Success/Success";
import React from "react";
import NotFound from "./pages/NotFound/NotFound";
//a
import * as Sentry from "@sentry/react";
import ErrorPage from "./pages/ErrorPage/ErrorPage";
function App() {
  React.useEffect(() => {
    () => sessionStorage.clear();
  }, []);

  return (
    <Sentry.ErrorBoundary fallback={ErrorPage} showDialog>
      <AppContext>
        <BrowserRouter>
          <Routes>
            <Route path="/*" element={<NotFound />} />
            <Route path="/tarificateur" element={<LayoutTarificateur />}>
              <Route path="/tarificateur" element={<Tarificateur />} />
              <Route
                path="/tarificateur/:param_id"
                element={<Tarificateur />}
              />
            </Route>
            <Route path="/devisTarificateur" element={<DevisTarificateur />} />
            <Route path="/adhesion" element={<LayoutAdhesion />}>
              <Route path="/adhesion" element={<Adhesion />} />
              <Route path="/adhesion/success" element={<Success />} />
            </Route>
            <Route path="/error" element={<ErrorPage />} />
          </Routes>
        </BrowserRouter>
      </AppContext>
    </Sentry.ErrorBoundary>
  );
}

export default App;
