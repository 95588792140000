// import axios from 'axios';
// import { notification } from 'antd';
// const apiHost = import.meta.env.VITE_APP_APIURL;
// const axiosInstance = axios.create({ baseURL: apiHost });

// axiosInstance.interceptors.response.use(
//   (response) => {
//     return response;
//   },
//   function (error) {
//     if (error.response)
//       if (error.response.status === 400) {
//         notification.warning({
//           message: 'Warning',
//           description:
//             "Non-éligibilité en raison d'une mesure de Curatelle ou de Tutelle.",
//         });
//       } else return Promise.reject(error);
//     else return Promise.reject(error);
//   }
// );

// export default axiosInstance;

import axios from "axios";
import { notification } from "antd";
const axiosInstance = axios.create();
axiosInstance.interceptors.response.use(
  (response) => {
    if (response?.data?.erreur) {
      notification.error({
        message: "Erreur",
        duration: 0,
        closable: true,
        description:
          response?.data?.error_message && response?.data?.message
            ? response?.data?.message
            : "Désolé, une erreur est survenue. Veuillez réessayer ultérieurement.",
      });

      return Promise.reject(response);
    } else if (response?.data?.error) {
      notification.error({
        message: "Erreur",
        duration: 0,
        closable: true,
        description:
          response?.data?.error_message && response?.data?.message
            ? response?.data?.message
            : "Désolé, une erreur est survenue. Veuillez réessayer ultérieurement.",
      });

      return Promise.reject(response);
    } else {
      return response;
    }
  },
  (error) => {
    const originalRequest = error.config;
    if (error.response)
      if (error.response.status === 401 && !originalRequest._retry) {
        originalRequest._retry = true;
        const headers = {
          Authorization: "Bearer " + localStorage.getItem("api_key"),
        };
        return axios
          .post(
            `${import.meta.env.VITE_APP_GEOPROD_APIURL}login`,
            {},
            {
              headers: headers,
            }
          )
          .then((res) => {
            if (res.status === 200) {
              // 1) put token to LocalStorage
              localStorage.setItem("token", res.data?.Acces_Token);
              // 2) Change Authorization header
              axios.defaults.headers.common["idSession"] =
                localStorage.getItem("token");
              originalRequest.headers["idSession"] =
                localStorage.getItem("token");
              // 3) return originalRequest object with Axios.
              return axios(originalRequest);
            }
          });
      } else {
       
        notification.error({
          message: "Erreur",
          duration: 0,
          closable: true,
          description:
            error.response?.data?.message && error.response?.data?.error_message
              ? error.response?.data?.message
              : "Désolé, une erreur est survenue. Veuillez réessayer ultérieurement.",
        });

        return Promise.reject(error);
      }
    else return error;
  }
);

export default axiosInstance;
