import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import classes from "./SendEmail.module.css";
import { Row, Col, Container } from "reactstrap";
import EditorText from "./EditorText/EditorText";
import PreviewEmail from "./PreviewEmail/PreviewEmail";
import GlobalContext from "../../../contexts/GlobalContext";
import { Button } from "antd";
import {
  Alert,
  Backdrop,
  Box,
  CircularProgress,
  Snackbar,
} from "@mui/material";
import Swal from "sweetalert2";
import moment from "moment";
import SessionStorageService from "../../../utils/sessionStorageService";
import axios from "../../../utils/axios";
function SendEmail(props) {
  const {
    modal,
    toggle,
    setModal,
    type,
    generatedDevis,
    setSpiningMail,
    SpiningMail,
    url,
    idReprise,
    tableau_garantie,
  } = props;
  //eslint-disable-next-line
  const {
    destination,
    editorMessage,
    setEditorMessage,
    templateDevis,
    // products,
    objetEmail,
    repriseData,
  } = React.useContext(GlobalContext);
  const [openEmail, setOpenEmail] = useState();
  const [openEmailEmpty, setOpenEmailEmpty] = useState();
  const prospectInfo = SessionStorageService().getEmailProspect();
  function isValidEmail() {
    var regex = new RegExp(/^[\w-\.]+@([\w-]+\.)+[a-zA-Z]{2,4}$/)
    let validEmail = regex.test(destination);

    return validEmail;
  }
  const handleEpmtyEmailRequirements = () => {
    setOpenEmailEmpty(true);
  };
  const handleCloseEmailEmpty = () => {
    setOpenEmailEmpty(false);
  };
  const handleRequirementsEmail = () => {
    setOpenEmail(true);
  };
  const handleCloseEmail = () => {
    setOpenEmail(false);
  };
  const { proposition } = repriseData;

  async function fetchAndConvertToBase64(name, url) {
    return await fetch(url)
      .then((response) => response?.blob())
      .then((blob) => {
        return new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.onloadend = () => resolve(reader.result);
          reader.onerror = reject;
          reader.readAsDataURL(blob);
        });
      })
      .then((result) => {
        let b64 = result?.split(",")[1];
        return {
          type: "b64",
          name: name,
          content: b64,
        };
      });
  }

  const convertAndSendAllDocs = async (doc_to_send) => {
    let time = moment().format("MM_DD_YYYY_HH_MM_SS_A");
    const results = await Promise?.all(
      Object?.entries(doc_to_send)
        .filter(([name, url]) => url)
        .map(async ([name, url]) => {
          return fetchAndConvertToBase64(
            name === "Devis"
              ? `Devis_${time}.pdf`
              : `${decodeURIComponent(url?.split("/")?.pop())}`,
            await url
          );
        })
    );
    return results;
  };

  const EnvoyerMail = async (doc_to_send) => {
    let template_to_send = templateDevis.replace(
      "*|FNAME|*",
      prospectInfo?.surname ? prospectInfo?.surname : ""
    );
    template_to_send = template_to_send.replace(
      "*|LNAME|*",
      prospectInfo?.name ? prospectInfo?.name : ""
    );

    template_to_send = template_to_send.replace(
      "[NOM OFFRE]",
      type === "tarificateur" ? proposition?.produit?.produit_sante?.option : ""
    );
    template_to_send = template_to_send.replace(
      "[NOM PRODUIT]",
      type === "tarificateur"
        ? (`ACHEEL - ${proposition?.produit?.produit_sante?.nom_produit}`)
        : ""
    );

    convertAndSendAllDocs(doc_to_send)?.then((attachments) => {
      const partnership_id = localStorage.getItem("api_key");
      axios
        .post(
          `${
            import.meta.env.VITE_APP_CLOUD_GEOPROD_APIURL
          }/mailchimp/send_mail`,
          {
            recipient: destination,
            subject: objetEmail,
            html: template_to_send,
            attachments: [...attachments],
            sender: "devis@as-solutions.fr",
            body: "",
            id_opp: sessionStorage.getItem("SessionURLID"),
            url: `${window.location.origin}/tarificateur?partnership_id=${partnership_id}&ref=${idReprise}`,
          },
          {
            headers: {
              idSession: `${localStorage.getItem("token")}`,
            },
          }
        )
        .then((res) => {
          if (res?.data?.error === false) {
            setSpiningMail(false);
            setModal(false);
            Swal.fire({
              position: "center",
              icon: "success",
              title: "E-mail envoyé avec succès !",
              showConfirmButton: false,
              timer: 1500,
            });
          } else {
            setModal(false);
            setSpiningMail(!SpiningMail);
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Quelque chose s'est mal passé !",
            });
          }
        })
        .catch(() => setSpiningMail(false));
    });
  };
  const handleClick = async () => {
    if (destination === "") {
      handleEpmtyEmailRequirements();
    } else if (isValidEmail() === false) {
      handleRequirementsEmail();
    } else {
      setSpiningMail(!SpiningMail);
      setModal(true);
      if (type === "tarificateur") {
        generatedDevis("email").then((response) => {
          if (response) {
            const doc_to_send = {
              Devis: response?.devis,
              info: response?.info,
              tableau_garantie: tableau_garantie,
              ipid_pj: import.meta.env?.VITE_APP_CDN_IPID_PJ,
              cg_pj: import.meta.env?.VITE_APP_CDN_CG_PJ_URL,
              ipid_sante: import.meta.env?.VITE_APP_CDN_IPID_ACHEEL,
              cg_sante: import.meta.env?.VITE_APP_CDN_CG_ACHEEL_URL,
            };
            EnvoyerMail(doc_to_send);
          }
        });
      } else {
        const doc_to_send = {
          Devis: url,
        };
        EnvoyerMail(doc_to_send);
      }
    }
  };

  return (
    <>
      <Snackbar
        open={openEmailEmpty}
        autoHideDuration={3000}
        onClose={handleCloseEmailEmpty}
      >
        <Alert
          onClose={handleCloseEmailEmpty}
          severity="error"
          sx={{ width: "100%" }}
        >
          Veuillez remplir l'adresse mail.
        </Alert>
      </Snackbar>
      <Snackbar
        open={openEmail}
        autoHideDuration={3000}
        onClose={handleCloseEmail}
      >
        <Alert
          onClose={handleCloseEmail}
          severity="error"
          sx={{ width: "100%" }}
        >
          Veuillez vérifier l'adresse mail.
        </Alert>
      </Snackbar>

      <Backdrop
        sx={{
          color: "#fff",
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
        open={SpiningMail}
        /* onClick={handleCloseBackdrop} */
      >
        <div className={classes.loaderContainer}>
          <Box sx={{ width: "100%" }}>
            <CircularProgress style={{ width: "4rem", height: "4rem" }} />
          </Box>
          <h2>Veuillez patienter un instant.</h2>
        </div>
      </Backdrop>

      <Modal show={modal} fullscreen onHide={toggle}>
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <Container fluid className={classes.contain}>
            <Row>
              <Col xs="12" lg="6">
                <EditorText
                  message={editorMessage}
                  onChange={setEditorMessage}
                />
              </Col>
              <Col xs="12" lg="6">
                <PreviewEmail
                  message={editorMessage}
                  url={props.url}
                  type={type}
                />
              </Col>
            </Row>
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <Button
            type="primary"
            size="large"
            variant="primary"
            onClick={handleClick}
          >
            Envoyer
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default SendEmail;
