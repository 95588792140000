import React, { useState } from "react";
import classes from "./Adhesion.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { Modal, Checkbox, Row, Col, notification } from "antd";
import { Button } from "antd";
import Box from "@mui/material/Box";
import Assure from "./Assure/Assure";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import FicheConseil from "./FicheConseil/FicheConseil";
import Garanties from "./Garanties/Garanties";
import GlobalContext from "../../contexts/GlobalContext";
import Echeance from "./Echeance/Echeance";
import Conjoint from "./Conjoint/Conjoint";
import SessionStorageService from "../../utils/sessionStorageService";
import Enfant from "./Enfant/Enfant";
import axios from "../../utils/axios";
import { Backdrop } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import moment from "moment";
import Swal from "sweetalert2";
import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons";
import { CircularProgress } from "@mui/material";
import ResiliationPopup from "./ResiliationPopup/ResiliationPopup";
import sign from "../../assets/signature 1.svg";
import saveIcon from "../../assets/saveIcon.png";
import { alertMessage } from "../../utils/alertFunction";
import dayjs from "dayjs";
import { checkSelectedGamme } from "../../utils/utils";

const Adhesion = (props) => {
  const [BanquePrelevement, setBanquePrelevement] = React.useState("");
  const [BanqueRemboursement, setBanqueRemboursement] = React.useState("");
  const params = useLocation();
  const [check, setCheck] = useState(false);
  const [modal, setModal] = useState(false);
  const [openbackdrop, setOpenbackdrop] = React.useState(false);
  // // const [openRemb, setOpenRemb] = useState("");
  const {
    data,
    setData,
    optionHorsSante,
    setOptionHorsSante,
    dataAdhesion,
    setDataAdhesion,
    resiliation,
    fraisLie,
    fraisAesio,
    fraisCourtage,
    organisme,
    valueChecked,
    // setResiliation,
    // dateEffet,
  } = React.useContext(GlobalContext);
  const navigate = useNavigate();

  /* const { optionHorsSante, proposition } = params.state; */
  // const [optionHorsSante, setOptionHorsSante] = React.useState([]);
  const [proposition, setProposition] = React.useState([]);
  const [stage, setStage] = React.useState(0);

  const [trigger, setTrigger] = React.useState(false);
  const handleTriggerModal = () => {
    setTrigger(!trigger);
  };
  const jumeauOptions = [
    { label: "Oui", value: "Oui" },
    { label: "Non", value: "Non" },
  ];

  let detailsData;
  // eslint-disable-next-line no-unused-vars

  let cfdpttc = 0;
  let cfdpht = 0;
  let cfdptax_ass = 0;
  let assisstancettc = 0;
  let assisstanceht = 0;
  let mutuaide_taxe1 = 0;

  let annuel_ht = 0;
  let taxeTSA = 0;
  let amountTtc = 0;
  let annuel_taxe = 0;

  let fraisAssociatif = 0;

  let childsNbr = 0;
  let conjointCount = 0;
  let exerceequalite = SessionStorageService().getProspect().situation;

  const id = localStorage.getItem("api_key");
  const [loading, setLoading] = React.useState(false);
  const [confirmationButton, setConfirmationButton] = useState("Enregistrer");
  const [customerInfo, setCustomerInfo] = useState(
    SessionStorageService().getCustomerInfo()
  );
  const [tempFils, setTempFils] = useState([]);
  const Fils = [];
  const [nbrChild, setnbrChild] = useState(0);

  const familyCalc = (costInf) => {
    costInf.map((CI) => {
      if (CI?.relationship === "children") {
        childsNbr++;
        Fils.push(CI);
      } else if (CI?.relationship === "married") {
        conjointCount++;
      }
      return null;
    });
    setTempFils(Fils);
    setnbrChild(childsNbr);
    if (conjointCount === 0 && childsNbr === 0) {
      let array = steps;
      array.splice(1, 2);
      setSteps(array);
    } else if (conjointCount !== 0 && childsNbr === 0) {
      let array = steps;
      array.splice(2, 1);
      setSteps(array);
    } else if (conjointCount === 0 && childsNbr !== 0) {
      let array = steps;
      array.splice(1, 1);
      setSteps(array);
    }
  };

  React.useEffect(() => {
    const id = new URLSearchParams(window.location.search).get("IdAdhesion");
    if (id) {
      axios
        .get(
          `${
            import.meta.env.VITE_APP_CLOUD_GEOPROD_APIURL
          }/session_url/get_object_from_session_url/${id}`
        )
        .then((res) => {
          sessionStorage.setItem(
            "_subs_tags",
            JSON.stringify(res.data?._subs_tags)
          );
          setOptionHorsSante(res.data?.optionHorsSante);
          setProposition(res.data?.product);
          sessionStorage.setItem("SessionURLID", res.data?.id_opp);
          SessionStorageService().setCustomerInfo(res.data?.customerInfo);
          sessionStorage.setItem("product", JSON.stringify(res.data?.product));
          SessionStorageService().setEmailProspect(res.data?.assure);
          setLoading(true);
          setCustomerInfo(res.data?.customerInfo);
          familyCalc(res.data?.customerInfo);
        })
        .catch((err) => {
          console.log(err);
        });
    } else if (params.state) {
      setOptionHorsSante(params.state.optionHorsSante);
      setProposition(params.state.proposition);
      setLoading(true);
    }
    if (!id) {
      customerInfo && familyCalc(customerInfo);
    }
    () => sessionStorage.clear();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = (e) => {
    let prospect = SessionStorageService().getProspect();
    prospect[e.target.name] = e.target.value;
    SessionStorageService().setProspect(prospect);
    let key = e.target.name;
    setDataAdhesion((prev) => {
      return {
        ...prev,
        [key]: e.target.value,
      };
    });
  };

  const childs = [
    {
      civilite: "civilite_enfant1||Civilité||0",
      nom: "nom_enfant1||Nom||1",
      prenom: "prenom_enfant1||Prénom||1",
      DN: "dn_enfant1||Date de naissance||3",
      regime: "ro_enfant1||Régime||4",
      nsociale: "num_sec_enfant1||N° sécurité sodiale||6",
      code_organisme: "code_organisme1",
      attach: "attach1",
      Qui: "qui1",
      LIEU_NAISSANCE: "lieu_naissance_1",
      JUMEAU: "jumeau_meme_sexe_1",
      HANDICAPE: "enfant_handicape_1",
      Rang: "enfant_rang_1",
    },
    {
      civilite: "civilite_enfant2||Civilité||0",
      nom: "nom_enfant2||Nom||1",
      prenom: "prenom_enfant2||Prénom||1",
      DN: "dn_enfant2||Date de naissance||3",
      regime: "ro_enfant2||Régime||4",
      nsociale: "num_sec_enfant2||N° sécurité sodiale||6",
      code_organisme: "code_organisme2",
      attach: "attach2",
      Qui: "qui2",
      LIEU_NAISSANCE: "lieu_naissance_2",
      JUMEAU: "jumeau_meme_sexe_2",
      HANDICAPE: "enfant_handicape_2",
      Rang: "enfant_rang_2",
    },
    {
      civilite: "civilite_enfant3||Civilité||0",
      nom: "nom_enfant3||Nom||1",
      prenom: "prenom_enfant3||Prénom||1",
      DN: "dn_enfant3||Date de naissance||3",
      regime: "ro_enfant3||Régime||4",
      nsociale: "num_sec_enfant3||N° sécurité sodiale||6",
      code_organisme: "code_organisme3",
      attach: "attach3",
      Qui: "qui3",
      LIEU_NAISSANCE: "lieu_naissance_3",
      JUMEAU: "jumeau_meme_sexe_3",
      HANDICAPE: "enfant_handicape_3",
      Rang: "enfant_rang_3",
    },
    {
      civilite: "civilite_enfant4||Civilité||0",
      nom: "nom_enfant4||Nom||1",
      prenom: "prenom_enfant4||Prénom||1",
      DN: "dn_enfant4||Date de naissance||3",
      regime: "ro_enfant4||Régime||4",
      nsociale: "num_sec_enfant4||N° sécurité sodiale||6",
      code_organisme: "code_organisme4",
      attach: "attach4",
      Qui: "qui4",
      LIEU_NAISSANCE: "lieu_naissance_4",
      JUMEAU: "jumeau_meme_sexe_4",
      HANDICAPE: "enfant_handicape_4",
      Rang: "enfant_rang_4",
    },
    {
      civilite: "civilite_enfant5||Civilité||0",
      nom: "nom_enfant5||Nom||1",
      prenom: "prenom_enfant5||Prénom||1",
      DN: "dn_enfant5||Date de naissance||3",
      regime: "ro_enfant5||Régime||4",
      nsociale: "num_sec_enfant5||N° sécurité sodiale||6",
      code_organisme: "code_organisme5",
      attach: "attach5",
      Qui: "qui5",
      LIEU_NAISSANCE: "lieu_naissance_5",
      JUMEAU: "jumeau_meme_sexe_5",
      HANDICAPE: "enfant_handicape_5",
      Rang: "enfant_rang_5",
    },
    {
      civilite: "civilite_enfant6||Civilité||0",
      nom: "nom_enfant6||Nom||1",
      prenom: "prenom_enfant6||Prénom||1",
      DN: "dn_enfant6||Date de naissance||3",
      regime: "ro_enfant6||Régime||4",
      nsociale: "num_sec_enfant6||N° sécurité sodiale||6",
      code_organisme: "code_organisme6",
      attach: "attach6",
      Qui: "qui6",
      LIEU_NAISSANCE: "lieu_naissance_6",
      JUMEAU: "jumeau_meme_sexe_6",
      HANDICAPE: "enfant_handicape_6",
      Rang: "enfant_rang_6",
    },
  ];

  const [steps, setSteps] = React.useState([
    "Adhérent principal",
    "Conjoint(e)",
    "Enfant(s)",
    // "Courtier",
    // "Conseiller",
    "Récapitulatif",
    "Fiche conseil",
    "Informations bancaires",
  ]);
  // eslint-disable-next-line

  function isValidEmail(email) {
    var regex = new RegExp(/^[\w-\.]+@([\w-]+\.)+[a-zA-Z]{2,4}$/);

    let result = regex.test(email);

    return result;
  }
  function isValidPhonePrefix() {
    let prefReg = /^(\+)\d+$/;
    return prefReg.test(dataAdhesion["prefix_mobile_assure"].replace(/ /g, ""));
  }
  function validateCleNSS(nss) {
    nss = nss.replace("2A", "19").replace("2B", "18");
    let numericNIR = parseInt(nss.slice(0, -2), 10);
    let nirKey = 97 - (numericNIR % 97);
    let providedKey = parseInt(nss.slice(-2), 10);
    return nirKey === providedKey;
  }

  function isValidSS(SS) {
    var regex = new RegExp(
      /^[1-2][0-9]{2}(0[1-9]|1[0-2])([0-9]{2})[0-9]{3}[0-9]{3}([0-9]{2})$/
    );
    var validSS = regex.test(SS.replace(/ /g, ""));

    return validSS;
  }
  function isTrueSS(SS) {
    const ss = dataAdhesion["num_sec_assure||N° sécurité sodiale||6"];
    const Civi = dataAdhesion["civilite_assure||Civilité||0"];

    var Civilité = "";
    if (Civi === "M") Civilité = "1";
    else Civilité = "2";

    var year = moment(
      dataAdhesion["dn_assure||Date de naissance||3"],
      "DD/MM/YYYY"
    )
      .format("YYYY")
      .substring(2, 4);

    var month = moment(
      dataAdhesion["dn_assure||Date de naissance||3"],
      "DD/MM/YYYY"
    ).format("MM");
    if (
      ss.replace(/ /g, "").substring(0, 1) === Civilité &&
      ss.replace(/ /g, "").substring(1, 3) === year &&
      ss.replace(/ /g, "").substring(3, 5) === month &&
      validateCleNSS(ss.replace(/ /g, ""))
    ) {
      return true;
    } else {
      return false;
    }
  }
  const isValidName = (name) => {
    const regexName = new RegExp(
      /^[A-Za-zÀ-ÖØ-öø-ÿ]+(?:[’'-][A-Za-zÀ-ÖØ-öø-ÿ]+)*$/
    );

    return regexName.test(name?.replace(/ /g, ""));
  };
  function isNotFrenchIBAN(iban) {
    iban = iban.replace(/\s+/g, "").toUpperCase();

    // Check the length
    if (iban.length !== 27) {
      return true;
    }

    // Check the country code
    if (iban.substr(0, 2) !== "FR") {
      return true;
    }
    // Just checking if the last two characters are digits
    if (!/^\d{2}$/.test(iban.substr(-2))) {
      return true;
    }

    return false;
  }

  function isNotFrenchBIC(bic) {
    bic = bic.replace(/\s+/g, "").toUpperCase();

    // Check the length
    if (![8, 11].includes(bic.length)) {
      return true;
    }

    // Check the country code
    if (bic.substr(4, 2) !== "FR") {
      return true;
    }

    return false;
  }

  function isTrueSS2() {
    let ss = [
      dataAdhesion["num_sec_conjoint||N° sécurité sodiale||6"],
    ].toString();

    var year = moment(
      dataAdhesion["dn_conjoint||Date de naissance||3"],
      "DD/MM/YYYY"
    )
      .format("YYYY")
      .substring(2, 4);

    var month = moment(
      dataAdhesion["dn_conjoint||Date de naissance||3"],
      "DD/MM/YYYY"
    ).format("MM");
    const Civi = dataAdhesion["civilite_conjoint||Civilité||0"];

    var Civilité = "";
    if (Civi === "M") Civilité = "1";
    else Civilité = "2";
    if (
      ss.replace(/ /g, "").substring(0, 1) === Civilité &&
      ss.replace(/ /g, "").substring(1, 3) === year &&
      ss.replace(/ /g, "").substring(3, 5) === month &&
      validateCleNSS(ss.replace(/ /g, ""))
    ) {
      return true;
    } else {
      return false;
    }
  }

  function isTrueSSEnfant() {
    for (let index = 0; index < childs.length; index++) {
      const child = childs[index];
      let birth_date = dataAdhesion[child?.DN];
      let birth = moment(birth_date, "DD/MM/YYYY");
      let isAttached = dataAdhesion[child.attach];
      let age = moment().diff(birth, "years");
      if (index < nbrChild) {
        if (
          (dataAdhesion[child.nsociale].replace(/ /g, "") !==
            dataAdhesion["num_sec_assure||N° sécurité sodiale||6"].replace(
              / /g,
              ""
            ) &&
            dataAdhesion[child.nsociale].replace(/ /g, "") !==
              dataAdhesion["num_sec_conjoint||N° sécurité sodiale||6"].replace(
                / /g,
                "" && age < 18
              )) ||
          (!isAttached && age > 18)
        ) {
          let ss = dataAdhesion[child.nsociale].toString();

          var year = moment(dataAdhesion[child.DN], "DD/MM/YYYY")
            .format("YYYY")
            .substring(2, 4);

          var month = moment(dataAdhesion[child.DN], "DD/MM/YYYY").format("MM");

          const Civi = dataAdhesion[child.civilite];
          var Civilité = "";
          if (Civi === "M") Civilité = "1";
          else Civilité = "2";

          if (
            ss.replace(/ /g, "").substring(0, 1) !== Civilité ||
            ss.replace(/ /g, "").substring(1, 3) !== year ||
            ss.replace(/ /g, "").substring(3, 5) !== month ||
            !validateCleNSS(ss.replace(/ /g, ""))
          ) {
            return { valid: false, index: index + 1 };
          } else if (index === childsNbr - 1) {
            return { valid: true, index: index + 1 };
          }
        }
      }
    }
  }
  const checkTypes = [null, undefined, ""];

  const checkEnfantInputs = () => {
    var res = [];
    childs.forEach((child, index) => {
      if (index < nbrChild) {
        const Civi = dataAdhesion[child.civilite];
        const nom = dataAdhesion[child.nom];
        const prenom = dataAdhesion[child.prenom];
        const codeOrganisme = dataAdhesion[child.code_organisme];
        const numSecurite = dataAdhesion[child.nsociale];
        const jumeau = dataAdhesion[child.JUMEAU];
        const handicape = dataAdhesion[child.HANDICAPE];
        const rang = dataAdhesion[child.Rang];
        const LIEU_NAISSANCE = dataAdhesion[child.LIEU_NAISSANCE];
        var Civilité = "";
        if (Civi === "M") Civilité = "1";
        else Civilité = "2";
        if (
          checkTypes.includes(Civilité) ||
          checkTypes.includes(nom?.replaceAll(" ", "")) ||
          checkTypes.includes(prenom?.replaceAll(" ", "")) ||
          checkTypes.includes(numSecurite?.replaceAll(" ", "")) ||
          checkTypes.includes(codeOrganisme?.replaceAll(" ", "")) ||
          checkTypes.includes(jumeau) ||
          checkTypes.includes(handicape) ||
          checkTypes.includes(LIEU_NAISSANCE) ||
          (jumeau === "Oui" && checkTypes.includes(rang?.replaceAll(" ", "")))
        ) {
          return res.push(false);
        } else if (index === childsNbr - 1) {
          res.push(true);
        }
      }
    });
    if (res.includes(false)) {
      return false;
    } else if (res.includes(true)) {
      return true;
    }
  };
  const checkNames = (val) => {
    let res = [];
    let indexx = -1; // Initialize indexx to -1 (invalid index) in case all elements are valid

    childs.forEach((child, index) => {
      if (index < nbrChild) {
        const text = dataAdhesion[child[val]];
        const isValid = !isValidName(text);
        res.push(isValid);

        if (isValid && indexx === -1) {
          indexx = index + 1; // Update indexx only if it's still -1 (i.e., not updated yet)
        }
      }
    });

    const valid = res.includes(true);
    return { valid: valid, index: indexx };
  };

  function isValidCodeOrganismeEnfant() {
    for (let index = 0; index < childs.length; index++) {
      const child = childs[index];

      if (index < nbrChild) {
        if (
          dataAdhesion[child?.code_organisme]?.split(" ")?.join("")?.length !==
          9
        ) {
          return { valid: false, index: index + 1 };
        }
      }
    }
  }
  function isValidAgeEnfant() {
    for (let index = 0; index < childs.length; index++) {
      const child = childs[index];

      if (index < nbrChild) {
        let birth_date = dataAdhesion[child?.DN];
        let isHandicap = dataAdhesion[child.HANDICAPE];
        let isAttached = dataAdhesion[child.attach];
        let birth = moment(birth_date, "DD/MM/YYYY");
        let age = moment().diff(birth, "years");
        if (isHandicap == "Non" && age >= 26 && !isAttached) {
          return { valid: false, index: index + 1 };
        }
      }
    }
  }

  const handleChangeCheckbox = (e) => {
    setCheck(e.target.checked);
  };
  const toggleConfirmModal = () => {
    setModal(!modal);
  };
  const nextHandler = () => {
    let ss = dataAdhesion["num_sec_assure||N° sécurité sodiale||6"];
    let ssc = [dataAdhesion["num_sec_conjoint||N° sécurité sodiale||6"]];
    let checkTypes = [undefined, null, ""];
    if (stage < steps.length - 1) {
      if (steps[stage] === "Adhérent principal") {
        if (
          checkTypes.includes(
            dataAdhesion["civilite_assure||Civilité||0"]?.replaceAll(" ", "")
          ) ||
          checkTypes.includes(
            dataAdhesion["nom_assure||Nom||1"]?.replaceAll(" ", "")
          ) ||
          checkTypes.includes(
            dataAdhesion["prenom_assure||Prénom||2"]?.replaceAll(" ", "")
          ) ||
          checkTypes.includes(
            dataAdhesion["adresse_assure||Adresse||3"]?.replaceAll(" ", "")
          ) ||
          checkTypes.includes(
            dataAdhesion["dn_assure||Date de naissance||3"]?.replaceAll(" ", "")
          ) ||
          checkTypes.includes(
            dataAdhesion["cp_assure||Code postal||4"]?.replaceAll(" ", "")
          ) ||
          checkTypes.includes(
            dataAdhesion["ro_assure||Régime||4"]?.replaceAll(" ", "")
          ) ||
          checkTypes.includes(
            dataAdhesion["ville_assure||Ville||5"]?.replaceAll(" ", "")
          ) ||
          checkTypes.includes(
            dataAdhesion["num_sec_assure||N° sécurité sodiale||6"]?.replaceAll(
              " ",
              ""
            )
          ) ||
          checkTypes.includes(dataAdhesion["prefix_mobile_assure"]) ||
          checkTypes.includes(dataAdhesion["suffix_mobile_assure"]) ||
          checkTypes.includes(
            SessionStorageService()
              .getProspect()
              ["code_organisme_principal"]?.replaceAll(" ", "")
          ) ||
          checkTypes.includes(dataAdhesion["lieu_naissance_principal"]) ||
          !SessionStorageService()
            .getCustomerInfo()[0]
            .address?.num_voie?.replaceAll(" ", "") ||
          !SessionStorageService()
            .getCustomerInfo()[0]
            .address?.nom_voie?.replaceAll(" ", "") ||
          checkTypes.includes(
            dataAdhesion["mailassure_text"]?.replaceAll(" ", "")
          ) ||
          checkTypes.includes(
            SessionStorageService().getProspect()["jumeau_meme_sexe_principal"]
          ) ||
          (SessionStorageService().getProspect()[
            "jumeau_meme_sexe_principal"
          ] === "Oui" &&
            checkTypes.includes(
              SessionStorageService()
                .getProspect()
                ["rang_jumeau_meme_sexe_principal"]?.replaceAll(" ", "")
            ))
        ) {
          alertMessage(
            "Veuillez remplir tous les champs obligatoires.",
            "error"
          );
        } else if (
          !isValidName(SessionStorageService().getProspect().surname)
        ) {
          alertMessage("Veuillez vérifier votre prénom.", "error");
        } else if (
          !isValidName(SessionStorageService().getEmailProspect().name)
        ) {
          alertMessage("Veuillez vérifier votre nom.", "error");
        } else if (isValidEmail(dataAdhesion["mailassure_text"]) === false) {
          alertMessage("Veuillez vérifier votre adresse email.", "error");
        } else if (isValidPhonePrefix() === false) {
          alertMessage(
            "Veuillez vérifier votre préfixe de numéro de téléphone.",
            "error"
          );
        } 
        else if (isTrueSS(ss) === false) {
          alertMessage(
            "Le numéro de sécurité sociale n'est pas cohérent avec votre date de naissance et/ou votre civilité .",
            "error"
          );
        }
        //  else if (isValidSS(ss) === false) {
        //   alertMessage(
        //     "Veuillez vérifier votre numéro de sécurité sociale.",
        //     "error"
        //   );
        // }
         else if (
          SessionStorageService()
            .getProspect()
            ["code_organisme_principal"]?.split(" ")
            ?.join("")?.length !== 9
        ) {
          alertMessage(
            "Le code organisme doit comporter 9 caractères.",
            "error"
          );
        } else {
          notification.destroy();
          setStage(stage + 1);
        }
      } else if (steps[stage] === "Conjoint(e)") {
        if (
          checkTypes.includes(
            dataAdhesion.code_organisme_conjoint?.replaceAll(" ", "")
          ) ||
          checkTypes.includes(
            dataAdhesion[
              "num_sec_conjoint||N° sécurité sodiale||6"
            ]?.replaceAll(" ", "")
          ) ||
          checkTypes.includes(
            dataAdhesion["nom_conjoint||Nom||1"]?.replaceAll(" ", "")
          ) ||
          checkTypes.includes(
            dataAdhesion["prenom_conjoint||Prénom||2"]?.replaceAll(" ", "")
          ) ||
          checkTypes.includes(dataAdhesion.lieu_naissance_conjoint) ||
          checkTypes.includes(dataAdhesion.jumeau_meme_sexe_conjoint) ||
          (dataAdhesion.jumeau_meme_sexe_conjoint === "Oui" &&
            checkTypes.includes(
              dataAdhesion.rang_jumeau_meme_sexe_conjoint?.replaceAll(" ", "")
            ))
        ) {
          // handleRequirements();
          alertMessage(
            "Veuillez remplir tous les champs obligatoires.",
            "error"
          );
        } else if (!isValidName(dataAdhesion["prenom_conjoint||Prénom||2"])) {
          alertMessage("Veuillez vérifier votre prénom.", "error");
        } else if (!isValidName(dataAdhesion["nom_conjoint||Nom||1"])) {
          alertMessage("Veuillez vérifier votre nom.", "error");
        } else if (isTrueSS2(ssc) === false) {
          // handleRequirementsSS();
          alertMessage(
            "Veuillez vérifier le numéro de sécurité sociale de votre conjoint(e).",
            "error"
          );
        } 
        // else if (
        //   isValidSS(
        //     dataAdhesion["num_sec_conjoint||N° sécurité sodiale||6"]
        //   ) === false
        // ) {
        //   // handleRequirementsSS();
        //   alertMessage(
        //     "Le numéro de sécurité sociale n'est pas cohérent avec votre date de naissance et/ou votre civilité .",
        //     "error"
        //   );
        // }
         else if (
          dataAdhesion.code_organisme_conjoint?.split(" ")?.join("")?.length !==
          9
        ) {
          alertMessage(
            "Le code organisme doit comporter 9 caractères.",
            "error"
          );
        } else {
          notification.destroy();
          setStage(stage + 1);
        }
      } else if (
        steps[stage] === "Fiche conseil" &&
        dataAdhesion[
          "nousresiliation_garantie||Souhaitez-vous que nous nous chargions d'expédier, en votre nom et pour votre compte, la lettre de résiliation en respectant les conditions,à votre assureur ou à votre mutuelle actuel (le) ?||8"
        ] === "Oui"
      ) {
        handleTriggerModal();
      } else if (
        steps[stage] === "Fiche conseil" &&
        dataAdhesion[
          "refbudget_garantie||Avez-vous réfléchi à un budget mensuel de cotisation ?||9"
        ] === "Oui" &&
        !dataAdhesion["budegtmensuel_garantie||Si oui: lequel ?||10"]
      ) {
        alertMessage(
          "Afin de poursuivre la souscription, veuillez remplir le montant de votre cotisation mensuelle.",
          "error"
        );
      } else if (steps[stage] === "Enfant(s)") {
        if (checkEnfantInputs() === false) {
          // handleRequirements();
          alertMessage(
            "Veuillez remplir tous les champs obligatoires.",
            "error"
          );
        } else if (checkNames("nom")?.valid === true) {
          alertMessage(
            `Veuillez vérifier le nom de l'enfant ${checkNames("nom")?.index}.`,
            "error"
          );
        } else if (checkNames("prenom")?.valid === true) {
          alertMessage(
            `Veuillez vérifier le prénom de l'enfant ${
              checkNames("prenom")?.index
            }.`,
            "error"
          );
        } else if (isTrueSSEnfant(ssc)?.valid === false) {
          alertMessage(
            childs?.length === 1
              ? "Veuillez vérifier le numéro de sécurité sociale de votre enfant."
              : `Veuillez vérifier le numéro de sécurité sociale de l'enfant ${
                  isTrueSSEnfant(ssc)?.index
                }.`,
            "error"
          );
        } else if (isValidCodeOrganismeEnfant()?.valid === false) {
          alertMessage(
            childs?.length === 1
              ? "Le code organisme doit comporter 9 caractères."
              : `Veuillez vérifier le code organisme de l'enfant ${
                  isValidCodeOrganismeEnfant()?.index
                }, il doit comporter 9 caractères.`,
            "error"
          );
        } else if (isValidAgeEnfant()?.valid === false) {
          alertMessage(
            "La couverture d'assurance est limitée aux enfants jusqu'à 26 ans, sauf en cas de handicap où une extension est possible.",
            "error"
          );
        } else {
          notification.destroy();
          setStage(stage + 1);
        }
      } else {
        notification.destroy();
        setStage(stage + 1);
      }
    }
  };

  const prevHandler = () => {
    if (stage > 0) {
      notification.destroy();
      setStage(stage - 1);
      // window.scrollTo(0, 0);
    }
  };
  const checkCivilite = (civ) => {
    if (civ === "M") {
      return "Monsieur";
    } else if (civ === "Mme") {
      return "Madame";
    }
  };
  const costumerData = (prodId) => {
    let customer_information = [];
    let costumers = JSON.parse(sessionStorage.getItem("customerInfo"));
    let id_pros = JSON.parse(
      sessionStorage.getItem("_subs_tags")
    )?.id_prospect_md5_with_givenTgas;
    costumers.forEach((element) => {
      var email = "";
      if (element?.relationship_rank === 1) {
        email = dataAdhesion["mailassure_text"];
      } else if (element?.relationship_rank === 2) {
        email = "conjoint" + dataAdhesion["mailassure_text"];
      } else {
        email =
          "Enfant" +
          (element?.relationship_rank - 10) +
          dataAdhesion["mailassure_text"];
      }
      customer_information.push({
        number: element?.relationship_rank,
        primary_subscriber: element?.primary_subscriber,
        insured: true,
        beneficiary: false,
        code_organisme:
          element?.relationship_rank === 1
            ? dataAdhesion["code_organisme_principal"]
            : element?.relationship_rank === 2
            ? dataAdhesion["code_organisme_conjoint"]
            : dataAdhesion[`code_organisme${element?.relationship_rank - 10}`],
        qualite_de:
          element?.relationship_rank === 1
            ? exerceequalite
            : SessionStorageService()
                .getCustomerInfo()
                ?.find((e) => e.relationship_rank === element.relationship_rank)
                ?.job?.situation_pro
            ? SessionStorageService()
                .getCustomerInfo()
                ?.find((e) => e.relationship_rank === element.relationship_rank)
                ?.job?.situation_pro
            : "Non renseigné",
        birth_info: {
          date:
            element?.relationship_rank === 1
              ? dataAdhesion["dn_assure||Date de naissance||3"]
              : element?.relationship_rank === 2
              ? dataAdhesion["dn_conjoint||Date de naissance||3"]
              : element?.birth_info.date,
          lieu:
            element.relationship_rank === 1
              ? SessionStorageService().getProspect()?.lieu_naissance_principal
              : element.relationship_rank === 2
              ? SessionStorageService().getProspect()?.lieu_naissance_conjoint
              : SessionStorageService().getProspect()[
                  `lieu_naissance_${element?.relationship_rank - 10}`
                ],
        },

        id_pros:
          element?.relationship_rank === 1
            ? Object.keys(id_pros)?.find((key) => id_pros[key] === "Prospect")
            : element?.relationship_rank === 2
            ? Object.keys(id_pros)?.find((key) => id_pros[key] === "Conjoint")
            : Object.keys(id_pros)?.find(
                (key) =>
                  id_pros[key] === "Enfant" + (element?.relationship_rank - 10)
              ),
        rang_jumeau:
          element.relationship_rank === 1
            ? SessionStorageService().getProspect()
                ?.rang_jumeau_meme_sexe_principal
            : element.relationship_rank === 2
            ? SessionStorageService().getProspect()
                ?.rang_jumeau_meme_sexe_conjoint
            : SessionStorageService().getProspect()[
                `enfant_rang_${element?.relationship_rank - 10}`
              ],
        address: {
          street_name:
            SessionStorageService().getCustomerInfo()[0]?.address.num_voie +
            " " +
            SessionStorageService().getCustomerInfo()[0]?.address.nom_voie,
          zip_code: dataAdhesion["cp_assure||Code postal||4"],
          city: dataAdhesion["ville_assure||Ville||5"],
          code_insee: costumers[0]?.address?.code_insee,
          num_voie:
            SessionStorageService().getCustomerInfo()[0]?.address.num_voie,
          nom_voie:
            SessionStorageService().getCustomerInfo()[0]?.address.nom_voie,
          address_complement:
            SessionStorageService().getCustomerInfo()[0]?.address
              .address_complement,
        },
        enfant_handicape:
          element?.relationship_rank > 10
            ? SessionStorageService().getProspect()[
                `enfant_handicape_${element?.relationship_rank - 10}`
              ]
            : null,

        attached:
          SessionStorageService().getProspect()[
            `attach${element?.relationship_rank - 10}`
          ],
        job: {
          csp_insee: "Toutes",
          regime: element?.job?.regime,
          number_yes: 0,
          force_yes: false,
        },
        contract_information: {
          number: 476820,
          product: [prodId],
        },
        relationship: element?.relationship,
        relationship_rank: element?.relationship_rank,
        mail: email,
        identity: {
          civility:
            element?.relationship_rank === 1
              ? checkCivilite(dataAdhesion["civilite_assure||Civilité||0"])
              : element?.relationship_rank === 2
              ? checkCivilite(dataAdhesion["civilite_conjoint||Civilité||0"])
              : checkCivilite(
                  dataAdhesion[
                    `civilite_enfant${
                      element?.relationship_rank - 10
                    }||Civilité||0`
                  ]
                ),
          use_name:
            element?.relationship_rank === 1
              ? dataAdhesion["nom_assure||Nom||1"]
              : element?.relationship_rank === 2
              ? dataAdhesion["nom_conjoint||Nom||1"]
              : dataAdhesion[
                  `nom_enfant${element?.relationship_rank - 10}||Nom||1`
                ],
          first_name:
            element?.relationship_rank === 1
              ? dataAdhesion["prenom_assure||Prénom||2"]
              : element?.relationship_rank === 2
              ? dataAdhesion["prenom_conjoint||Prénom||2"]
              : dataAdhesion[
                  `prenom_enfant${element?.relationship_rank - 10}||Prénom||1`
                ],
        },
        //data[35]['num_sec_assure||N° sécurité sodiale||6'] without last 2 digits
        social_security_number:
          element?.relationship_rank === 1
            ? dataAdhesion["num_sec_assure||N° sécurité sodiale||6"]
                ?.replace(/ /g, "")
                .slice(0, -2)
            : element?.relationship_rank === 2
            ? dataAdhesion["num_sec_conjoint||N° sécurité sodiale||6"]
                ?.replace(/ /g, "")
                .slice(0, -2)
            : dataAdhesion[
                `num_sec_enfant${
                  element?.relationship_rank - 10
                }||N° sécurité sodiale||6`
              ]
                ?.replace(/ /g, "")
                ?.slice(0, -2),

        social_security_number_link:
          element?.relationship_rank === 1
            ? dataAdhesion["num_sec_assure||N° sécurité sodiale||6"]
                ?.replace(/ /g, "")
                .slice(-2)
            : element?.relationship_rank === 2
            ? dataAdhesion["num_sec_conjoint||N° sécurité sodiale||6"]
                ?.replace(/ /g, "")
                .slice(-2)
            : dataAdhesion[
                `num_sec_enfant${
                  element?.relationship_rank - 10
                }||N° sécurité sodiale||6`
              ]
                ?.replace(/ /g, "")
                ?.slice(-2),
        phone: [
          {
            type: "Mobile",
            country_prefix: dataAdhesion["prefix_mobile_assure"],
            number: `${dataAdhesion["suffix_mobile_assure"]}`,
          },
        ],
      });
    });
    return customer_information;
  };
  const choixOption = () => {
    let option = [];
    if (optionHorsSante.includes("Assistance")) {
      option.push(import.meta.env.VITE_APP_ASSISTANCE_CONTRACT);
    }
    if (optionHorsSante.includes("Protection juridique")) {
      option.push(import.meta.env.VITE_APP_PROTECTION_JURIDIQUE_CONTRACT);
    }
    return option;
  };

  const subscribeHandler = (signature_electronique) => {
    setOpenbackdrop(true);
    let newdata = dataAdhesion;
    newdata["civilite_producteur||Civilité||0"] = organisme?.user?.civilite
      ? organisme?.user?.civilite +
        " " +
        organisme?.user?.nom +
        " " +
        organisme?.user?.prenom
      : "";
    // newdata["nom_producteur||Nom||1"] =
    //   organisme?.user?.nom + " " + organisme?.user?.prenom;
    // newdata["prenom_producteur||Prénom||2"] = organisme?.user?.prenom ? organisme?.user?.prenom :"";
    newdata["orias_producteur||ORIAS N°||4"] =
      organisme?.user?.orias_producteur !== null
        ? organisme?.user?.orias_producteur
        : "";
    newdata["tel_producteur||Téléphone||5"] =
      SessionStorageService().getProspect()["tel_producteur||Téléphone||5"]
        ? SessionStorageService().getProspect()["tel_producteur||Téléphone||5"]
        : "";
    // newdata["qualite_producteur||Agissant en qualité||3"] = "Salarié";

    newdata["de_sante||Date d'échéance||6"] =
      dataAdhesion["date_effet_sante||Date d'effet||1"];
    newdata["dureeadhesion_sante||Durée de l'adhésion||7"] =
      "1 an avec tacite reconduction	au 1 er janvier de chaque année";
    newdata["preavisres_sante||Préavis de résiliation||8"] =
      "2 mois avant la date d'échéance";
    newdata["lienf_assure||Lien famillial||5"] = "Assuré";
    newdata["inseeassure_text"] =
      SessionStorageService().getCustomerInfo()[0]?.address?.zip_code;
    if (dataAdhesion["nom_conjoint||Nom||1"])
      newdata["lienf_conjoint||Lien famillial||5"] = "Conjoint";
    if (dataAdhesion["prenom_enfant1||Prénom||1"])
      newdata["lienf_enfant1||Lien famillial||5"] = "Enfant 1";
    if (dataAdhesion["nom_enfant2||Nom||1"])
      newdata["lienf_enfant2||Lien famillial||5"] = "Enfant 2";
    if (dataAdhesion["nom_enfant3||Nom||1"])
      newdata["lienf_enfant3||Lien famillial||5"] = "Enfant 3";
    if (dataAdhesion["nom_enfant4||Nom||1"])
      newdata["lienf_enfant4||Lien famillial||5"] = "Enfant 4";
    if (dataAdhesion["nom_enfant5||Nom||1"])
      newdata["lienf_enfant5||Lien famillial||5"] = "Enfant 5";
    if (dataAdhesion["nom_enfant6||Nom||1"])
      newdata["lienf_enfant6||Lien famillial||5"] = "Enfant 6";

    // Télétransmission
    newdata["teletransmission_assure||Télétransmission||8"] = "Oui";
    if (dataAdhesion["nom_conjoint||Nom||1"])
      newdata["teletransmission_conjoint||Télétransmission||8"] = "Oui";
    if (dataAdhesion["prenom_enfant1||Prénom||1"])
      newdata["teletransmission_enfant1||Télétransmission||8"] = "Oui";
    if (dataAdhesion["nom_enfant2||Nom||1"])
      newdata["teletransmission_enfant2||Télétransmission||8"] = "Oui";
    if (dataAdhesion["nom_enfant3||Nom||1"])
      newdata["teletransmission_enfant3||Télétransmission||8"] = "Oui";
    if (dataAdhesion["nom_enfant4||Nom||1"])
      newdata["teletransmission_enfant4||Télétransmission||8"] = "Oui";
    if (dataAdhesion["nom_enfant5||Nom||1"])
      newdata["teletransmission_enfant5||Télétransmission||8"] = "Oui";
    if (dataAdhesion["nom_enfant6||Nom||1"])
      newdata["teletransmission_enfant6||Télétransmission||8"] = "Oui";
    // Rang
    newdata["rang_assure||Rang||7"] = "1";
    if (dataAdhesion["nom_conjoint||Nom||1"])
      newdata["rang_conjoint||Rang||7"] = "2";
    if (dataAdhesion["prenom_enfant1||Prénom||1"])
      newdata["rang_enfant1||Rang||7"] = "3";
    if (dataAdhesion["nom_enfant2||Nom||1"])
      newdata["rang_enfant2||Rang||7"] = "4";
    if (dataAdhesion["nom_enfant3||Nom||1"])
      newdata["rang_enfant3||Rang||7"] = "5";
    if (dataAdhesion["nom_enfant4||Nom||1"])
      newdata["rang_enfant4||Rang||7"] = "6";
    if (dataAdhesion["nom_enfant5||Nom||1"])
      newdata["rang_enfant5||Rang||7"] = "7";
    if (dataAdhesion["nom_enfant6||Nom||1"])
      newdata["rang_enfant6||Rang||7"] = "8";

    if (dataAdhesion["nom_conjoint||Nom||1"]) {
      newdata["nomconjoint_text"] = dataAdhesion["nom_conjoint||Nom||1"];
      newdata["inseeconjoint_text"] =
        SessionStorageService().getCustomerInfo()[0]?.address?.zip_code;
    }
    if (dataAdhesion["prenom_conjoint||Prénom||2"])
      newdata["prenomconjoint_text"] =
        dataAdhesion["prenom_conjoint||Prénom||2"];
    if (dataAdhesion["dn_conjoint||Date de naissance||3"])
      newdata["dnconjoint_text"] =
        dataAdhesion["dn_conjoint||Date de naissance||3"];
    if (dataAdhesion["ro_conjoint||Régime||4"])
      newdata["roconjoint_text"] = dataAdhesion["ro_conjoint||Régime||4"];
    if (dataAdhesion["num_sec_conjoint||N° sécurité sodiale||6"]) {
      newdata["affiliationconjoint_text"] = dataAdhesion[
        "num_sec_conjoint||N° sécurité sodiale||6"
      ]?.replace(/ /g, "");
    }

    ///////// ENFANT 1
    if (dataAdhesion["nom_enfant1||Nom||1"]) {
      newdata["nomenf1_text"] = dataAdhesion["nom_enfant1||Nom||1"];
      newdata["inseeenf1_text"] =
        SessionStorageService().getCustomerInfo()[0]?.address?.zip_code;
    }
    if (dataAdhesion["prenom_enfant1||Prénom||1"])
      newdata["prenomenf1_text"] = dataAdhesion["prenom_enfant1||Prénom||1"];
    if (dataAdhesion["dn_enfant1||Date de naissance||3"])
      newdata["dnenf1_text"] = dataAdhesion["dn_enfant1||Date de naissance||3"];
    if (dataAdhesion["ro_enfant1||Régime||4"])
      newdata["roenf1_text"] = dataAdhesion["ro_enfant1||Régime||4"];
    if (dataAdhesion["num_sec_enfant1||N° sécurité sodiale||6"]) {
      newdata["affiliationenf1_text"] = dataAdhesion[
        "num_sec_enfant1||N° sécurité sodiale||6"
      ]?.replace(/ /g, "");
    }

    // ENFANT 2
    if (dataAdhesion["nom_enfant2||Nom||1"]) {
      newdata["nomenf2_text"] = dataAdhesion["nom_enfant2||Nom||1"];
      newdata["inseeenf2_text"] =
        SessionStorageService().getCustomerInfo()[0]?.address?.zip_code;
    }
    if (dataAdhesion["prenom_enfant2||Prénom||1"])
      newdata["prenomenf2_text"] = dataAdhesion["prenom_enfant2||Prénom||1"];
    if (dataAdhesion["dn_enfant2||Date de naissance||3"])
      newdata["dnenf2_text"] = dataAdhesion["dn_enfant2||Date de naissance||3"];
    if (dataAdhesion["ro_enfant2||Régime||4"])
      newdata["roenf2_text"] = dataAdhesion["ro_enfant2||Régime||4"];
    if (dataAdhesion["num_sec_enfant2||N° sécurité sodiale||6"]) {
      newdata["affiliationenf2_text"] = dataAdhesion[
        "num_sec_enfant2||N° sécurité sodiale||6"
      ]?.replace(/ /g, "");
    }
    // ENFANT 3
    if (dataAdhesion["nom_enfant3||Nom||1"]) {
      newdata["nomenf3_text"] = dataAdhesion["nom_enfant3||Nom||1"];
      newdata["inseeenf3_text"] =
        SessionStorageService().getCustomerInfo()[0]?.address?.zip_code;
    }
    if (dataAdhesion["prenom_enfant3||Prénom||1"])
      newdata["prenomenf3_text"] = dataAdhesion["prenom_enfant3||Prénom||1"];
    if (dataAdhesion["dn_enfant3||Date de naissance||3"])
      newdata["dnenf3_text"] = dataAdhesion["dn_enfant3||Date de naissance||3"];
    if (dataAdhesion["ro_enfant3||Régime||4"])
      newdata["roenf3_text"] = dataAdhesion["ro_enfant3||Régime||4"];
    if (dataAdhesion["num_sec_enfant3||N° sécurité sodiale||6"]) {
      newdata["affiliationenf3_text"] = dataAdhesion[
        "num_sec_enfant3||N° sécurité sodiale||6"
      ]?.replace(/ /g, "");
    }
    // ENFANT 4
    if (dataAdhesion["nom_enfant4||Nom||1"]) {
      newdata["nomenf4_text"] = dataAdhesion["nom_enfant4||Nom||1"];
      newdata["inseeenf4_text"] =
        SessionStorageService().getCustomerInfo()[0]?.address?.zip_code;
    }
    if (dataAdhesion["prenom_enfant4||Prénom||1"])
      newdata["prenomenf4_text"] = dataAdhesion["prenom_enfant4||Prénom||1"];
    if (dataAdhesion["dn_enfant4||Date de naissance||3"])
      newdata["dnenf4_text"] = dataAdhesion["dn_enfant4||Date de naissance||3"];
    if (dataAdhesion["ro_enfant4||Régime||4"])
      newdata["roenf4_text"] = dataAdhesion["ro_enfant4||Régime||4"];
    if (dataAdhesion["num_sec_enfant4||N° sécurité sodiale||6"]) {
      newdata["affiliationenf4_text"] = dataAdhesion[
        "num_sec_enfant4||N° sécurité sodiale||6"
      ]?.replace(/ /g, "");
    }
    // ENFANT 5
    if (dataAdhesion["nom_enfant5||Nom||1"]) {
      newdata["nomenf5_text"] = dataAdhesion["nom_enfant5||Nom||1"];
      newdata["inseeenf5_text"] =
        SessionStorageService().getCustomerInfo()[0]?.address?.zip_code;
    }
    if (dataAdhesion["prenom_enfant5||Prénom||1"])
      newdata["prenomenf5_text"] = dataAdhesion["prenom_enfant5||Prénom||1"];
    if (dataAdhesion["dn_enfant5||Date de naissance||3"])
      newdata["dnenf5_text"] = dataAdhesion["dn_enfant5||Date de naissance||3"];
    if (dataAdhesion["ro_enfant5||Régime||4"])
      newdata["roenf5_text"] = dataAdhesion["ro_enfant5||Régime||4"];
    if (dataAdhesion["num_sec_enfant5||N° sécurité sodiale||6"]) {
      newdata["affiliationenf5_text"] = dataAdhesion[
        "num_sec_enfant5||N° sécurité sodiale||6"
      ]?.replace(/ /g, "");
    }
    // ENFANT 6
    if (dataAdhesion["nom_enfant6||Nom||1"]) {
      newdata["nomenf6_text"] = dataAdhesion["nom_enfant6||Nom||1"];
      newdata["inseeenf6_text"] =
        SessionStorageService().getCustomerInfo()[0]?.address?.zip_code;
    }
    if (dataAdhesion["prenom_enfant6||Prénom||1"])
      newdata["prenomenf6_text"] = dataAdhesion["prenom_enfant6||Prénom||1"];
    if (dataAdhesion["dn_enfant6||Date de naissance||3"])
      newdata["dnenf6_text"] = dataAdhesion["dn_enfant6||Date de naissance||3"];
    if (dataAdhesion["ro_enfant6||Régime||4"])
      newdata["roenf6_text"] = dataAdhesion["ro_enfant6||Régime||4"];
    if (dataAdhesion["num_sec_enfant6||N° sécurité sodiale||6"]) {
      newdata["affiliationenf6_text"] = dataAdhesion[
        "num_sec_enfant6||N° sécurité sodiale||6"
      ]?.replace(/ /g, "");
    }
    if (dataAdhesion["formuleproduit_sante||Formule||3"])
      newdata["cie_text"] = "ACHEEL";
    newdata["produit_text"] =
      "ALL SANTE" + " " + dataAdhesion["formuleproduit_sante||Formule||3"];
    if (dataAdhesion["ds_sante||Date de souscription||0"])
      newdata["datesous_text"] = moment(
        dataAdhesion["ds_sante||Date de souscription||0"],
        "DD-MM-YYYY"
      ).format("DD/MM/yyyy");

    newdata[
      "signature_garantie||Cette fiche d’information et de conseil a été signée dans le cadre d'une adhésion Signée de façon||21"
    ] = "Électronique";
    newdata["offre1_garantie||Par téléphone||22"] = "Oui";
    newdata["offre2_garantie||Par mail||23"] = "Oui";
    newdata["offre3_garantie||Par courrier||24"] = "Oui";
    newdata["nom_courtier||Courtier||0"] =
      dataAdhesion["nom_courtier||Courtier||0"];
    if (dataAdhesion["nom_assure||Nom||1"])
      newdata["nomprenomassure_text"] =
        dataAdhesion["nom_assure||Nom||1"] +
        " " +
        dataAdhesion["prenom_assure||Prénom||2"];
    if (dataAdhesion["nom_producteur||Nom||1"])
      // newdata["nomprenomproducteur_text"] =
      //   dataAdhesion["nom_producteur||Nom||1"] +
      //   " " +
      //   dataAdhesion["prenom_producteur||Prénom||2"];
      newdata["nomprenomproducteur_text"] =
        organisme?.user?.nom + " " + organisme?.user?.prenom;

    if (dataAdhesion["num_sec_enfant6||N° sécurité sodiale||6"])
      newdata["affiliationenf4_text"] = dataAdhesion[
        "num_sec_enfant6||N° sécurité sodiale||6"
      ]?.replace(/ /g, "");

    // **************************************************************************************
    // Assuré
    if (dataAdhesion["nom_assure||Nom||1"])
      newdata["nomassure_text"] = dataAdhesion["nom_assure||Nom||1"];
    if (dataAdhesion["prenom_assure||Prénom||2"])
      newdata["prenomassure_text"] = dataAdhesion["prenom_assure||Prénom||2"];
    if (dataAdhesion["dn_assure||Date de naissance||3"])
      newdata["dnassure_text"] =
        dataAdhesion["dn_assure||Date de naissance||3"];
    if (dataAdhesion["ville_assure||Ville||5"])
      newdata["villeassure_text"] = dataAdhesion["ville_assure||Ville||5"];
    if (dataAdhesion["cp_assure||Code postal||4"])
      newdata["cpassure_text"] = dataAdhesion["cp_assure||Code postal||4"];
    if (dataAdhesion["dn_assure||Date de naissance||3"])
      newdata["date_naissanceassure_text"] = moment(
        dataAdhesion["dn_assure||Date de naissance||3"],
        "DD/MM/YYYY"
      ).format("DD/MM/YYYY");
    if (dataAdhesion["ro_assure||Régime||4"])
      newdata["roassure_text"] = dataAdhesion["ro_assure||Régime||4"];
    if (dataAdhesion["num_sec_assure||N° sécurité sodiale||6"])
      newdata["affiliationassure_text"] = dataAdhesion[
        "num_sec_assure||N° sécurité sodiale||6"
      ]?.replace(/ /g, "");

    // **************************************************************************************
    // CIVI NOM PRENOMM ALL (ASSURE, CONJOINT, ENFANTS)
    newdata["nbreenfantsassure_text"] = nbrChild;
    newdata["civi_nom_prenomassure"] =
      dataAdhesion["civilite_assure||Civilité||0"] +
      " " +
      dataAdhesion["nom_assure||Nom||1"] +
      " " +
      dataAdhesion["prenom_assure||Prénom||2"];
    if (dataAdhesion["nom_conjoint||Nom||1"])
      newdata["civi_nom_prenomconjoint"] =
        dataAdhesion["civilite_conjoint||Civilité||0"] +
        " " +
        dataAdhesion["nom_conjoint||Nom||1"] +
        " " +
        dataAdhesion["prenom_conjoint||Prénom||2"];
    if (dataAdhesion["nom_enfant1||Nom||1"])
      newdata["civi_nom_prenomenf1"] =
        dataAdhesion["civilite_enfant1||Civilité||0"] +
        " " +
        dataAdhesion["nom_enfant1||Nom||1"] +
        " " +
        dataAdhesion["prenom_enfant1||Prénom||1"];
    if (dataAdhesion["nom_enfant2||Nom||1"])
      newdata["civi_nom_prenomenf2"] =
        dataAdhesion["civilite_enfant2||Civilité||0"] +
        " " +
        dataAdhesion["nom_enfant2||Nom||1"] +
        " " +
        dataAdhesion["prenom_enfant2||Prénom||1"];
    if (dataAdhesion["nom_enfant3||Nom||1"])
      newdata["civi_nom_prenomenf3"] =
        dataAdhesion["civilite_enfant3||Civilité||0"] +
        " " +
        dataAdhesion["nom_enfant3||Nom||1"] +
        " " +
        dataAdhesion["prenom_enfant3||Prénom||1"];
    if (dataAdhesion["nom_enfant4||Nom||1"])
      newdata["civi_nom_prenomenf4"] =
        dataAdhesion["civilite_enfant4||Civilité||0"] +
        " " +
        dataAdhesion["nom_enfant4||Nom||1"] +
        " " +
        dataAdhesion["prenom_enfant4||Prénom||1"];
    if (dataAdhesion["nom_enfant5||Nom||1"])
      newdata["civi_nom_prenomenf5"] =
        dataAdhesion["civilite_enfant5||Civilité||0"] +
        " " +
        dataAdhesion["nom_enfant5||Nom||1"] +
        " " +
        dataAdhesion["prenom_enfant5||Prénom||1"];
    if (dataAdhesion["nom_enfant6||Nom||1"])
      newdata["civi_nom_prenomenf6"] =
        dataAdhesion["civilite_enfant6||Civilité||0"] +
        " " +
        dataAdhesion["nom_enfant6||Nom||1"] +
        " " +
        dataAdhesion["prenom_enfant6||Prénom||1"];
    //newdata["numcontrat_sante||N° contrat||0"] = num_contrat;
    newdata["mobileassure_text"] =
      dataAdhesion["prefix_mobile_assure"] +
      " " +
      dataAdhesion["suffix_mobile_assure"];

    if (detailsData) {
      newdata["aesio_sante||AESIO mutuelle||12"] = annuel_ht;
      newdata["tax_aesio_mutuelle||Taxe Assurance||3"] = annuel_taxe;
      newdata["tsa_sante||Taxe TSA||13"] = taxeTSA;
      newdata["cfdp_sante||Cfdp||15"] = cfdpttc;
      newdata["matuaide_sante||Mutuaide assistance||17"] = assisstancettc;
      newdata["ht_taxe_sante||HT Taxe Assurance||16"] = cfdpht;
      newdata["cfdp_tax_as||Taxe Assurance||6"] = cfdptax_ass;
      newdata["tax_sante||Taxe Assurance||14"] = amountTtc.toFixed(2);
      newdata["ht_taxe1_sante||HT Taxe Assurance||18"] = assisstanceht;
      newdata["mutuaide_taxe1_sante||HT Taxe Assurance||9"] = mutuaide_taxe1;
      newdata["frais_sante||Frais associatifs||20"] = fraisAssociatif;
    }

    /****************************** */
    // ASSISTANCE SANTE
    if (dataAdhesion["assistance_sante||Assistance||4"] === "Oui") {
      dataAdhesion["assistance_sante||Assistance||4||oui"] = "True";
    } else {
      dataAdhesion["assistance_sante||Assistance||4||non"] = "True";
    }

    if (dataAdhesion["pj_sante||Protection juridique||5"] === "Oui") {
      dataAdhesion["pj_sante||Protection juridique||5||oui"] = "True";
    } else {
      dataAdhesion["pj_sante||Protection juridique||5||non"] = "True";
    }

    // Fiche conseil
    if (
      dataAdhesion[
        "mesure_assure||Faites-vous l'objet d'une mesure de Curatelle ou de Tutelle? ||18"
      ] === "Oui"
    ) {
      dataAdhesion[
        "mesure_assure_oui||Faites-vous l'objet d'une mesure de Curatelle ou de Tutelle? ||18"
      ] = "True";
    } else {
      dataAdhesion[
        "mesure_assure_non||Faites-vous l'objet d'une mesure de Curatelle ou de Tutelle? ||18"
      ] = "True";
    }

    if (
      dataAdhesion[
        "declare_assure||Vous déclarez gérer vous-même vos papiers sans une aide extérieure ?||19"
      ] === "Oui"
    ) {
      dataAdhesion[
        "declare_assure_oui||Vous déclarez gérer vous-même vos papiers sans une aide extérieure ?||19"
      ] = "True";
    } else {
      dataAdhesion[
        "declare_assure_non||Vous déclarez gérer vous-même vos papiers sans une aide extérieure ?||19"
      ] = "True";
    }
    ///

    if (
      dataAdhesion[
        "beneficairecmu_garantie||Êtes-vous bénéficiaire de la CSS, ou en avez-vous formulé la demande ?||2"
      ] === "Oui"
    ) {
      dataAdhesion[
        "beneficairecmu_garantie_oui||Êtes-vous bénéficiaire de la CSS, ou en avez-vous formulé la demande ?||2"
      ] = "True";
    } else {
      dataAdhesion[
        "beneficairecmu_garantie_non||Êtes-vous bénéficiaire de la CSS, ou en avez-vous formulé la demande ?||2"
      ] = "True";
    }

    if (
      dataAdhesion[
        "complementairesante_garantie||Bénéficiez-vous d’une aide à la complémentaire santé ?||3"
      ] === "Oui"
    ) {
      dataAdhesion[
        "complementairesante_garantie_oui||Bénéficiez-vous d’une aide à la complémentaire santé ?||3"
      ] = "True";
    } else {
      dataAdhesion[
        "complementairesante_garantie_non||Bénéficiez-vous d’une aide à la complémentaire santé ?||3"
      ] = "True";
    }
    ///
    if (
      dataAdhesion[
        "beneficie_garantie||Bénéficiez-vous actuellement d'une  complémentaire santé ou d'une mutuelle ?||5"
      ] === "Oui"
    ) {
      dataAdhesion[
        "beneficie_garantie_oui||Bénéficiez-vous actuellement d'une  complémentaire santé ou d'une mutuelle ?||5"
      ] = "True";
    } else {
      dataAdhesion[
        "beneficie_garantie_non||Bénéficiez-vous actuellement d'une  complémentaire santé ou d'une mutuelle ?||5"
      ] = "True";
    }

    if (
      dataAdhesion[
        "contrat_garantie||S'agit-il d'un contrat complémentaire santé de votre employeur ?||6"
      ] === "Oui"
    ) {
      dataAdhesion[
        "contrat_garantie_oui||S'agit-il d'un contrat complémentaire santé de votre employeur ?||6"
      ] = "True";
    } else {
      dataAdhesion[
        "contrat_garantie_non||S'agit-il d'un contrat complémentaire santé de votre employeur ?||6"
      ] = "True";
    }
    if (
      dataAdhesion[
        "vousresiliation_garantie||Vous chargerez-vous d'adresser la lettre de résiliation en respectant les conditions, à votre assureur ou mutuelle actuel (le) ?||7"
      ] === "Oui"
    ) {
      dataAdhesion[
        "vousresiliation_garantie_oui||Vous chargerez-vous d'adresser la lettre de résiliation en respectant les conditions, à votre assureur ou mutuelle actuel (le) ?||7"
      ] = "True";
    } else {
      dataAdhesion[
        "vousresiliation_garantie_non||Vous chargerez-vous d'adresser la lettre de résiliation en respectant les conditions, à votre assureur ou mutuelle actuel (le) ?||7"
      ] = "True";
    }
    if (
      dataAdhesion[
        "nousresiliation_garantie||Souhaitez-vous que nous nous chargions d'expédier, en votre nom et pour votre compte, la lettre de résiliation en respectant les conditions,à votre assureur ou à votre mutuelle actuel (le) ?||8"
      ] === "Oui"
    ) {
      dataAdhesion[
        "nousresiliation_garantie_oui||Souhaitez-vous que nous nous chargions d'expédier, en votre nom et pour votre compte, la lettre de résiliation en respectant les conditions,à votre assureur ou à votre mutuelle actuel (le) ?||8"
      ] = "True";
    } else {
      dataAdhesion[
        "nousresiliation_garantie_non||Souhaitez-vous que nous nous chargions d'expédier, en votre nom et pour votre compte, la lettre de résiliation en respectant les conditions,à votre assureur ou à votre mutuelle actuel (le) ?||8"
      ] = "True";
    }

    // if (nousresiliation_garantie_oui||Souhaitez-vous que nous nous chargions d'expédier, en votre nom et pour votre compte, la lettre de résiliation en respectant les conditions,à votre assureur ou à votre mutuelle actuel (le) ?||8"] === "Oui") {
    //   dataAdhesion["contrat_garantie_oui||S'agit-il d'un contrat complémentaire santé de votre employeur ?||6"] = "True";
    // } else {
    //   dataAdhesion["contrat_garantie_non||S'agit-il d'un contrat complémentaire santé de votre employeur ?||6"] = "True";
    // }

    ///
    if (
      dataAdhesion[
        "refbudget_garantie||Avez-vous réfléchi à un budget mensuel de cotisation ?||9"
      ] === "Oui"
    ) {
      dataAdhesion[
        "refbudget_garantie_oui||Avez-vous réfléchi à un budget mensuel de cotisation ?||9"
      ] = "True";
    } else {
      dataAdhesion[
        "refbudget_garantie_non||Avez-vous réfléchi à un budget mensuel de cotisation ?||9"
      ] = "True";
    }

    if (
      dataAdhesion[
        "contrat_garantie||S'agit-il d'un contrat complémentaire santé de votre employeur ?||6"
      ] === "Oui"
    ) {
      dataAdhesion[
        "contrat_garantie_oui||S'agit-il d'un contrat complémentaire santé de votre employeur ?||6"
      ] = "True";
    } else {
      dataAdhesion[
        "contrat_garantie_non||S'agit-il d'un contrat complémentaire santé de votre employeur ?||6"
      ] = "True";
    }
    if (
      dataAdhesion[
        "signature_garantie||Cette fiche d’information et de conseil a été signée dans le cadre d'une adhésion Signée de façon||21"
      ] === "Électronique"
    ) {
      dataAdhesion[
        "signature_garantie_electronique||Cette fiche d’information et de conseil a été signée dans le cadre d'une adhésion Signée de façon||21"
      ] = "True";
    }

    if (dataAdhesion["offre1_garantie||Par téléphone||22"] === "Oui") {
      dataAdhesion["offre1_garantie_oui||Par téléphone||22"] = "True";
    } else {
      dataAdhesion["offre1_garantie_non||Par téléphone||22"] = "True";
    }

    if (dataAdhesion["offre2_garantie||Par mail||23"] === "Oui") {
      dataAdhesion["offre2_garantie_oui||Par mail||23"] = "True";
    } else {
      dataAdhesion["offre2_garantie_non||Par mail||23"] = "True";
    }
    if (dataAdhesion["offre3_garantie||Par courrier||24"] === "Oui") {
      dataAdhesion["offre3_garantie_oui||Par courrier||24"] = "True";
    } else {
      dataAdhesion["offre3_garantie_non||Par courrier||24"] = "True";
    }
    ///
    if (
      SessionStorageService().getProspect()[
        "meilleurcouverture_garantie||Vous souhaitez mettre en avant la meilleure couverture ?||17"
      ] === "Oui"
    ) {
      dataAdhesion[
        "meilleurcouverture_garantie_meilleure_couverture||Vous souhaitez mettre en avant la meilleur couverture ?||17"
      ] = "True";
    }
    if (
      SessionStorageService().getProspect()[
        "meilleurprix_garantie||Vous souhaitez mettre en avant le meilleur prix ?||15"
      ] === "Oui"
    ) {
      dataAdhesion[
        "meilleurprix_garantie_meilleur_prix||Vous souhaitez mettre en avant le meilleur prix ?||15"
      ] = "True";
    }
    if (
      SessionStorageService().getProspect()[
        "meilleurqualiteprix_garantie||Vous souhaitez mettre en avant le meilleur rapport qualité prix ?||16"
      ] === "Oui"
    ) {
      dataAdhesion[
        "meilleurqualiteprix_garantie_meilleur_rapport_prix||Vous souhaitez mettre en avant le meilleur rapport qualité prix ?||16"
      ] = "True";
    }
    //
    if (
      dataAdhesion["qualite_producteur||Agissant en qualité||3"] === "Salarié"
    ) {
      dataAdhesion["qualite_producteur_salarié||Agissant en qualité||3"] =
        "True";
    }

    //professionassure_text

    dataAdhesion["professionassure_text"] = exerceequalite;

    // exercé en qualité de

    switch (exerceequalite) {
      case "Salarié":
        dataAdhesion["exerceequalite_salarié||Exercée en qualité de||17"] =
          "True";
        break;
      case "Profession libérale":
        dataAdhesion["exerceequalite_profession||Exercée en qualité de||17"] =
          "True";
        break;
      case "Artisan":
        dataAdhesion["exerceequalite_artisan||Exercée en qualité de||17"] =
          "True";
        break;
      case "Commerçant":
        dataAdhesion["exerceequalite_commercant||Exercée en qualité de||17"] =
          "True";
        break;
      case "Exploitant agricole":
        dataAdhesion["exerceequalite_exploitant||Exercée en qualité de||17"] =
          "True";
        break;
      case "Conjoint collaborateur":
        dataAdhesion["exerceequalite_conjoint||Exercée en qualité de||17"] =
          "True";
        break;
      case "Etudiant":
        dataAdhesion["exerceequalite_Etudiant||Exercée en qualité de||17"] =
          "True";
        break;
      case "Retraité":
        dataAdhesion["exerceequalite_Retraite||Exercée en qualité de||17"] =
          "True";
        break;
      case "Sans emploi":
        dataAdhesion["exerceequalite_SansEmploi||Exercée en qualité de||17"] =
          "True";
        break;

      default:
        dataAdhesion["exerceequalite_RN||Exercée en qualité de||17"] = "True";
        break;
    }
    // optique
    switch (valueChecked["Optique"]) {
      case "Verres progressifs":
        dataAdhesion["optique_garantie_progressifs||Optique||13"] = "True";
        break;
      case "Verres simples":
        dataAdhesion["optique_garantie_simples||Optique||13"] = "True";
        break;

      default:
        dataAdhesion["optique_garantie_sans||Optique||13"] = "True";
        break;
    }
    //hospitalisation
    switch (
      valueChecked[
        "Hospitalisation (dépassement d'honoraires, prestations de confort)"
      ]
    ) {
      case "<= 125€":
        dataAdhesion["hospi_garantie_inf125||Hospitalisation||11"] = "True";
        break;
      case "> 125€ et <=150€":
        dataAdhesion["hospi_garantie_sup125||Hospitalisation||11"] = "True";
        break;
      case "> 150€ et <= 250€":
        dataAdhesion["hospi_garantie_sup150||Hospitalisation||11"] = "True";
        break;
      case "> 250€":
        dataAdhesion["hospi_garantie_sup250||Hospitalisation||11"] = "True";
        break;
      default:
        break;

      // default:
      //   dataAdhesion["hospi_garantie_sup250||Hospitalisation||11"] = "True";
      //   break;
    }

    //Dentaire
    switch (valueChecked["Dentaire (prothèses, orthodontie)"]) {
      case "<= 125€":
        dataAdhesion["dentaire_garantie_inf125||Dentaire||12"] = "True";
        break;
      case "> 125€ et <=150€":
        dataAdhesion["dentaire_garantie_sup125||Dentaire||12"] = "True";
        break;
      case "> 150€ et <= 250€":
        dataAdhesion["dentaire_garantie_sup150||Dentaire||12"] = "True";
        break;
      case "> 250€":
        dataAdhesion["dentaire_garantie_sup250||Dentaire||12"] = "True";
        break;
      default:
        break;

      // default:
      //   dataAdhesion["dentaire_garantie_sup250||Dentaire||12"] = "True";
      //   break;
    }
    //Soins courants
    switch (valueChecked["Soins courants(consultations, pharmacie)"]) {
      case "<= 125€":
        dataAdhesion["soins_garantie_inf125||Soins courants||14"] = "True";
        break;
      case "> 125€ et <=150€":
        dataAdhesion["soins_garantie_sup125||Soins courants||14"] = "True";
        break;
      case "> 150€ et <= 250€":
        dataAdhesion["soins_garantie_sup150||Soins courants||14"] = "True";
        break;
      case "> 250€":
        dataAdhesion["soins_garantie_sup250||Soins courants||14"] = "True";
        break;
      default:
        break;

      // default:
      //   dataAdhesion["soins_garantie_sup250||Soins courants||14"] = "True";
      //   break;
    }

    setDataAdhesion(newdata);

    // **************************************************************************************
    // PAGINATION
    const PJ = dataAdhesion["pj_sante||Protection juridique||5"] === "Oui";
    const AS = dataAdhesion["assistance_sante||Assistance||4"] === "Oui";
    const TNS = dataAdhesion["ro_assure||Régime||4"] === "TNS";

    const NTNS = dataAdhesion["ro_assure||Régime||4"] !== "TNS";
    const NPJ = dataAdhesion["pj_sante||Protection juridique||5"] === "Non";
    const NAS = dataAdhesion["assistance_sante||Assistance||4"] === "Non";
    //eslint-disable-next-line
    let deletePagesCertif;
    if (TNS && AS && PJ) {
      deletePagesCertif = [
        5, 6, 8, 9, 11, 12, 13, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28,
      ];
    } else if (TNS && AS && NPJ) {
      deletePagesCertif = [
        5, 6, 7, 8, 9, 10, 12, 13, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27,
        28,
      ];
    } else if (TNS && NAS && PJ) {
      deletePagesCertif = [
        5, 6, 7, 8, 9, 10, 11, 13, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27,
        28,
      ];
    } else if (TNS && NAS && NPJ) {
      deletePagesCertif = [
        5, 6, 7, 8, 9, 10, 11, 12, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27,
        28,
      ];
    } else if (NTNS && AS && PJ) {
      deletePagesCertif = [
        4, 7, 8, 9, 10, 11, 12, 13, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27,
        28,
      ];
    } else if (NTNS && AS && NPJ) {
      deletePagesCertif = [
        4, 6, 8, 9, 10, 11, 12, 13, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27,
        28,
      ];
    } else if (NTNS && NAS && PJ) {
      deletePagesCertif = [
        4, 6, 7, 8, 10, 11, 12, 13, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27,
        28,
      ];
    } else if (NTNS && NAS && NPJ) {
      //eslint-disable-next-line
      deletePagesCertif = [
        4, 6, 7, 9, 10, 11, 12, 13, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27,
        28,
      ];
    }
    if (TNS && AS && PJ) {
      /*  formData.append("tabPages", "[1,2,3,4,10,14,15,16]");
      formData.append(
        "data[280][date_effet_sante||Date d'effet||22]",
        "E0681191"
      ); */
      dataAdhesion["date_effet_sante||Date d'effet||22"] = "E0681191";
    } else if (TNS && AS && NPJ) {
      /*  formData.append("tabPages", "[1,2,3,4,11,14,15,16]");
      formData.append(
        "data[280][date_effet_sante||Date d'effet||22]",
        "E0681191"
      ); */
      dataAdhesion["date_effet_sante||Date d'effet||22"] = "E0681191";
    } else if (TNS && NAS && PJ) {
      /*  formData.append("tabPages", "[1,2,3,4,12,14,15,16]");
      formData.append(
        "data[280][date_effet_sante||Date d'effet||22]",
        "E0681191"
      ); */
      dataAdhesion["date_effet_sante||Date d'effet||22"] = "E0681191";
    } else if (TNS && NAS && NPJ) {
      /* formData.append("tabPages", "[1,2,3,4,13,14,15,16]");
      formData.append(
        "data[280][date_effet_sante||Date d'effet||22]",
        "E0681191"
      ); */
      dataAdhesion["date_effet_sante||Date d'effet||22"] = "E0681191";
    } else if (NTNS && AS && PJ) {
      /*     formData.append("tabPages", "[1,2,3,5,6,14,15,16]");
      formData.append(
        "data[280][date_effet_sante||Date d'effet||22]",
        "E0681190"
      ); */
      dataAdhesion["date_effet_sante||Date d'effet||22"] = "E0681190";
    } else if (NTNS && AS && NPJ) {
      /*  formData.append("tabPages", "[1,2,3,5,7,14,15,16]");
      formData.append(
        "data[280][date_effet_sante||Date d'effet||22]",
        "E0681190"
      ); */
      dataAdhesion["date_effet_sante||Date d'effet||22"] = "E0681190";
    } else if (NTNS && NAS && PJ) {
      /*  formData.append("tabPages", "[1,2,3,5,9,14,15,16]");
      formData.append(
        "data[280][date_effet_sante||Date d'effet||22]",
        "E0681190"
      ); */
      dataAdhesion["date_effet_sante||Date d'effet||22"] = "E0681190";
    } else if (NTNS && NAS && NPJ) {
      /*  formData.append("tabPages", "[1,2,3,5,8,14,15,16]");
      formData.append(
        "data[280][date_effet_sante||Date d'effet||22]",
        "E0681190"
      ); */
      dataAdhesion["date_effet_sante||Date d'effet||22"] = "E0681190";
    }

    const A = true;
    let C = customerInfo[1]?.relationship === "married";
    const NC = customerInfo[1]?.relationship !== "married";
    const E0 = childsNbr === 0;
    const E1 =
      customerInfo[1]?.relationship === "married"
        ? customerInfo[2]?.relationship_rank === 11
        : customerInfo[1]?.relationship_rank === 11;
    const E2 =
      customerInfo[1]?.relationship === "married"
        ? customerInfo[3]?.relationship_rank === 12
        : customerInfo[2]?.relationship_rank === 12;
    const E3 =
      customerInfo[1]?.relationship === "married"
        ? customerInfo[4]?.relationship_rank === 13
        : customerInfo[3]?.relationship_rank === 13;
    const E4 =
      customerInfo[1]?.relationship === "married"
        ? customerInfo[5]?.relationship_rank === 14
        : customerInfo[4]?.relationship_rank === 14;
    const E5 =
      customerInfo[1]?.relationship === "married"
        ? customerInfo[6]?.relationship_rank === 15
        : customerInfo[5]?.relationship_rank === 15;
    const E6 =
      customerInfo[1]?.relationship === "married"
        ? customerInfo[7]?.relationship_rank === 16
        : customerInfo[6]?.relationship_rank === 16;
    let deletePagesFIC;
    if (A && NC && E0) {
      deletePagesFIC = [2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14];
    }
    if (A && C && E0) {
      deletePagesFIC = [1, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14];
    }
    if (A && C && E1) {
      deletePagesFIC = [1, 2, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14];
    }
    if (A && C && E1 && E2) {
      deletePagesFIC = [1, 2, 3, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14];
    }
    if (A && C && E1 && E2 && E3) {
      deletePagesFIC = [1, 2, 3, 4, 6, 7, 8, 9, 10, 11, 12, 13, 14];
    }
    if (A && C && E1 && E2 && E3 && E4) {
      deletePagesFIC = [1, 2, 3, 4, 5, 7, 8, 9, 10, 11, 12, 13, 14];
    }
    if (A && C && E1 && E2 && E3 && E4 && E5) {
      deletePagesFIC = [1, 2, 3, 4, 5, 6, 8, 9, 10, 11, 12, 13, 14];
    }
    if (A && C && E1 && E2 && E3 && E4 && E5 && E6) {
      deletePagesFIC = [1, 2, 3, 4, 5, 6, 7, 9, 10, 11, 12, 13, 14];
    }
    if (A && NC && E1) {
      deletePagesFIC = [1, 2, 3, 4, 5, 6, 7, 8, 10, 11, 12, 13, 14];
    }
    if (A && NC && E1 && E2) {
      deletePagesFIC = [1, 2, 3, 4, 5, 6, 7, 8, 9, 11, 12, 13, 14];
    }
    if (A && NC && E1 && E2 && E3) {
      deletePagesFIC = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 12, 13, 14];
    }
    if (A && NC && E1 && E2 && E3 && E4) {
      deletePagesFIC = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 13, 14];
    }
    if (A && NC && E1 && E2 && E3 && E4 && E5) {
      deletePagesFIC = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 14];
    }
    if (A && NC && E1 && E2 && E3 && E4 && E5 && E6) {
      deletePagesFIC = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13];
    }

    /*  axios.post(
      "https://editique.geoprod.com/view/ws_get_contrat.php",
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    ); */

    Object.keys(dataAdhesion).forEach((element) => {
      if (typeof dataAdhesion[element] !== "string")
        dataAdhesion[element] = `${dataAdhesion[element]}`;
    });
    axios
      .post(
        `${
          import.meta.env.VITE_APP_CLOUD_GEOPROD_APIURL
        }/subscription/subscribe_v2`,
        {
          // cotation_id:sessionStorage.getItem("quotation_id")?sessionStorage.getItem("quotation_id"):"",
          call_source: signature_electronique
            ? "parcours"
            : "parcours+signatureManuelle",
          document_produit: "acheel",
          id_opp: sessionStorage.getItem("SessionURLID"),
          ficher_conseil_print_data: {
            deletePagesFIC: deletePagesFIC,
            data: dataAdhesion,
          },
          option: [],
          information_bancaire: {
            bic_swift: dataAdhesion["bicremb_assure||BIC rembourssement||10"]
              ? dataAdhesion["bicremb_assure||BIC rembourssement||10"]
              : dataAdhesion["bic_sepa||BIC||5"],
            iban: dataAdhesion["ibanremb_assure||IBAN rembourssement||11"]
              ? dataAdhesion["ibanremb_assure||IBAN rembourssement||11"]
              : dataAdhesion["iban_sepa||IBAN||6"],
            type_iban: "prestation",
            // titulaire_compte: dataAdhesion[
            //   "nomprenom_sepa||Nom/Prénom du débiteur||1"
            // ]
            //   ? dataAdhesion["nomprenom_sepa||Nom/Prénom du débiteur||1"]
            //   : "",
            titulaire_compte: dataAdhesion["titulaire_compte_remb"]
              ? dataAdhesion["titulaire_compte_remb"]
              : "",
            nom_banque: BanqueRemboursement,
          },
          pricing: {
            third_app: {
              name: "ASolutions",
              id_external: 476820,
            },
            frais_courtage: fraisCourtage,
            frais_dossier_distributeur: fraisLie,
            customer_information: costumerData(
              proposition.produit.produit_sante.cotation_id
            ),
            contract_information: {
              effective_date: moment(
                dataAdhesion["date_effet_sante||Date d'effet||1"],
                "DD-MM-YYYY"
              ).format("DD/MM/yyyy"),
              mode_splitting: "Mensuel",
              signature_date: moment(
                dataAdhesion["ds_sante||Date de souscription||0"],
                "DD-MM-YYYY"
              ).format("DD/MM/yyyy"),
              payment_information: {
                mode_splitting: "Mensuel",
                withdrawal_day:
                  dataAdhesion[
                    "date_prelevement_sante||Date de prélèvement||24"
                  ],
                payment_mode:
                  dataAdhesion["type_paiement_sepa||Type de paiement||7"],
                bic: dataAdhesion["bic_sepa||BIC||5"],
                iban: dataAdhesion["iban_sepa||IBAN||6"],
                nom_banque: BanquePrelevement,
                // nom_prenom_debiteur: dataAdhesion[
                //   "nomprenom_sepa||Nom/Prénom du débiteur||1"
                // ]
                //   ? dataAdhesion["nomprenom_sepa||Nom/Prénom du débiteur||1"]
                //   : "",
                nom_prenom_debiteur: dataAdhesion["titulaire_compte_prev"]
                  ? dataAdhesion["titulaire_compte_prev"]
                  : "",
              },
            },
          },
          resiliation:
            dataAdhesion[
              "nousresiliation_garantie||Souhaitez-vous que nous nous chargions d'expédier, en votre nom et pour votre compte, la lettre de résiliation en respectant les conditions,à votre assureur ou à votre mutuelle actuel (le) ?||8"
            ] === "Oui"
              ? {
                  accepte: resiliation.accepte,
                  type: resiliation.type,
                  nom_ancier_assureur: resiliation.nom_ancier_assureur,
                  num_ancier_contrat: resiliation.num_ancier_contrat,
                  date_echeance:
                    resiliation.date_echeance &&
                    resiliation.type === "demande de a l'échéance"
                      ? resiliation.date_echeance
                      : "",
                }
              : {
                  accepte: "Non",
                  type: "",
                  nom_ancier_assureur: "",
                  num_ancier_contrat: "",
                  date_echeance: "",
                },
          destinataire_data:
            dataAdhesion[
              "nousresiliation_garantie||Souhaitez-vous que nous nous chargions d'expédier, en votre nom et pour votre compte, la lettre de résiliation en respectant les conditions,à votre assureur ou à votre mutuelle actuel (le) ?||8"
            ] === "Oui"
              ? {
                  nom: SessionStorageService()?.getResiliationCompany()
                    .denomination,
                  ville: SessionStorageService()?.getResiliationCompany().ville,
                  rue: SessionStorageService()?.getResiliationCompany().adresse,
                  code_postal:
                    SessionStorageService()?.getResiliationCompany().cp,
                  siren: SessionStorageService()?.getResiliationCompany().siren,
                }
              : null,
        },
        {
          headers: {
            idSession: localStorage.getItem("token"),
          },
        }
      )
      .then((res) => {
        if (
          res.data?.message?.message?.contract_information?.payment_information[
            "476820"
          ]?.iban
        ) {
          Swal.fire({
            icon: "error",
            text: res.data.message.message.contract_information
              .payment_information["476820"].iban,
            showConfirmButton: true,
            // timer: 3000,
          });
          return setOpenbackdrop(false);
        }

        if (res.data?.error === true) {
          Swal.fire({
            icon: "warning",
            text: "veuillez vérifier vos données",
          });
          setOpenbackdrop(false);
        } else {
          if (signature_electronique) {
            navigate("/adhesion/success", {
              state: {
                message:
                  "L'opportunité a été intégrée dans GeoProd et est actuellement en attente de la signature de l'adhérent.",
              },
            });
            setOpenbackdrop(false);
          } else {
            window.open(res.data?.pdf, "_blank");
            navigate("/adhesion/success", {
              state: {
                message:
                  "L'opportunité a été intégrée dans GeoProd et est actuellement en attente de la signature de l'adhérent.",
              },
            });
            setOpenbackdrop(false);
          }
        }
      })
      .catch((err) => {
        console.log(err);
        setOpenbackdrop(false);
      });
  };

  function isValidIBan(iban) {
    // iban = dataAdhesion["iban_sepa||IBAN||6"];
    var regex = new RegExp(/^[A-Z\s]{2}[0-9\s]{2}[A-Z0-9\s]{1,27}$/);
    let validIban = regex.test(iban.replace(/ /g, ""));

    return validIban;
  }

  function isValidBic(bic) {
    // bic = dataAdhesion["bic_sepa||BIC||5"];
    var regex = new RegExp(/^[A-Z\s]{6}[A-Z0-9\s]{2}([A-Z0-9\s]{3})?/);
    let validBic = regex.test(bic.replace(/ /g, ""));

    return validBic;
  }
  const signandsave = (iban, bic) => {
    subscribeHandler(true);
  };

  const save = () => {
    subscribeHandler(false);
  };

  if (loading) {
    return (
      <div>
        <Row
          gutter={22}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            minHeight: "70vh",
          }}
        >
          <ResiliationPopup
            modal={trigger}
            toggle={handleTriggerModal}
            stage={stage}
            setStage={setStage}
          />
          <Col xs={20} sm={20} md={20} lg={20} xl={20}>
            <Box
              sx={{
                width: "100%",
                paddingInline: "2rem",
                marginTop: "2rem",
              }}
            >
              <Row className={classes.actionsRow}>
                <Col>
                  <Button
                    disabled={
                      steps[stage] !== "Informations bancaires" ||
                      dataAdhesion[
                        "modesignature_sante||Mode de signature||9"
                      ] === "Papier (Signature manuscrite classique)"
                    }
                    className={classes.actionsBtn}
                    type="primary"
                    size="large"
                    style={
                      steps[stage] !== "Informations bancaires" ||
                      dataAdhesion[
                        "modesignature_sante||Mode de signature||9"
                      ] === "Papier (Signature manuscrite classique)"
                        ? {}
                        : { backgroundColor: "#ffc53d", color: "white" }
                    }
                    onClick={(e) => {
                      if (
                        checkTypes.includes(
                          dataAdhesion["bic_sepa||BIC||5"]?.replaceAll(" ", "")
                        ) ||
                        checkTypes.includes(
                          dataAdhesion["iban_sepa||IBAN||6"]?.replaceAll(
                            " ",
                            ""
                          )
                        ) ||
                        checkTypes.includes(
                          dataAdhesion[
                            "bicremb_assure||BIC rembourssement||10"
                          ]?.replaceAll(" ", "")
                        ) ||
                        checkTypes.includes(
                          dataAdhesion[
                            "ibanremb_assure||IBAN rembourssement||11"
                          ]?.replaceAll(" ", "")
                        ) ||
                        checkTypes.includes(
                          dataAdhesion["titulaire_compte_prev"]?.replaceAll(
                            " ",
                            ""
                          )
                        ) ||
                        checkTypes.includes(dataAdhesion["bank_prev"]) ||
                        checkTypes.includes(
                          dataAdhesion["titulaire_compte_remb"]?.replaceAll(
                            " ",
                            ""
                          )
                        ) ||
                        checkTypes.includes(
                          dataAdhesion["bank_remb"]?.replaceAll(" ", "")
                        )
                      ) {
                        alertMessage(
                          "Veuillez remplir tous les champs obligatoires.",
                          "error"
                        );
                      } else if (
                        isValidBic(dataAdhesion["bic_sepa||BIC||5"]) &&
                        isValidIBan(dataAdhesion["iban_sepa||IBAN||6"]) &&
                        isValidBic(
                          dataAdhesion["bicremb_assure||BIC rembourssement||10"]
                        ) &&
                        isValidIBan(
                          dataAdhesion[
                            "ibanremb_assure||IBAN rembourssement||11"
                          ]
                        )
                      ) {
                        if (
                          isNotFrenchBIC(dataAdhesion["bic_sepa||BIC||5"]) ||
                          isNotFrenchBIC(
                            dataAdhesion[
                              "bicremb_assure||BIC rembourssement||10"
                            ]
                          ) ||
                          isNotFrenchIBAN(
                            dataAdhesion[
                              "ibanremb_assure||IBAN rembourssement||11"
                            ]
                          ) ||
                          isNotFrenchIBAN(dataAdhesion["iban_sepa||IBAN||6"])
                        ) {
                          alertMessage(
                            "Nous sommes désolés, votre compte doit être domicilié en France .",
                            "error"
                          );
                        } else {
                          notification.destroy();
                          setConfirmationButton("Signer et enregistrer");
                          toggleConfirmModal();
                        }
                      } else if (
                        isValidBic(dataAdhesion["bic_sepa||BIC||5"]) &&
                        isValidIBan(dataAdhesion["iban_sepa||IBAN||6"]) &&
                        !dataAdhesion[
                          "ibanremb_assure||IBAN rembourssement||11"
                        ] &&
                        !dataAdhesion["bicremb_assure||BIC rembourssement||10"]
                      ) {
                        if (
                          isNotFrenchBIC(dataAdhesion["bic_sepa||BIC||5"]) ||
                          isNotFrenchBIC(
                            dataAdhesion[
                              "bicremb_assure||BIC rembourssement||10"
                            ]
                          ) ||
                          isNotFrenchIBAN(
                            dataAdhesion[
                              "ibanremb_assure||IBAN rembourssement||11"
                            ]
                          )
                        ) {
                          alertMessage(
                            "Nous sommes désolés, votre compte doit être domicilié en France .",
                            "error"
                          );
                        } else {
                          notification.destroy();
                          setConfirmationButton("Signer et enregistrer");
                          toggleConfirmModal();
                        }
                      } else if (
                        dataAdhesion["bic_sepa||BIC||5"] === "" ||
                        dataAdhesion["iban_sepa||IBAN||6"] === ""
                      ) {
                        // handleRequirementsSign();
                        alertMessage(
                          "Les champs BIC et IBAN sont obligatoires.",
                          "error"
                        );
                      } else if (
                        dataAdhesion[
                          "bicremb_assure||BIC rembourssement||10"
                        ] &&
                        isValidBic(
                          dataAdhesion["bicremb_assure||BIC rembourssement||10"]
                        ) &&
                        !dataAdhesion[
                          "ibanremb_assure||IBAN rembourssement||11"
                        ]
                      ) {
                        // setOpenRemb(
                        //   "Veuillez remplir votre IBAN remboursement."
                        // );
                        alertMessage(
                          "Veuillez remplir votre IBAN remboursement.",
                          "error"
                        );
                      } else if (
                        dataAdhesion[
                          "ibanremb_assure||IBAN rembourssement||11"
                        ] &&
                        isValidIBan(
                          dataAdhesion[
                            "ibanremb_assure||IBAN rembourssement||11"
                          ]
                        ) &&
                        !dataAdhesion["bicremb_assure||BIC rembourssement||10"]
                      ) {
                        // setOpenRemb(
                        //   "Veuillez remplir votre BIC remboursement."
                        // );
                        alertMessage(
                          "Veuillez remplir votre BIC remboursement.",
                          "error"
                        );
                      } else if (
                        !isValidBic(dataAdhesion["bic_sepa||BIC||5"]) ||
                        (dataAdhesion[
                          "bicremb_assure||BIC rembourssement||10"
                        ] &&
                          !isValidBic(
                            dataAdhesion[
                              "bicremb_assure||BIC rembourssement||10"
                            ]
                          ))
                      ) {
                        alertMessage(
                          "Veuillez vérifier le champ BIC.",
                          "error"
                        );
                      } else if (
                        !isValidIBan(dataAdhesion["iban_sepa||IBAN||6"]) ||
                        (dataAdhesion[
                          "ibanremb_assure||IBAN rembourssement||11"
                        ] &&
                          !isValidIBan(
                            dataAdhesion[
                              "ibanremb_assure||IBAN rembourssement||11"
                            ]
                          ))
                      ) {
                        alertMessage(
                          "Veuillez vérifier le champ IBAN.",
                          "error"
                        );
                      }
                    }}
                  >
                    <img src={sign} style={{ marginRight: "0.2rem" }} alt="" />
                    e-signature
                  </Button>

                  <Button
                    size="large"
                    className={classes.actionsBtn}
                    color="warning"
                    onClick={() => {
                      if (
                        checkTypes.includes(
                          dataAdhesion["bic_sepa||BIC||5"]?.replaceAll(" ", "")
                        ) ||
                        checkTypes.includes(
                          dataAdhesion["iban_sepa||IBAN||6"]?.replaceAll(
                            " ",
                            ""
                          )
                        ) ||
                        checkTypes.includes(
                          dataAdhesion[
                            "bicremb_assure||BIC rembourssement||10"
                          ]?.replaceAll(" ", "")
                        ) ||
                        checkTypes.includes(
                          dataAdhesion[
                            "ibanremb_assure||IBAN rembourssement||11"
                          ]?.replaceAll(" ", "")
                        ) ||
                        checkTypes.includes(
                          dataAdhesion["titulaire_compte_prev"]?.replaceAll(
                            " ",
                            ""
                          )
                        ) ||
                        checkTypes.includes(dataAdhesion["bank_prev"]) ||
                        checkTypes.includes(
                          dataAdhesion["titulaire_compte_remb"]?.replaceAll(
                            " ",
                            ""
                          )
                        ) ||
                        checkTypes.includes(
                          dataAdhesion["bank_remb"]?.replaceAll(" ", "")
                        )
                      ) {
                        alertMessage(
                          "Veuillez remplir tous les champs obligatoires.",
                          "error"
                        );
                      } else if (
                        isValidBic(dataAdhesion["bic_sepa||BIC||5"]) &&
                        isValidIBan(dataAdhesion["iban_sepa||IBAN||6"]) &&
                        isValidBic(
                          dataAdhesion["bicremb_assure||BIC rembourssement||10"]
                        ) &&
                        isValidIBan(
                          dataAdhesion[
                            "ibanremb_assure||IBAN rembourssement||11"
                          ]
                        )
                      ) {
                        if (
                          isNotFrenchBIC(dataAdhesion["bic_sepa||BIC||5"]) ||
                          isNotFrenchBIC(
                            dataAdhesion[
                              "bicremb_assure||BIC rembourssement||10"
                            ]
                          ) ||
                          isNotFrenchIBAN(
                            dataAdhesion[
                              "ibanremb_assure||IBAN rembourssement||11"
                            ]
                          ) ||
                          isNotFrenchIBAN(dataAdhesion["iban_sepa||IBAN||6"])
                        ) {
                          alertMessage(
                            "Nous sommes désolés, votre compte doit être domicilié en France .",
                            "error"
                          );
                        } else {
                          notification.destroy();
                          setConfirmationButton("Enregistrer");
                          toggleConfirmModal();
                        }
                      } else if (
                        isValidBic(dataAdhesion["bic_sepa||BIC||5"]) &&
                        isValidIBan(dataAdhesion["iban_sepa||IBAN||6"]) &&
                        !dataAdhesion[
                          "ibanremb_assure||IBAN rembourssement||11"
                        ] &&
                        !dataAdhesion["bicremb_assure||BIC rembourssement||10"]
                      ) {
                        if (
                          isNotFrenchBIC(dataAdhesion["bic_sepa||BIC||5"]) ||
                          isNotFrenchBIC(
                            dataAdhesion[
                              "bicremb_assure||BIC rembourssement||10"
                            ]
                          ) ||
                          isNotFrenchIBAN(
                            dataAdhesion[
                              "ibanremb_assure||IBAN rembourssement||11"
                            ]
                          )
                        ) {
                          alertMessage(
                            "Nous sommes désolés, votre compte doit être domicilié en France .",
                            "error"
                          );
                        } else {
                          notification.destroy();
                          setConfirmationButton("Enregistrer");
                          toggleConfirmModal();
                        }
                      } else if (
                        dataAdhesion["bic_sepa||BIC||5"] === "" ||
                        dataAdhesion["iban_sepa||IBAN||6"] === ""
                      ) {
                        alertMessage(
                          "Les champs BIC et IBAN sont obligatoires.",
                          "error"
                        );
                      } else if (
                        dataAdhesion[
                          "bicremb_assure||BIC rembourssement||10"
                        ] &&
                        isValidBic(
                          dataAdhesion["bicremb_assure||BIC rembourssement||10"]
                        ) &&
                        !dataAdhesion[
                          "ibanremb_assure||IBAN rembourssement||11"
                        ]
                      ) {
                        alertMessage(
                          "Veuillez remplir votre IBAN remboursement.",
                          "error"
                        );
                      } else if (
                        dataAdhesion[
                          "ibanremb_assure||IBAN rembourssement||11"
                        ] &&
                        isValidIBan(
                          dataAdhesion[
                            "ibanremb_assure||IBAN rembourssement||11"
                          ]
                        ) &&
                        !dataAdhesion["bicremb_assure||BIC rembourssement||10"]
                      ) {
                        alertMessage(
                          "Veuillez remplir votre BIC remboursement.",
                          "error"
                        );
                      } else if (
                        !isValidBic(dataAdhesion["bic_sepa||BIC||5"]) ||
                        (dataAdhesion[
                          "bicremb_assure||BIC rembourssement||10"
                        ] &&
                          !isValidBic(
                            dataAdhesion[
                              "bicremb_assure||BIC rembourssement||10"
                            ]
                          ))
                      ) {
                        alertMessage(
                          "Veuillez vérifier le champ BIC.",
                          "error"
                        );
                      } else if (
                        !isValidIBan(dataAdhesion["iban_sepa||IBAN||6"]) ||
                        (dataAdhesion[
                          "ibanremb_assure||IBAN rembourssement||11"
                        ] &&
                          !isValidIBan(
                            dataAdhesion[
                              "ibanremb_assure||IBAN rembourssement||11"
                            ]
                          ))
                      ) {
                        alertMessage(
                          "Veuillez vérifier le champ IBAN.",
                          "error"
                        );
                      }
                    }}
                    disabled={
                      steps[stage] !== "Informations bancaires" ||
                      dataAdhesion[
                        "modesignature_sante||Mode de signature||9"
                      ] !== "Papier (Signature manuscrite classique)"
                    }
                    type="primary"
                    style={
                      steps[stage] !== "Informations bancaires" ||
                      dataAdhesion[
                        "modesignature_sante||Mode de signature||9"
                      ] !== "Papier (Signature manuscrite classique)"
                        ? {}
                        : { backgroundColor: "#ffc53d", color: "white" }
                    }
                  >
                    <img
                      src={saveIcon}
                      style={{ marginRight: "0.2rem" }}
                      alt=""
                    />
                    Enregistrer PDF
                  </Button>

                  <Button
                    className={classes.actionsBtn}
                    size="large"
                    type="primary"
                    danger
                    onClick={() => {
                      sessionStorage.clear();
                      if (id)
                        window.location = `${
                          import.meta.env.VITE_APP_URL_COMPARATEUR
                        }/?partnership_id=${id}`;
                    }}
                  >
                    Remise à zéro
                  </Button>
                </Col>
              </Row>
              <Stepper activeStep={stage} alternativeLabel>
                {steps.map((label) => (
                  <Step key={label}>
                    <StepLabel>{label}</StepLabel>
                  </Step>
                ))}
              </Stepper>
              <br />
              <div className={classes.stageContainer}>
                {steps[stage] === "Adhérent principal" && (
                  <Assure
                    handleChange={handleChange}
                    data={data}
                    isTrueSS={isTrueSS}
                    isValidSS={isValidSS}
                    isValidEmail={isValidEmail}
                    setData={setData}
                    dataAdhesion={dataAdhesion}
                    setDataAdhesion={setDataAdhesion}
                    jumeauOptions={jumeauOptions}
                  />
                )}

                {steps[stage] === "Conjoint(e)" && (
                  <Conjoint
                    handleChange={handleChange}
                    data={data}
                    setData={setData}
                    isValidSS={isValidSS}
                    // isTrueSS2={isTrueSS}
                    isTrueSS2={isTrueSS2}
                    dataAdhesion={dataAdhesion}
                    setDataAdhesion={setDataAdhesion}
                    jumeauOptions={jumeauOptions}
                  />
                )}

                {steps[stage] === "Enfant(s)" && nbrChild && tempFils && (
                  <div>
                    {childs.map((child, index) => {
                      if (index < nbrChild) {
                        return (
                          <Enfant
                            key={index}
                            handleChange={handleChange}
                            fils={tempFils[index]}
                            data={data}
                            setData={setData}
                            dataAdhesion={dataAdhesion}
                            setDataAdhesion={setDataAdhesion}
                            isTrueSSEnfant={isTrueSSEnfant}
                            isValidSS={isValidSS}
                            IDS={child}
                            idx={index}
                            jumeauOptions={jumeauOptions}
                          />
                        );
                      }
                      return null;
                    })}
                  </div>
                )}
                {steps[stage] === "Récapitulatif" && (
                  <Garanties
                    handleChange={handleChange}
                    data={dataAdhesion}
                    setData={setDataAdhesion}
                  />
                )}
                {steps[stage] === "Fiche conseil" && (
                  <FicheConseil
                    handleChange={handleChange}
                    data={dataAdhesion}
                    setData={setDataAdhesion}
                  />
                )}
                {steps[stage] === "Informations bancaires" && (
                  <Echeance
                    setBanquePrelevement={setBanquePrelevement}
                    setBanqueRemboursement={setBanqueRemboursement}
                    handleChange={handleChange}
                    data={dataAdhesion}
                    setData={setDataAdhesion}
                  />
                )}
              </div>
              <div className={classes.stepsBtnContainer}>
                <Button
                  style={{
                    display: "flex",
                    alignItems: "center",
                  }}
                  disabled={steps[stage] === "Adhérent principal"}
                  type="primary"
                  size="large"
                  onClick={prevHandler}
                >
                  <FontAwesomeIcon
                    style={{ marginRight: "0.5rem" }}
                    icon={faArrowLeft}
                  />
                  Précédent
                </Button>

                <Button
                  disabled={steps[stage] === "Informations bancaires"}
                  style={{
                    display:
                      steps[stage] === "Informations bancaires"
                        ? "none"
                        : "block",
                  }}
                  size="large"
                  type="primary"
                  onClick={nextHandler}
                >
                  Suivant
                  <FontAwesomeIcon
                    style={{ marginLeft: "0.5rem" }}
                    icon={faArrowRight}
                  />
                </Button>
              </div>
            </Box>
          </Col>
        </Row>
        <Backdrop
          sx={{
            color: "#fff",
            zIndex: (theme) => theme.zIndex.drawer + 1,
          }}
          open={openbackdrop}
          /* onClick={handleCloseBackdrop} */
        >
          <div className={classes.loaderContainer}>
            <Box sx={{ width: "100%" }}>
              <CircularProgress style={{ width: "4rem", height: "4rem" }} />
            </Box>
            <h2>Veuillez patienter un instant.</h2>
          </div>
        </Backdrop>
        <Modal
          centered
          width={700}
          onCancel={toggleConfirmModal}
          open={modal}
          footer={[
            <Col key="x" className={classes.btn}>
              <Button
                type="primary"
                onClick={() => {
                  switch (confirmationButton) {
                    case "Enregistrer":
                      save();
                      toggleConfirmModal();
                      break;
                    case "Signer et enregistrer":
                      signandsave();
                      toggleConfirmModal();
                      break;
                    default:
                      break;
                  }
                }}
                disabled={!check}
              >
                Continuer
              </Button>
            </Col>,
          ]}
        >
          <FontAwesomeIcon
            icon={faExclamationCircle}
            className={classes.icon}
          />

          <Row>
            <p className={classes.title}>
              J'atteste avoir le consentement du client, avoir respecté le
              devoir de conseil ainsi que les délais réglementaires entre
              l'envoi du devis et l'adhésion.
            </p>
          </Row>
          <Row className={classes.checkboxRow} style={{ marginBlock: "2rem" }}>
            <Col>
              <Checkbox onChange={handleChangeCheckbox}>Oui</Checkbox>
            </Col>
          </Row>
        </Modal>
      </div>
    );
  }
};

export default Adhesion;
