import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import * as Sentry from '@sentry/react';

const root = ReactDOM.createRoot(document.getElementById('root'));
if (import.meta.env.VITE_APP_ENVIRONMENT === 'production') {
  Sentry.init({
    dsn: 'https://37830bab57802d17ebb18c5123fc0997@sentry.neopolis-dev.com/35',
  });
}

root.render(
  // <React.StrictMode>
  <App />
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
