/* eslint-disable no-unused-vars */
import React from "react";
import classes from "./Echeance.module.css";
import { Row, Col, Form, Input, Select, Tooltip } from "antd";
import SessionStorageService from "../../../utils/sessionStorageService";
import { InfoCircleOutlined } from "@ant-design/icons";
import GlobalContext from "../../../contexts/GlobalContext";

const Echances = ({
  data,
  setData,
  isValidBic,
  isValidIBan,
  setBanquePrelevement,
  setBanqueRemboursement,
}) => {
  const { setDataAdhesion, dataAdhesion } = React.useContext(GlobalContext);
  const [sameBank, setSameBank] = React.useState(false);
  const datePrelevementOptions = [
    { label: "5", value: "5" },
    { label: "10", value: "10" },
    { label: "15", value: "15" },
  ];
  const paymentMethodOptions = [
    { label: "Prélèvement automatique", value: "Prélèvement automatique" },
    { label: "PRELEVEMENT SEPA", value: "PRELEVEMENT SEPA" },
    { label: "CHEQUE", value: "CHEQUE" },
  ];
  const paymentOptions = [
    {
      label: <span style={{ color: "black" }}>Prélèvement automatique</span>,
      value: "Prélèvement automatique",
    },
    {
      label: <span style={{ color: "black" }}>PRELEVEMENT SEPA</span>,
      value: "Direct Debit",
    },
    { label: <span style={{ color: "black" }}>CHEQUE</span>, value: "Cheque" },
  ];

  const handleChange = (e, changeType, name) => {
    let prospect = SessionStorageService().getProspect();
    prospect[name] = changeType === "change" ? e.target.value : e;
    SessionStorageService().setProspect(prospect);
    let key = name;
    setData((prev) => {
      return {
        ...prev,
        [key]: changeType === "change" ? e.target.value : e,
      };
    });
  };
  React.useEffect(() => {
    setData({
      ...data,
      "type_paiement_sepa||Type de paiement||7": "Direct Debit",
      "nomprenom_sepa||Nom/Prénom du débiteur||1":
        data["nom_assure||Nom||1"] + " " + data["prenom_assure||Prénom||2"],
      "adresse_sepa||Adresse||2":
        SessionStorageService().getCustomerInfo()[0].address.locality,
      "cp_sepa||Code postal||3": data["cp_assure||Code postal||4"],
      "ville_sepa||Ville||4":
        SessionStorageService().getCustomerInfo()[0].address.locality,
      "bic_sepa||BIC||5": data["bic_sepa||BIC||5"] || "",
      "iban_sepa||IBAN||6": data["iban_sepa||IBAN||6"] || "",
      "ibanremb_assure||IBAN rembourssement||11":
        data["ibanremb_assure||IBAN rembourssement||11"] || "",
      "bicremb_assure||BIC rembourssement||10":
        data["bicremb_assure||BIC rembourssement||10"] || "",
    });

    // eslint-disable-next-line
  }, []);

  return data["type_paiement_sepa||Type de paiement||7"] ? (
    <Form layout="vertical" className={classes.form}>
      <h3
        style={{
          textAlign: "center",
          paddingTop: "1rem",
          borderBottom: "1px solid #adb5bd",
          marginBottom: "2.5rem",
        }}
      >
        Votre compte bancaire de prélèvement
      </h3>
      <Row gutter={25}>
        <Col xs={24} sm={24} md={24} lg={12} xl={12}>
          <Form.Item label={"Titulaire du compte prélèvement"} required>
            <Input
              size="large"
              name="titulaire_compte_prev"
              defaultValue={data["titulaire_compte_prev"]}
              onChange={(e) => {
                handleChange(e, "change", "titulaire_compte_prev");
                if (sameBank) {
                  setDataAdhesion((prev) => {
                    return {
                      ...prev,
                      bank_remb: dataAdhesion["bank_remb"],
                      titulaire_compte_remb: e.target.value,
                    };
                  });
                }
              }}
              suffix={
                <Tooltip
                  placement="top"
                  title={"Référez-vous à votre relevé d'identité bancaire ."}
                >
                  <InfoCircleOutlined style={{ color: "rgba(0,0,0,.45)" }} />
                </Tooltip>
              }
            />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={24} lg={12} xl={12}>
          <Form.Item label={"Votre établissement bancaire"} required>
            <Input
              size="large"
              name="bank_prev"
              defaultValue={data["bank_prev"]}
              onChange={(e) => {
                handleChange(e, "change", "bank_prev");
                if (sameBank) {
                  setDataAdhesion((prev) => {
                    return {
                      ...prev,
                      bank_remb: e.target.value,
                      titulaire_compte_remb:
                        dataAdhesion["titulaire_compte_prev"],
                    };
                  });
                }
              }}
              suffix={
                <Tooltip
                  placement="top"
                  title={"Référez-vous à votre relevé d'identité bancaire ."}
                >
                  <InfoCircleOutlined style={{ color: "rgba(0,0,0,.45)" }} />
                </Tooltip>
              }
            />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={24} lg={12} xl={12}>
          <Form.Item
            label={"IBAN prélèvement"}
            name="iban_sepa||IBAN||6"
            rules={[
              {
                required: true,
                message: "Veuillez remplir ce champ.",
              },
            ]}
          >
            <Input
              size="large"
              name="iban_sepa||IBAN||6"
              defaultValue={data["iban_sepa||IBAN||6"]}
              onChange={(e) => {
                handleChange(e, "change", "iban_sepa||IBAN||6");
                if (sameBank) {
                  setDataAdhesion((prev) => {
                    return {
                      ...prev,
                      "ibanremb_assure||IBAN rembourssement||11":
                        e.target.value,
                      "bicremb_assure||BIC rembourssement||10":
                        dataAdhesion["bic_sepa||BIC||5"],
                    };
                  });
                }
              }}
              suffix={
                <Tooltip
                  placement="top"
                  title={"Référez-vous à votre relevé d'identité bancaire ."}
                >
                  <InfoCircleOutlined style={{ color: "rgba(0,0,0,.45)" }} />
                </Tooltip>
              }
            />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={24} lg={12} xl={12}>
          <Form.Item
            label={"BIC prélèvement"}
            name="bic_sepa||BIC||5"
            rules={[
              {
                required: true,
                message: "Veuillez remplir ce champ.",
              },
            ]}
          >
            <Input
              size="large"
              name="bic_sepa||BIC||5"
              defaultValue={data["bic_sepa||BIC||5"]}
              onChange={(e) => {
                handleChange(e, "change", "bic_sepa||BIC||5");

                if (sameBank) {
                  setDataAdhesion((prev) => {
                    return {
                      ...prev,
                      "ibanremb_assure||IBAN rembourssement||11":
                        dataAdhesion["iban_sepa||IBAN||6"],
                      "bicremb_assure||BIC rembourssement||10": e.target.value,
                    };
                  });
                }
              }}
              suffix={
                <Tooltip
                  placement="top"
                  title={"Référez-vous à votre relevé d'identité bancaire ."}
                >
                  <InfoCircleOutlined style={{ color: "rgba(0,0,0,.45)" }} />
                </Tooltip>
              }
            />
          </Form.Item>
        </Col>

        <Col xs={24} sm={24} md={24} lg={12} xl={12}>
          <Form.Item label={"Mode de règlement des cotisations"}>
            <Input
              size="large"
              // onSelect={(e) =>
              //   handleChange(
              //     e,
              //     "select",
              //     "modepc_sante||Mode de paiement du comptant||22"
              //   )
              // }
              name="modepc_sante||Mode de paiement du comptant||22"
              // options={paymentMethodOptions}
              value={data["modepc_sante||Mode de paiement du comptant||22"]}
              // type="select"
              style={{ color: "black" }}
              disabled
            />
          </Form.Item>
          {/* <Form.Item label={"Nom de la banque prélèvement"}>
            <Input
              size="large"
              onChange={(e) => {
                setBanquePrelevement(e.target.value);
              }}
            />
          </Form.Item> */}
        </Col>
        <Col xs={24} sm={24} md={24} lg={12} xl={12}>
          <Form.Item label={"Jour de prélèvement"}>
            <Select
              size="large"
              onSelect={(e) =>
                handleChange(
                  e,
                  "select",
                  "date_prelevement_sante||Date de prélèvement||24"
                )
              }
              options={datePrelevementOptions}
              name="date_prelevement_sante||Date de prélèvement||24"
              value={data["date_prelevement_sante||Date de prélèvement||24"]}
            />
          </Form.Item>
        </Col>

        <Col xs={24} sm={24} md={24} lg={12} xl={12}>
          <Form.Item
            label={
              "Utilisez le même compte pour les remboursements des sinistres"
            }
          >
            <Select
              size="large"
              defaultValue={
                SessionStorageService().getProspect()?.sameBank || false
              }
              onSelect={(e) => {
                handleChange(e, "select", "sameBank");
                setSameBank(e);
                if (e) {
                  setDataAdhesion((prev) => {
                    return {
                      ...prev,
                      "ibanremb_assure||IBAN rembourssement||11":
                        dataAdhesion["iban_sepa||IBAN||6"],
                      "bicremb_assure||BIC rembourssement||10":
                        dataAdhesion["bic_sepa||BIC||5"],
                      titulaire_compte_remb:
                        dataAdhesion["titulaire_compte_prev"],
                      bank_remb: dataAdhesion["bank_prev"],
                    };
                  });
                } else {
                  setDataAdhesion((prev) => {
                    return {
                      ...prev,
                      "ibanremb_assure||IBAN rembourssement||11": "",
                      "bicremb_assure||BIC rembourssement||10": "",
                      titulaire_compte_remb: "",
                      bank_remb: "",
                    };
                  });
                }
              }}
              options={[
                { label: "Oui", value: true },
                { label: "Non", value: false },
              ]}
            />
          </Form.Item>
        </Col>
        {SessionStorageService().getProspect()?.sameBank && (
          <Col xs={24} sm={24} md={24} lg={12} xl={12}>
            <Form.Item label={"Mode de remboursement des sinistres"}>
              <Input
                size="large"
                value={"Virement"}
                // type="select"
                style={{ color: "black" }}
                disabled
              />
            </Form.Item>
          </Col>
        )}
      </Row>
      {!SessionStorageService().getProspect()?.sameBank && (
        <div>
          <h3
            style={{
              textAlign: "center",
              paddingTop: "1rem",
              borderBottom: "1px solid #adb5bd",
              marginBottom: "2.5rem",
            }}
          >
            Votre compte bancaire de remboursement
          </h3>
          <Row gutter={25}>
            <Col xs={24} sm={24} md={24} lg={12} xl={12}>
              <Form.Item
                label={"Titulaire du compte remboursement"}
                tooltip="S'il est différent du Compte Prélèvement."
                required
              >
                <Input
                  size="large"
                  name="titulaire_compte_remb"
                  defaultValue={data["titulaire_compte_remb"]}
                  onChange={(e) =>
                    handleChange(e, "change", "titulaire_compte_remb")
                  }
                  suffix={
                    <Tooltip
                      placement="top"
                      title={
                        "Référez-vous à votre relevé d'identité bancaire ."
                      }
                    >
                      <InfoCircleOutlined
                        style={{ color: "rgba(0,0,0,.45)" }}
                      />
                    </Tooltip>
                  }
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={24} lg={12} xl={12}>
              <Form.Item
                label={"Votre établissement bancaire"}
                tooltip="S'il est différent du Compte Prélèvement."
                required
              >
                <Input
                  size="large"
                  name="bank_remb"
                  defaultValue={data["bank_remb"]}
                  onChange={(e) => handleChange(e, "change", "bank_remb")}
                  suffix={
                    <Tooltip
                      placement="top"
                      title={
                        "Référez-vous à votre relevé d'identité bancaire ."
                      }
                    >
                      <InfoCircleOutlined
                        style={{ color: "rgba(0,0,0,.45)" }}
                      />
                    </Tooltip>
                  }
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={24} lg={12} xl={12}>
              <Form.Item
                label={"IBAN remboursement"}
                tooltip="S'il est différent du Compte Prélèvement."
                required
              >
                <Input
                  size="large"
                  name="ibanremb_assure||IBAN rembourssement||11"
                  defaultValue={
                    data["ibanremb_assure||IBAN rembourssement||11"]
                  }
                  onChange={(e) =>
                    handleChange(
                      e,
                      "change",
                      "ibanremb_assure||IBAN rembourssement||11"
                    )
                  }
                  suffix={
                    <Tooltip
                      placement="top"
                      title={
                        "Référez-vous à votre relevé d'identité bancaire ."
                      }
                    >
                      <InfoCircleOutlined
                        style={{ color: "rgba(0,0,0,.45)" }}
                      />
                    </Tooltip>
                  }
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={24} lg={12} xl={12}>
              <Form.Item
                label={"BIC remboursement"}
                tooltip="S'il est différent du Compte Prélèvement."
                required
              >
                <Input
                  size="large"
                  name="bicremb_assure||BIC rembourssement||10"
                  defaultValue={data["bicremb_assure||BIC rembourssement||10"]}
                  onChange={(e) =>
                    handleChange(
                      e,
                      "change",
                      "bicremb_assure||BIC rembourssement||10"
                    )
                  }
                  suffix={
                    <Tooltip
                      placement="top"
                      title={
                        "Référez-vous à votre relevé d'identité bancaire ."
                      }
                    >
                      <InfoCircleOutlined
                        style={{ color: "rgba(0,0,0,.45)" }}
                      />
                    </Tooltip>
                  }
                />
              </Form.Item>
            </Col>

            <Col xs={24} sm={24} md={24} lg={12} xl={12}>
              <Form.Item label={"Mode de remboursement des sinistres"}>
                <Input
                  size="large"
                  value={"Virement"}
                  // type="select"
                  style={{ color: "black" }}
                  disabled
                />
              </Form.Item>
            </Col>
          </Row>
        </div>
      )}
    </Form>
  ) : (
    <></>
  );
};

export default Echances;
