import React from "react";
import classes from "./Conjoint.module.css";
import { Row, Col, Form, Input, Select, Tooltip, AutoComplete } from "antd";
import SessionStorageService from "../../../utils/sessionStorageService";
import moment from "moment/moment";
import { InfoCircleOutlined } from "@ant-design/icons";
import listPays from "../../../constants/listPays.json";

const Conjoint = ({
  isValidSS,
  isTrueSS2,
  dataAdhesion,
  setDataAdhesion,
  jumeauOptions,
}) => {
  const [form] = Form.useForm();
  const customerInfoC = SessionStorageService()
    ?.getCustomerInfo()
    ?.find((e) => e?.relationship_rank === 2);
  // eslint-disable-next-line no-unused-vars
  const [conjoint, setConjoint] = React.useState(
    SessionStorageService().getEmailProspect()
  );
  const options = [
    { label: "M", value: "M" },
    { label: "Mme", value: "Mme" },
  ];
  const regimeOptions = [
    { label: "Sécurité sociale", value: "Régime général" },
    { label: "Travailleurs non salariés", value: "Travailleurs non salariés" },
    { label: "Agricole salarié", value: "Agricole salarié" },
    { label: "Agricole exploitant", value: "Agricole exploitant" },
    { label: "Alsace mosellle", value: "Régime local" },
  ];
  const optionsPays = Object.entries(listPays)
    .map(([code, name]) => ({
      value: name,
      key: name,
    }))
    .sort((a, b) => a.value.localeCompare(b.value));
  const [filteredOptions, setFilteredOptions] = React.useState(optionsPays);
  React.useEffect(() => {
    let newdata = dataAdhesion;
    newdata["civilite_conjoint||Civilité||0"] =
      conjoint["civilite_conjoint||Civilité||0"] || "Mme";
    newdata["ro_conjoint||Régime||4"] = customerInfoC.job.regime;
    newdata["dn_conjoint||Date de naissance||3"] =
      customerInfoC.birth_info.date;
    newdata["nom_conjoint||Nom||1"] =
      SessionStorageService().getProspect()["nom_conjoint||Nom||1"] || "";
    newdata["prenom_conjoint||Prénom||2"] =
      SessionStorageService().getProspect()["prenom_conjoint||Prénom||2"] || "";
    newdata["num_sec_conjoint||N° sécurité sodiale||6"] =
      SessionStorageService().getProspect()[
        "num_sec_conjoint||N° sécurité sodiale||6"
      ] || "";
    newdata["lieu_naissance_conjoint"] = SessionStorageService().getProspect()[
      "lieu_naissance_conjoint"
    ]
      ? SessionStorageService().getProspect()["lieu_naissance_conjoint"]
      : "";

    setDataAdhesion(newdata);
  }, []);
  const handleChange = (e, changeType, name) => {
    let prospect = SessionStorageService().getProspect();
    prospect[name] = changeType === "change" ? e.target.value : e;
    SessionStorageService().setProspect(prospect);
    let key = name;
    setDataAdhesion((prev) => {
      return {
        ...prev,
        [key]: changeType === "change" ? e.target.value : e,
      };
    });
  };
  const onSelectPay = (value, option) => {
    handleChange(option.key, "select", "lieu_naissance_conjoint");
  };
  return (
    <Form layout="vertical" className={classes.form}>
      <Row gutter={24}>
        <Col
          xs={24}
          sm={24}
          md={24}
          lg={12}
          xl={12}
          style={{ margin: "0.4rem 0" }}
        >
          <Form.Item required label="Civilité" initialValue={options[1]}>
            <Select
              size="large"
              onChange={(e) =>
                handleChange(e, "select", "civilite_conjoint||Civilité||0")
              }
              name="civilite_conjoint||Civilité||0"
              value={
                dataAdhesion["civilite_conjoint||Civilité||0"] || options[1]
              }
              options={options}
            />
          </Form.Item>
        </Col>
        <Col
          xs={24}
          sm={24}
          md={24}
          lg={12}
          xl={12}
          style={{ margin: "0.4rem 0" }}
        >
          <Form.Item required label="Nom">
            <Input
              size="large"
              onChange={(e) =>
                handleChange(e, "change", "nom_conjoint||Nom||1")
              }
              name="nom_conjoint||Nom||1"
              defaultValue={conjoint["nom_conjoint||Nom||1"] || ""}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={24}>
        <Col
          xs={24}
          sm={24}
          md={24}
          lg={12}
          xl={12}
          style={{ margin: "0.4rem 0" }}
        >
          <Form.Item required label="Prénom">
            <Input
              size="large"
              onChange={(e) =>
                handleChange(e, "change", "prenom_conjoint||Prénom||2")
              }
              defaultValue={conjoint["prenom_conjoint||Prénom||2"] || ""}
              name="prenom_conjoint||Prénom||2"
            />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={24} lg={12} xl={12}>
          <Form.Item
            label={"Pays de naissance"}
            required
            name="lieu_naissance_conjoint"
          >
            <AutoComplete
              allowClear
              onClear={() => {
                handleChange("", "select", "lieu_naissance_conjoint");
              }}
              options={filteredOptions}
              size="large"
              onBlur={(e) => {
                const isTextFound = Object.values(listPays).some((value) =>
                  value.toLowerCase().includes(e.target.value.toLowerCase())
                );
                if (!isTextFound) {
                  form.setFieldsValue({
                    ...form.getFieldsValue(),
                    lieu_naissance_conjoint: "",
                  });
                }
              }}
              onSearch={(text) => {
                if (text !== "") {
                  const filteredOptions = optionsPays.filter((option) =>
                    option.value.toLowerCase().includes(text.toLowerCase())
                  );
                  setFilteredOptions(filteredOptions);
                } else {
                  setFilteredOptions(optionsPays);
                }
              }}
              onSelect={onSelectPay}
              defaultValue={
                SessionStorageService().getProspect()["lieu_naissance_conjoint"]
                  ? SessionStorageService().getProspect()[
                      "lieu_naissance_conjoint"
                    ]
                  : ""
              }
            />
          </Form.Item>
        </Col>
        <Col
          xs={24}
          sm={24}
          md={24}
          lg={12}
          xl={12}
          style={{ margin: "0.4rem 0" }}
        >
          <Form.Item
            label="Date de naissance"
            name="dn_conjoint||Date de naissance||3"
            required
          >
            <Input
              size="large"
              defaultValue={moment(
                customerInfoC.birth_info.date,
                "DD/MM/YYYY"
              ).format("DD/MM/YYYY")}
              onChange={(e) =>
                handleChange(e, "change", "dn_conjoint||Date de naissance||3")
              }
              name="dn_conjoint||Date de naissance||3"
              style={{
                color: "black",
              }}
              disabled
            />
          </Form.Item>
        </Col>

        <Col
          xs={24}
          sm={24}
          md={24}
          lg={12}
          xl={12}
          style={{ margin: "0.4rem 0" }}
        >
          <Form.Item
            label="N° sécurité sociale"
            required
            name="num_sec_conjoint||N° sécurité sodiale||6"
          >
            <Input
              size="large"
              onChange={(e) => {
                handleChange(
                  e,
                  "change",
                  "num_sec_conjoint||N° sécurité sodiale||6"
                );
                isValidSS(e.target.value);
                isTrueSS2(e.target.value);
              }}
              name="num_sec_conjoint||N° sécurité sodiale||6"
              defaultValue={
                conjoint["num_sec_conjoint||N° sécurité sodiale||6"] || ""
              }
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={24}>
        <Col
          xs={24}
          sm={24}
          md={24}
          lg={12}
          xl={12}
          style={{ margin: "0.4rem 0" }}
        >
          <Form.Item label={"Code organisme"} required>
            <Input
              size="large"
              onChange={(e) => {
                handleChange(e, "change", "code_organisme_conjoint");
              }}
              // minLength={0}
              // maxLength={9}
              defaultValue={dataAdhesion["code_organisme_conjoint"]}
              suffix={
                <Tooltip
                  placement="top"
                  title={"Référez-vous à l'attestation de votre Carte Vitale"}
                >
                  <InfoCircleOutlined style={{ color: "rgba(0,0,0,.45)" }} />
                </Tooltip>
              }
            />
          </Form.Item>
        </Col>
        <Col
          xs={24}
          sm={24}
          md={24}
          lg={12}
          xl={12}
          style={{ margin: "0.4rem 0" }}
        >
          <Form.Item label="Régime" name="ro_conjoint||Régime||4" required>
            <Select
              size="large"
              defaultValue={customerInfoC.job.regime}
              onChange={(e) => handleChange(e)}
              name="ro_conjoint||Régime||4"
              options={regimeOptions}
              disabled
            />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={24} lg={12} xl={12}>
          <Form.Item label={"Etes-vous un jumeau de même sexe ?"} required>
            <Select
              size="large"
              options={jumeauOptions}
              onSelect={(e) =>
                handleChange(e, "select", "jumeau_meme_sexe_conjoint")
              }
              defaultValue={dataAdhesion["jumeau_meme_sexe_conjoint"]}
            />
          </Form.Item>
        </Col>
        {dataAdhesion["jumeau_meme_sexe_conjoint"] === "Oui" && (
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <Form.Item label={"Rang"} required>
              <Input
                size="large"
                onChange={(e) => {
                  handleChange(e, "change", "rang_jumeau_meme_sexe_conjoint");
                }}
                defaultValue={dataAdhesion["rang_jumeau_meme_sexe_conjoint"]}
                suffix={
                  <Tooltip
                    placement="top"
                    title={"Référez-vous à l'attestation de votre Carte Vitale"}
                  >
                    <InfoCircleOutlined style={{ color: "rgba(0,0,0,.45)" }} />
                  </Tooltip>
                }
              />
            </Form.Item>
          </Col>
        )}
      </Row>
    </Form>
  );
};

export default Conjoint;
