import React, { useState, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPhone,
  faShare,
  faFilePdf,
  faArrowLeft,
} from "@fortawesome/free-solid-svg-icons";
import headerImage from "../DevisTarificateur/Img-header.png";
import DevisTarificateurCard from "./DevisTarificateurCard/DevisTarificateurCard";
import PrincipalesGaranties from "./PrincipalesGaranties/PrincipalesGaranties";
import moment from "moment/moment";
import { useLocation, useNavigate } from "react-router-dom";

import {
  Accordion,
  AccordionBody,
  AccordionItem,
  Row,
  Col,
  Container,
  Table,
} from "reactstrap";
import { Button } from "antd";
import { useParams } from "react-router-dom";
import SimpleCrypto from "simple-crypto-js";
import classes from "./DevisTarificateur.module.css";
import SendEmail from "../SendEmail/SendEmail";
import GlobalContext from "../../../contexts/GlobalContext";
import SessionStorageService from "../../../utils/sessionStorageService";
import ImageBase from "../../../assets/ImageBase64/ImageBase64";
import { v4 as uuidv4 } from "uuid";
import axios from "../../../utils/axios";
import { checkSelectedGamme } from "../../../utils/utils";
const DevisTarificateur = () => {
  const params = useLocation();
  const { baseTarif, proposition, param_id, tarif } = params.state;
  const [SpiningMail, setSpiningMail] = useState(false);
  const printRef = useRef();
  const idReprise = uuidv4();
  const [open, setOpen] = useState("0");
  const {
    modal,
    setModal,
    entreprise,
    image,
    numero,
    fetchComparison,
    optionHorsSante,
    destination,
    changedProduct,
    repriseData,
    fraisCourtage,
    fraisLie,
    fraisAesio,
    sharedFurtherInformation,
    valueChecked
  } = React.useContext(GlobalContext);
  const customerRelations = SessionStorageService().getCustomerInfo();
  const product = JSON.parse(sessionStorage.getItem("product"));
  // const prospectData = SessionStorageService().getProductData();
  const { id } = useParams();
  const navigate = useNavigate();
  const simpleCrypto = new SimpleCrypto(
    import.meta.env.VITE_APP_ENCRYPTION_SECRET_KEY
  );
  const partnershipId = localStorage.getItem("api_key");
  const prospectInfo = SessionStorageService().getEmailProspect();
  //eslint-disable-next-line no-unused-vars
  const [numeroDevis, setNumeroDevis] = useState(
    sessionStorage.getItem("SessionURLID") || Date.now()
  );

  const { tarifs, tarifWithOption, products } = repriseData;
  let productsIds = [
    parseInt(changedProduct?.produit?.produit_sante.cotation_id).toString(),
  ];

  React.useEffect(() => {
    id && fetchComparison(id);
    // eslint-disable-next-line
  }, [id]);

  const togglemodal = (id) => {
    if (open === id) {
      setOpen();
    } else {
      setOpen(id);
    }
  };

  const toggle = () => {
    let id_op = sessionStorage.getItem("SessionURLID");
    sessionStorage.setItem("idAdhesion", `${id_op + numeroDevis}`);

    setModal(!modal);
  };
  const costumerData = (navigateTo) => {
    let customer_information = [];
    let costumers = JSON.parse(sessionStorage.getItem("customerInfo"));
    let assureurInfo = SessionStorageService().getCustomerInfo()[0];
    costumers.forEach((element) => {
      let custom = {
        number: element?.relationship_rank,
        primary_subscriber: element?.primary_subscriber,
        insured: true,
        beneficiary: false,
        birth_info: {
          date: element?.birth_info?.date,
        },

        address: {
          street_name: assureurInfo.address["fullAddress"],
          zip_code: assureurInfo.address["zip_code"],
          city: assureurInfo.address["locality"],
        },
        job: {
          csp_insee: "Toutes",
          regime: element?.job?.regime,
        },
        contract_information: {
          number: 476820,
          product: productsIds,
        },
        relationship: element?.relationship,
        relationship_rank: element?.relationship_rank,

        // identity: {
        //   // civility: "",
        //   use_name:
        //     element?.relationship_rank === 1
        //       ? SessionStorageService().getEmailProspect().name
        //       : "",
        //   first_name:
        //     element?.relationship_rank === 1
        //       ? SessionStorageService().getEmailProspect()?.surname
        //       : "",
        // },

        // social_security_number: "",

        // social_security_number_link: "",
        // phone: [
        //   {
        //     type: "Mobile",
        //     country_prefix: "",
        //     number: "",
        //   },
        // ],
      };

      if (element.relationship_rank === 1) {
        custom = {
          ...custom,
          email: destination,
          identity: {
            // civility: "",
            use_name:
              element?.relationship_rank === 1
                ? SessionStorageService().getEmailProspect().name
                : "",
            first_name:
              element?.relationship_rank === 1
                ? SessionStorageService().getEmailProspect()?.surname
                : "",
          },
        };
      }
      if (navigateTo === "email") {
        custom = {
          ...custom,
          mail: destination ? destination : "",
        };
      }
      customer_information.push(custom);
    });
    return customer_information;
  };

  const generatedDevis = async (navigateTo) => {
    setSpiningMail(!SpiningMail);
    let option = [];
    if (
      optionHorsSante.includes("Assistance") &&
      JSON.parse(import.meta.env.VITE_APP_AESIO_ID_GAMME).includes(
        sessionStorage.getItem("selectedGamme")
      )
    ) {
      option.push(import.meta.env.VITE_APP_ASSISTANCE_CONTRACT);
    }
    if (
      optionHorsSante.includes("Protection juridique") &&
      JSON.parse(import.meta.env.VITE_APP_AESIO_ID_GAMME).includes(
        sessionStorage.getItem("selectedGamme")
      )
    ) {
      option.push(import.meta.env.VITE_APP_PROTECTION_JURIDIQUE_CONTRACT);
    }

    const dateEffet = SessionStorageService().getProspect()?.dateEffet
      ? SessionStorageService().getProspect()?.dateEffet
      : moment().add(1, "days").format("DD-MM-YYYY");

    let dataDevis = {
      id_opp: SessionStorageService().getId(),
      document_produit: "acheel_sante",
      data_pricing: {
        option: option,
        third_app: {
          name: "ASolutions",
          id_external: 476820,
        },
        customer_information: costumerData(navigateTo),
        contract_information: {
          effective_date: moment(dateEffet, "DD-MM-YYYY").format("DD/MM/YYYY"),
          mode_splitting: "Mensuel",
        },
      },
      data_fields: {
        formule: SessionStorageService().getProspect()?.formule,
        email_client:destination ? destination : ""
      },
    };
    dataDevis = {
      ...dataDevis,
      data_pricing: {
        ...dataDevis.data_pricing,
        frais_courtage: fraisCourtage,
        frais_dossier_distributeur: fraisLie,
      },
    };

    const apiKey = localStorage.getItem("api_key");
    return axios
      .post(
        `${import.meta.env.VITE_APP_CLOUD_GEOPROD_APIURL}/devis/generate_devis`,
        dataDevis,
        {
          headers: {
            apiKey: apiKey,
          },
        }
      )
      .then((res) => {
        sessionStorage.setItem("quotation_id", res?.data?.quotation_id);
        if (navigateTo === "email") {
          axios
            .post(
              `${
                import.meta.env.VITE_APP_CLOUD_GEOPROD_APIURL
              }/session_url/set_object_from_session_url`,
              {
                id: idReprise,
                objet: {
                  data: {
                    sharedFurtherInformation: sharedFurtherInformation,
                    tarif: baseTarif,
                    tarifs: tarifs,
                    proposition: changedProduct ? changedProduct : proposition,
                    products: products,
                    tarifWithOption: tarifWithOption,
                    prospect: SessionStorageService().getProspect(),
                    customerInfo: SessionStorageService().getCustomerInfo(),
                    quotation_id: res?.data?.quotation_id,
                    _subs_tags: sessionStorage.getItem("_subs_tags"),
                    fraisDistribution: fraisLie,
                    fraisCourtageAccessoire: fraisCourtage,
                    nom_cie: sessionStorage.getItem("nom_cie"),
                    valueChecked:valueChecked
                  },
                  idOpp: sessionStorage.getItem("SessionURLID"),
                  creationDate: moment().format("DD/MM/YYYY"),
                },
              }
            )
            .then(() => {})
            .catch((e) => {
              console.log(e);
              setSpiningMail(false);
            });
        }
        if (navigateTo === "exporter") {
          setSpiningMail(false);
          window.open(res?.data?.devis, "_blank");
        }
        return res.data;
      })
      .catch((e) => {
        setSpiningMail(false);
        setModal(false);
        console.log(e);
      });
  };

  return (
    <>
      <div ref={printRef}>
        <Container
          fluid
          style={{
            paddingInline: "4rem",
            backgroundColor: "white",
          }}
        >
          <Row style={{ position: "relative" }}>
            <img
              className={classes.headerImage}
              src={headerImage}
              alt="headerImage"
            />
            {image && (
              <img
                className={classes.head}
                alt="logo"
                src={`data:image/png;base64,${image}`}
              />
            )}
            <h1 className={classes.headerTitle}>Votre devis</h1>
          </Row>
          <Row
            style={{
              margin: "3rem",
              justifyContent: "flex-end",
              display: "flex",
              alignItems: "center",
            }}
          >
            <Col lg={4}>
              <h2 style={{ textAlign: "center" }}>
                {entreprise?.nom_organisme_master}
              </h2>
            </Col>
            {numero && (
              <Col
                lg="4"
                xs="12"
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-end",
                }}
              >
                <FontAwesomeIcon icon={faPhone} className={classes.phoneIcon} />
                <h3 className={classes.phoneNumber}> {numero}</h3>
              </Col>
            )}
          </Row>
          <Row>
            <Col lg={4} className={classes.devisContainer}>
              <h2>Devis n° {numeroDevis}</h2>
            </Col>
          </Row>
          <Row>
            <Col xs="12">
              <h4>Date de génération : {moment().format("DD/MM/YYYY")}</h4>
              <h4>Offre valable pour 60 jours.</h4>
            </Col>
          </Row>

          <Row xs="12" className={classes.grayDiv}>
            <Row>
              <Col xs="12" style={{ padding: "1.5rem" }}>
                <p className={classes.devis}>
                  {" "}
                  Bonjour, {prospectInfo?.civilite} {prospectInfo?.name}{" "}
                  {prospectInfo?.surname} Habitant à{" "}
                  {
                    SessionStorageService()?.getCustomerInfo()[0]?.address
                      ?.country
                  }{" "}
                  {
                    SessionStorageService()?.getCustomerInfo()[0]?.address
                      ?.locality
                  }
                </p>
                <p style={{ fontSize: "1.5rem" }}>
                  Merci de nous avoir choisi pour l'élaboration de votre étude
                  personnalisée.
                  <br />
                  Suite à notre entretien, vous trouverez ci-dessous le
                  récapitulatif de la garantie que nous avons sélectionnée
                  ensemble, les tarifs ainsi que des liens pour les consulter.
                </p>
              </Col>{" "}
            </Row>
            <Row className={classes.anneeEffetContainer}>
              <Col xs="6">
                <h3 className={classes.Asolutions}>
                  {/* Mutuelle : {prospectData?.productData?.nom_cie}{" "} */}
                  {/* Mutuelle : {sessionStorage.getItem("nom_cie")} */}
                  Mutuelle : ACHEEL
                </h3>
              </Col>
              <Col
                xs="6"
                style={{ display: "flex", justifyContent: "flex-end" }}
              >
                <h3 className={classes.Asolutions}>
                  ANNÉE D'EFFET :{" "}
                  {moment(
                    JSON.parse(sessionStorage.getItem("userInfo"))?.dateEffet,
                    "DD-MM-YYYY"
                  ).format("YYYY")}
                </h3>
              </Col>
            </Row>
            <Row>
              <Col xs="12">
                <Accordion
                  open={open}
                  toggle={togglemodal}
                  className={classes.accordion}
                >
                  <AccordionItem className={classes.accordionItem}>
                    {/* <AccordionHeader targetId="1">
                      <p className={classes.accordionHeader}>
                        [+] Bénéficiaires
                      </p>
                    </AccordionHeader> */}
                    <AccordionBody accordionId="1">
                      <ul>
                        <Row className={classes.accordionBody}>
                          <Col>
                            <li
                              style={{
                                fontSize: "1.5rem",
                              }}
                            >
                              Assuré :
                            </li>
                          </Col>
                          <Col>
                            {" "}
                            <p className={classes.p}> 45.08 €</p>
                          </Col>
                        </Row>

                        <Row className={classes.accordionBody}>
                          <Col>
                            <li
                              style={{
                                fontSize: "1.5rem",
                              }}
                            >
                              Conjoint:
                            </li>
                          </Col>
                          <Col>
                            {" "}
                            <p className={classes.p}> 61.95 €</p>
                          </Col>
                        </Row>
                      </ul>
                    </AccordionBody>
                  </AccordionItem>
                </Accordion>
              </Col>
            </Row>

            <Row>
              <Col xs="12" lg="4">
                <DevisTarificateurCard
                  title="PRODUIT"
                  content={proposition?.produit?.produit_sante?.nom_produit}
                />
              </Col>
              <Col xs="12" lg="4">
                <DevisTarificateurCard
                  title="NIVEAU"
                  content={`${proposition?.produit?.produit_sante?.option}`}
                />
              </Col>
              <Col xs="12" lg="4">
                <DevisTarificateurCard
                  title="COTISATION"
                  content={`${tarif} €/mois *`}
                />
              </Col>
            </Row>
          </Row>
          {customerRelations.length >= 1 ? (
            <Row className={classes.table}>
              <Table bordered>
                <thead className={classes.tableheader}>
                  <tr>
                    <th>Bénéficier</th>
                    <th>Date de naissance</th>
                    <th>RO</th>
                  </tr>
                </thead>
                <tbody>
                  {customerRelations.map((val, index) => {
                    var type = "";
                    var ro = "";
                    switch (val.relationship) {
                      case "married":
                        type = "Conjoint";
                        break;
                      case "children":
                        type = "Enfant";
                        break;
                      case "primary subscriber":
                        type = "adhérent";
                        break;
                      default:
                        break;
                    }
                    switch (val.job.regime) {
                      case "Régime local":
                        ro = "Alsace Moselle";
                        break;
                      case "Régime général":
                        ro = "Sécurité Sociale";
                        break;
                      default:
                        ro = val.job.regime;
                        break;
                    }
                    return (
                      <tr
                        className={
                          val.relationship === "married"
                            ? "table-danger"
                            : "table-light"
                        }
                        key={index}
                      >
                        <td>{type}</td>
                        <td>{val.birth_info.date}</td>
                        <td>{ro}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </Row>
          ) : (
            <></>
          )}
          <div className={classes.noPrint}>
            <Row>
              <Col
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <a
                  href={import.meta.env.VITE_APP_CDN_CG_ACHEEL_URL}
                  target="_blank"
                  style={{
                    textDecoration: "none",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                  rel="noreferrer"
                >
                  <img
                    className={classes.pdfImage}
                    alt="pdf_icon"
                    src="https://mutuelle-direct.cloud.geoprod.com/css/imgs/icon-pdf.png"
                  />

                  <p style={{ fontSize: "1.5rem", color: "black" }}>
                    Conditions générales
                  </p>
                </a>
              </Col>
              <Col
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <a
                  href={
                    proposition?.tableau_garantie
                      ? proposition?.tableau_garantie[0]
                      : ""
                  }
                  target="_blank"
                  rel="noreferrer"
                  style={{
                    textDecoration: "none",
                    display: "flex",
                    flexDirection: "row",
                    color: "black",
                    alignItems: "center",
                  }}
                >
                  <img
                    className={classes.pdfImage}
                    alt="pdf_icon"
                    src="https://mutuelle-direct.cloud.geoprod.com/css/imgs/icon-pdf.png"
                  />

                  <p style={{ fontSize: "1.5rem" }}>Tableau des garanties </p>
                </a>
              </Col>
              <Col
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <a
                  href={
                    import.meta.env.VITE_APP_CDN_IPID_ACHEEL ||
                    `/404${window.location.search}`
                  }
                  target="_blank"
                  rel="noreferrer"
                  style={{
                    textDecoration: "none",
                    display: "flex",
                    flexDirection: "row",
                    color: "black",
                    alignItems: "center",
                  }}
                >
                  <img
                    className={classes.pdfImage}
                    alt="pdf_icon"
                    src="https://mutuelle-direct.cloud.geoprod.com/css/imgs/icon-pdf.png"
                  />

                  <p style={{ fontSize: "1.5rem" }}>Fiches IPID </p>
                </a>
              </Col>
            </Row>
          </div>

          {/*  <Row className={classes.grayDiv}>
            <p className={classes.principalesGaranties}>
              Vos Principales Garanties<span style={{ color: "red" }}>**</span>
            </p>
            <Col xs="12" lg="4">
              <PrincipalesGaranties
                title=" HOSPITALISATION"
                icon="https://mutuelle-direct.cloud.geoprod.com/images/garanties/Hospitalisation_2.png"
                data={[
                  {
                    key: "Honoraires",
                    value: `${proposition.garanties.find((item)=>item.name==="ex22").valeur_affiche} ${proposition.garanties.find((item)=>item.name==="ex22").type}`,
                  },
                  {
                    key: "Chambre particulière",
                    value: `${proposition.garanties.find((item)=>item.name==="ex23").valeur_affiche} ${proposition.garanties.find((item)=>item.name==="ex23").type}`,
                  },
                ]}
              />
            </Col>
           
            <Col xs="12" lg="4">
              <PrincipalesGaranties
                title=" OPTIQUE"
                icon="https://mutuelle-direct.cloud.geoprod.com/images/garanties/Optique_2.png"
                data={[
                  {
                    key: "Monture + Verres simples",
                    value: `${proposition.garanties.find((item)=>item.name==="ex41").valeur_affiche} ${proposition.garanties.find((item)=>item.name==="ex41").type}`,
                  },
                  {
                    key: "Monture + Verres complexes",
                    value: `${proposition.garanties.find((item)=>item.name==="pb").valeur_affiche} ${proposition.garanties.find((item)=>item.name==="pb").type}`,
                  },
                ]}
              />
            </Col>
            <Col xs="12" lg="4">
              <PrincipalesGaranties
                title=" SOINS COURANTS"
                icon="https://mutuelle-direct.cloud.geoprod.com/images/garanties/Soins courants_2.png"
                data={[
                  {
                    key: "Consultations",
                    value: `${proposition.garanties.find((item)=>item.name==="pbo").valeur_affiche} ${proposition.garanties.find((item)=>item.name==="pbo").type}`,
                  },
                  {
                    key: "Labo & Auxiliaires",
                    value: `${proposition.garanties.find((item)=>item.name==="pbob").valeur_affiche} ${proposition.garanties.find((item)=>item.name==="ex44").type}`,
                  },
                  {
                    key: `${proposition.garanties.find((item)=>item.name==="pnr").valeur_affiche} ${proposition.garanties.find((item)=>item.name==="pnr").type}`,
                    value: "-",
                  },
                  {
                    key: "Radiologie",
                    value: `${proposition.garanties.find((item)=>item.name==="conforme").valeur_affiche} ${proposition.garanties.find((item)=>item.name==="conforme").type}`,
                  },
                ]}
              />
            </Col>
            <Col xs="12" lg="4">
              <PrincipalesGaranties
                title="PHARMACIE"
                icon="https://mutuelle-direct.cloud.geoprod.com/images/garanties/Pharmacie_2.png"
                data={[
                  {
                    key: "Médicaments - Rendu important",
                    value: `${proposition.garanties.find((item)=>item.name==="pb").valeur_affiche} ${proposition.garanties.find((item)=>item.name==="pb").type}`,
                  },
                  {
                    key: "Médicaments - Rendu modéré",
                    value: `${proposition.garanties.find((item)=>item.name==="pbo").valeur_affiche} ${proposition.garanties.find((item)=>item.name==="pbo").type}`,
                  },
                  {
                    key: "Médicaments - Rendu faible",
                    value: `${proposition.garanties.find((item)=>item.name==="pbob").valeur_affiche} ${proposition.garanties.find((item)=>item.name==="pbob").type}`,
                  },
                  {
                    key: "Pharmacie non remboursée",
                    value: `${proposition.garanties.find((item)=>item.name==="pnr").valeur_affiche} ${proposition.garanties.find((item)=>item.name==="pnr").type}`,
                  },
                ]}
              />
            </Col>

            <Row>
              <Col xs="12" className={classes.etoile}>
                <p className={classes.etoile_p}>(*) </p>{" "}
                <h6 className={classes.tarif_h6}>
                  Tarif valable 60 jours, hors offre promotionnelle
                </h6>
              </Col>
            </Row> */}
          <Row className={classes.grayDiv}>
            <p className={classes.principalesGaranties}>
              Vos garanties principales
            </p>
            <Col xs="12" lg="4">
              <PrincipalesGaranties
                title=" HOSPITALISATION"
                icon={ImageBase[1].Hospitalisation}
                data={[
                  {
                    key: "Honoraires",
                    value: `${
                      proposition?.garanties?.find(
                        (item) => item?.name === "ex22"
                      )?.valeur_affiche
                    }`,
                  },
                  {
                    key: "Chambre particulière",
                    value: `${
                      proposition?.garanties?.find(
                        (item) => item?.name === "ex23"
                      )?.valeur_affiche
                    }`,
                  },
                ]}
              />
            </Col>
            <Col xs="12" lg="4">
              <PrincipalesGaranties
                title="DENTAIRE"
                icon={ImageBase[2]?.dentaire}
                data={[
                  {
                    key: "Soins",
                    value: `${
                      proposition?.garanties?.find(
                        (item) => item?.name === "ex41"
                      )?.valeur_affiche
                    }`,
                  },
                  {
                    key: "Prothèse",
                    value: `${
                      proposition?.garanties?.find(
                        (item) => item?.name === "ex42"
                      )?.valeur_affiche
                    }`,
                  },
                  {
                    key: "Orthodontie",
                    value: `${
                      proposition?.garanties?.find(
                        (item) => item?.name === "ex44"
                      )?.valeur_affiche
                    }`,
                  },
                ]}
              />
            </Col>
            <Col xs="12" lg="4">
              <PrincipalesGaranties
                title=" OPTIQUE"
                icon={ImageBase[3]?.optique}
                data={[
                  {
                    key: "Monture + Verres simples",
                    value: `${
                      proposition?.garanties?.find(
                        (item) => item?.name === "ex31"
                      )?.valeur_affiche
                    }`,
                  },
                  {
                    key: "Monture + Verres complexes",
                    value: `${
                      proposition?.garanties?.find(
                        (item) => item?.name === "ex32"
                      )?.valeur_affiche
                    }`,
                  },
                  {
                    key: "Lentille remboursé par la ss",
                    value: `${
                      proposition.garanties?.find(
                        (item) => item?.name === "ex33"
                      ).valeur_affiche
                    }`,
                  },
                  {
                    key: "Chirurgie refractive",
                    value: `${
                      proposition?.garanties?.find(
                        (item) => item?.name === "ex34"
                      )?.valeur_affiche
                    }`,
                  },
                ]}
              />
            </Col>
            <Col xs="12" lg="4">
              <PrincipalesGaranties
                title=" SOINS COURANTS"
                icon={ImageBase[4]?.soins_courants}
                data={[
                  {
                    key: "Consultations",
                    value: `${
                      proposition?.garanties?.find(
                        (item) => item?.name === "ex11"
                      )?.valeur_affiche
                    }`,
                  },
                  {
                    key: "Labo ",
                    value: `${
                      proposition?.garanties?.find(
                        (item) => item?.name === "ex17"
                      )?.valeur_affiche
                    }`,
                  },
                  {
                    key: "Auxiliaires medicaux ",
                    value: `${
                      proposition?.garanties?.find(
                        (item) => item?.name === "ex12"
                      )?.valeur_affiche
                    }`,
                  },
                  {
                    key: "Médecine alternative",
                    value: `${
                      proposition?.garanties?.find(
                        (item) => item?.name === "ex16"
                      )?.valeur_affiche
                    }`,
                  },
                  {
                    key: "Radiologie",
                    value: `${
                      proposition?.garanties?.find(
                        (item) => item?.name === "ex19"
                      )?.valeur_affiche
                    }`,
                  },
                ]}
              />
            </Col>
            <Col xs="12" lg="4">
              <PrincipalesGaranties
                title="PHARMACIE"
                icon={ImageBase[5]?.pharmacie}
                data={[
                  {
                    key: "Médicaments-important",
                    value: `${
                      proposition?.garanties?.find(
                        (item) => item?.name === "pb"
                      )?.valeur_affiche
                    }`,
                  },
                  {
                    key: "Médicaments - Rendu modéré",
                    value: `${
                      proposition?.garanties?.find(
                        (item) => item?.name === "pbo"
                      )?.valeur_affiche
                    }`,
                  },
                  {
                    key: "Médicaments - Rendu faible",
                    value: `${
                      proposition?.garanties?.find(
                        (item) => item?.name === "pbob"
                      )?.valeur_affiche
                    } `,
                  },
                  {
                    key: "Pharmacie non remboursée",
                    value: `${
                      proposition?.garanties?.find(
                        (item) => item?.name === "pnr"
                      )?.valeur_affiche
                    } `,
                  },
                ]}
              />
            </Col>
            <Col xs="12" lg="4">
              <PrincipalesGaranties
                title="Audio Prothèse"
                icon={ImageBase[6]?.oreille}
                data={[
                  {
                    key: "  Audio Prothèse",
                    value: `${
                      proposition?.garanties?.find(
                        (item) => item?.name === "or1"
                      )?.valeur_affiche
                    }`,
                  },
                ]}
              />
            </Col>

            <Row>
              <Col xs="12" className={classes.etoile}>
                <p className={classes.etoile_p}>(*) </p>{" "}
                <h6 className={classes.tarif_h6}>
                  Tarif valable 60 jours, hors offre promotionnelle
                </h6>
              </Col>
            </Row>

            <Row>
              <Col className={classes.etoile}>
                <p className={classes.doubleEtoile_p}>(**) </p>
                <h6 className={classes.doubleEtoile_h6}>
                  {" "}
                  Pour l’exhaustivité du tableau de garanties, voir les liens
                  ci-dessus tableau de garanties et notice
                </h6>
              </Col>
            </Row>
          </Row>

          <Row>
            <Col>
              <h5 className={classes.h5}>
                Je reste à votre entière disposition pour tout complément
                d'informations.
              </h5>
              <h5 className={classes.h5}>
                Je vous remercie de la confiance que vous nous témoignez, en
                espérant avoir répondu à vos attentes.
              </h5>
              <h5 className={classes.cordialement_h5}>Cordialement.</h5>
            </Col>
          </Row>
        </Container>
      </div>
      <Row style={{ padding: "2rem" }}>
        <Col lg="6">
          <Button
            onClick={() =>
              navigate(
                `/tarificateur?partnership_id=${partnershipId}&ref=${param_id}`
              )
            }
            className={classes.buttons}
            size="large"
            type="primary"
            style={{
              backgroundColor: "#8c8c8c",
              display: "flex",
              alignItems: "center",
            }}
          >
            <FontAwesomeIcon
              style={{ marginRight: "0.5rem" }}
              icon={faArrowLeft}
            />
            Précédent
          </Button>
        </Col>

        <Col xs="12" lg="6" className={classes.buttons}>
          <Button
            size="large"
            type="primary"
            style={{ backgroundColor: "#8c8c8c" }}
            onClick={() => {
              generatedDevis("exporter");
            }}
            className={classes.pdfButton}
          >
            <FontAwesomeIcon icon={faFilePdf} className={classes.pdfIcon} />
            ExporterPdf
          </Button>

          <Button
            size="large"
            type="primary"
            style={{ borderRadius: "0.7rem", backgroundColor: "#8c8c8c" }}
            onClick={toggle}
          >
            <FontAwesomeIcon icon={faShare} className={classes.shareIcon} />
            Envoyer Devis
          </Button>
        </Col>
      </Row>
      <SendEmail
        toggle={toggle}
        modal={modal}
        setModal={setModal}
        idReprise={idReprise}
        setSpiningMail={setSpiningMail}
        generatedDevis={generatedDevis}
        SpiningMail={SpiningMail}
        type="tarificateur"
        tableau_garantie={
          proposition?.tableau_garantie ? proposition?.tableau_garantie[0] : ""
        }
      />
      {/* <Backdrop
          sx={{
            color: '#fff',
            zIndex: (theme) => theme.zIndex.drawer + 1,
          }}
          open={SpiningMail}
        >
          <div className={classes.loaderContainer}>
            <Box sx={{ width: '100%' }}>
              <CircularProgress style={{ width: '4rem', height: '4rem' }} />
            </Box>
            <h2>Veuillez patienter un instant.</h2>
          </div>
        </Backdrop> */}
    </>
  );
};

export default DevisTarificateur;
