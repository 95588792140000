import React, { useEffect } from "react";
import classes from "./PreviewEmail.module.css";
import GlobalContext from "../../../../contexts/GlobalContext";
import SessionStorageService from "../../../../utils/sessionStorageService";
function PreviewEmail(props) {
  const { type } = props;
  const { templateDevis, editorMessage,repriseData } = React.useContext(GlobalContext);
  const [previewTemplate, setPreviewTemplate] = React.useState("");
  const {
    proposition,
  } = repriseData;

  useEffect(() => {
    const prospect = SessionStorageService().getEmailProspect();
    let temp = templateDevis.replace(
      "*|FNAME|*",
      prospect?.surname ? prospect?.surname : ""
    );
    temp = temp.replace("*|LNAME|*", prospect?.name ? prospect?.name : "");
    // temp = temp.replace("[NOM OFFRE]", type!=="comparatif"? (prospect?.formule || 1):"");
    // temp=temp.replace("[NOM PRODUIT]", type!=="comparatif"?(sessionStorage.getItem("nom_cie")+" - ALL SANTE" ):"")
    temp = temp.replace("[NOM OFFRE]", type === "tarificateur"? proposition?.produit?.produit_sante?.option:"");
    // temp=temp.replace("[NOM PRODUIT]", type === "tarificateur"?(`${sessionStorage.getItem("nom_cie")} - ${proposition?.produit?.produit_sante?.nom_produit}`):"")
    temp=temp.replace("[NOM PRODUIT]", type === "tarificateur"?(`ACHEEL - ${proposition?.produit?.produit_sante?.nom_produit}`):"")
    setPreviewTemplate(temp);
    // eslint-disable-next-line
  }, [editorMessage]);

  return (
    <div style={{ minHeight: "100%" }}>
      <h6> Visuel:</h6>
      <div className={classes.contain}>
        <div dangerouslySetInnerHTML={{ __html: previewTemplate }} />
        {/* <Row className={classes.header}>
          <Col>
            <h2 className={classes.title}>Devis</h2>
            {image && (
              <img
                className={classes.head}
                alt='logo'
                src={`data:image/png;base64,${image}`}
              />
            )}
          </Col>
        </Row>

        <Row className={classes.rowemail}>
          <Col>
            <p dangerouslySetInnerHTML={{ __html: message }}></p>
          </Col>
        </Row>
        <Row className={classes.rowbtn}>
          <Col className={classes.btn}>
            <Button
              size='lg'
              block
              style={{ backgroundColor: 'red' }}
              onClick={RedirectToDevis}
            >
              <img
                src={icon}
                alt='icon'
                style={{
                  height: '1rem',
                  width: '1.5rem',
                  paddingRight: '0.5rem',
                  marginBottom: '0.22rem',
                }}
              />
              DEVIS
            </Button>
          </Col>
        </Row>
        <Row className={classes.rowtext}>
          <Col className={classes.text}>
            <p>
              Je reste à votre entière disposition pour tout complément
              d'informations. Je vous remercie de la confiance que vous nous
              témoignez, en espérant avoir répondu à vos attentes. Cordialement,
            </p>
          </Col>
        </Row> */}
      </div>
    </div>
  );
}

export default PreviewEmail;
/*<table border="0" cellpadding="0" cellspacing="0" style="background-color:#e2e2e2; font-weight: normal !important;" width="100%;">
    <tbody>
        <tr>
            <td align="center" bgcolor="#e0e0e0">
                <table border="0" cellpadding="0" cellspacing="0">
                    <tbody>
                        <tr>
                            <td class="w640" height="10" width="640">&nbsp;</td>
                        </tr> 
                        <tr>
                            <td class="w640" height="10" width="640">&nbsp;</td>
                        </tr>
                        <!-- entete -->
                        <tr class="pagetoplogo">
                            <td class="w640" width="640" style="background-color:#283061;height:60px;">
                                <div style="padding:20px; text-align:center;color:#ffffff;font-family: 'Chivo', sans-serif;font-size: 25px"> Devis</div>
                            </td>
                        </tr>
                        <tr class="content">
                            <td bgcolor="#ffffff" class="w640" width="640">
                                <table border="0" cellpadding="0" cellspacing="0" class="w640" width="640">
                                    <Col>
            <p dangerouslySetInnerHTML={{ __html: message }}></p>
          </Col>
                                </table>
                                <table border="0" cellpadding="0" cellspacing="0" class="w640" width="640" style="margin-bottom: 40px; font-weight:normal;">
                                    <tbody>
                                        <tr>
                                            <td bgcolor="" class="w100" width="150">&nbsp;</td>
                                            <td bgcolor="" class="w580" width="340">
                                                <div id="link_det" align="center" style="padding-right: 40px;padding-left: 40px;padding: 15px;color: #ffffff;text-decoration: none;text-transform: uppercase;background-color: #e8291b;border-radius:6px;font-family: 'Chivo', sans-serif;"><a href="https://mutuelle-direct.cloud.geoprod.com/devis_comparatif.php?api_key=62eebdb2c2ebf88e6716f6a63d178c7ff569b6033f820eae97ab08f07732fe21&amp;id_opp=5e344791c47f1dcc59c5b6ba024c04f1&amp;list_id_prod=-1_1%2C8207_46.6%2C8208_68.61&amp;nom=TRABELSI&amp;prenom=Ahmed&amp;annee_naissance_assure=1997&amp;departement=83&amp;ro_assure=2&amp;situation_assure=&amp;ro_conjoint=&amp;annee_naissance_conjoint=&amp;situation_conjoint=&amp;age=&amp;ro_enfant_1=&amp;annee_naissance_enfant_1=&amp;situation_enfant_1=&amp;age=&amp;ro_enfant_2=&amp;annee_naissance_enfant_2=&amp;situation_enfant_2=&amp;age=&amp;ro_enfant_3=&amp;annee_naissance_enfant_3=&amp;situation_enfant_3=&amp;age=&amp;ro_enfant_4=&amp;annee_naissance_enfant_4=&amp;situation_enfant_4=&amp;age=&amp;specification_asolution=&amp;specification_politique=&amp;mesure_garantie=&amp;declare_garantie=&amp;complementairesante_garantie=&amp;mt_garantie=&amp;beneficie_garantie=&amp;contrat_garantie=&amp;refbudget_garantie=&amp;budegtmensuel_garantie=&amp;annee_effet=2022&amp;step3_mise_avant=meilleurprix_garantie&amp;budget2=0%2C800&amp;option_assante_assistance=Oui&amp;option_assante_pj=Oui&amp;budget=0%2C800" style="color: #ffffff;text-decoration: none;text-transform: uppercase;letter-spacing: 4px; display: inline-block">DEVIS COMPARATIF PERSONNALISÉ</a></div>
                                            </td>
                                            <td bgcolor="" class="w100" width="150">&nbsp;</td>
                                        </tr>
                                    </tbody>
                                </table>
                                <table border="0" cellpadding="0" cellspacing="0" class="w640" width="640">
                                    <tbody>

                                        <tr> 
                                            <td colspan="3" width="640" style="height:100px;background-color:#ffffff;">
                                                <div style="padding:35px; padding-top: 0px; color:#515151;font-family: 'Chivo', sans-serif;">
                                                      <p style="text-align: justify;line-height: 35px;" id="conforme_sante"></p>    
                                                        <p style="text-align: justify;line-height: 35px;">
                                                        Je reste à votre entière disposition pour tout complément d'informations.<br>
                                                        Je vous remercie de la confiance que vous nous témoignez, en espérant avoir répondu à vos attentes.<br>Cordialement,
                                                    </p>
                                                                                                                                        </div>
                                            </td>	
                                        </tr>
                                    </tbody>
                                </table>

                                <table border="0" cellpadding="0" cellspacing="0" class="w640" width="640">
                                    <tbody>
                                        <tr>
                                            <td colspan="3" width="640" style="height:20px;background-color:#e2e2e2;"></td> 
                                        </tr>
                                    </tbody>
                                </table>
                                <table class="pagebottom" border="0" cellpadding="0" cellspacing="0" width="640">
                                    <tbody>
                                        <tr> 
                                            <td bgcolor="" class="w340" width="340">
                                                <div style="padding:15px;color:#515151;font-family: 'Chivo', sans-serif;" id="signature_collab"></div>
                                            </td>  
                                            <td bgcolor="#283061" class="w300" width="300" style="height:100%;-webkit-clip-path: polygon(50% 0%, 0% 0%, 0% 0%);
                                                clip-path: polygon(50% -89%, 0% 100%, 160% 100%);">  
                                                <div style="width:90%;float: right;text-align: right;padding-right: 35px;margin-top: 19%;">
                                                   <img src="https://sdn.geoprod.com/static/uploads/organismes/logo/logo-header-geoprod.png" alt="nom" style="max-width:150px; max-height:80px;">                                                                                        </div>
                                            </td>
                                        </tr> 
                                    </tbody>
                                </table>
                            </td>
                        </tr> 
                        <tr>
                            <td class="w640" height="10" width="640">&nbsp;</td>
                        </tr>
                    </tbody>
                </table>
            </td>
        </tr>
    </tbody>
</table> */
