import React from "react";
import classes from "./Tarificateur.module.css";
import { Col, Form, Row, Select, notification } from "antd";
import { Button } from "antd";
import GarantiesModal from "./GarantiesModal/GarantiesModal";
import UserInfo from "./Userinfo/Userinfo";
import GroupMember from "../Tarificateur/GroupMember/GroupMember";
import TotalCotisation from "../Tarificateur/TotalCotisation/TotalCotisation";
import { useNavigate } from "react-router-dom";
import SessionStorageService from "../../utils/sessionStorageService";
import axios from "../../utils/axios";
import GlobalContext from "../../contexts/GlobalContext";
import moment from "moment/moment";
import GarantiesOptionnels from "./Garantiesoptionnels/Garantiesoptionnels";
import { Backdrop, Box, CircularProgress } from "@mui/material";
import { alertMessage } from "../../utils/alertFunction";
import FraisTarificateur from "../../components/FraisTarificateur/FraisTarificateur";
import { ErrorBoundary } from "@sentry/react";
import { checkSelectedGamme } from "../../utils/utils";

const Tarificateur = () => {
  const {
    optionHorsSante,
    tarifFinal,
    setTarifFinal,
    // eslint-disable-next-line no-unused-vars
    setCots,
    // eslint-disable-next-line no-unused-vars
    seChangedProduct,
    changedProduct,
    setTarifLoader,
    repriseData,
    fraisLie,
    fraisCourtage,
    setMalo,
    setFraisAssociatif,
    setFraisPj,
    setFraisMutuaide,
    setFraisQuittancement,
    setAlturgence,
    calculated,
    setCalculated,
  } = React.useContext(GlobalContext);
  const [openbackdrop, setOpenbackdrop] = React.useState(false);
  const [calculating, setCalculating] = React.useState(true);
  const frais_complementaire_options = [
    { label: "0 €", value: 0 },
    { label: "10 €", value: 10 },
    { label: "20 €", value: 20 },
    { label: "30 €", value: 30 },
    { label: "40 €", value: 40 },
    { label: "50 €", value: 50 },
  ];
  //const ap = JSON.parse(sessionStorage.getItem("ap"));
  // const params = useLocation();
  const options_frais_courtage = [
    { label: "0 €", value: 0 },
    { label: "2 €", value: 2 },
    { label: "4 €", value: 4 },
    { label: "7 €", value: 7 },
    { label: "10 €", value: 10 },
  ];
  const {
    tarif,
    tarifs,
    proposition,
    garantiesFilterValues,
    prospect,
    customerInfo,
  } = repriseData;

  const [baseTarif, setBaseTarif] = React.useState(tarif);
  const [firstEcheance, setFirstEcheance] = React.useState(tarif);
  const containerDiv = React.createRef(null);
  const navigate = useNavigate();
  const param_id = new URLSearchParams(window.location.search).get("ref");
  //eslint-disable-next-line
  const [modal, setModal] = React.useState(false);
  const dateEffet = SessionStorageService().getProspect()?.dateEffet
    ? SessionStorageService().getProspect()?.dateEffet
    : moment().add(1, "days").format("DD-MM-YYYY");

  const toggle = () => {
    setModal(!modal);
  };

  const isValidName = (name) => {
    const regexName = new RegExp(/^[A-Za-zÀ-ÖØ-öø-ÿ]+(?:[’'-][A-Za-zÀ-ÖØ-öø-ÿ]+)*$/);
    
    return regexName.test(name.replace(/ /g, ""));
  };

  const createOpp = (navigateTo) => {
    let customerInformation = SessionStorageService().getCustomerInfo();
    let tempProspect = SessionStorageService().getProspect();
    let info_specifiques_campagnes = { budget: "" };
    garantiesFilterValues?.forEach((element, index) => {
      info_specifiques_campagnes[element.name] = element.value;
    });

    let info_specifiques_ligne_produit = {
      Ro:
        customerInformation.find(
          (el) => el.relationship === "primary subscriber"
        )?.job?.regime || null,
      Ro_conjoint:
        customerInformation.find((el) => el.relationship === "married")?.job
          .regime || null,
      annee_naissance:
        moment(
          customerInformation.find(
            (el) => el.relationship === "primary subscriber"
          )?.birth_info?.date,
          "DD/MM/YYYY"
        ).format("YYYY-MM-DD") || null,
      annee_naissance_conjoint:
        customerInformation.find((el) => el.relationship === "married")
          ?.birth_info?.date || null,
      annee_naissance_enfant1:
        customerInformation.find(
          (el) => el.relationship === "children" && el.relationship_rank === 11
        )?.birth_info?.date || null,
      annee_naissance_enfant2:
        customerInformation.find(
          (el) => el.relationship === "children" && el.relationship_rank === 12
        )?.birth_info?.date || null,
      annee_naissance_enfant3:
        customerInformation.find(
          (el) => el.relationship === "children" && el.relationship_rank === 13
        )?.birth_info?.date || null,
      annee_naissance_enfant4:
        customerInformation.find(
          (el) => el.relationship === "children" && el.relationship_rank === 14
        )?.birth_info?.date || null,
      annee_naissance_enfant5:
        customerInformation.find(
          (el) => el.relationship === "children" && el.relationship_rank === 15
        )?.birth_info?.date || null,
      annee_naissance_enfant6:
        customerInformation.find(
          (el) => el.relationship === "children" && el.relationship_rank === 16
        )?.birth_info?.date || null,
      ro_enfant1:
        customerInformation.find(
          (el) => el.relationship === "children" && el.relationship_rank === 11
        )?.job?.regime || null,
      ro_enfant2:
        customerInformation.find(
          (el) => el.relationship === "children" && el.relationship_rank === 12
        )?.job?.regime || null,
      ro_enfant3:
        customerInformation.find(
          (el) => el.relationship === "children" && el.relationship_rank === 13
        )?.job?.regime || null,
      ro_enfant4:
        customerInformation.find(
          (el) => el.relationship === "children" && el.relationship_rank === 14
        )?.job?.regime || null,
      ro_enfant5:
        customerInformation.find(
          (el) => el.relationship === "children" && el.relationship_rank === 15
        )?.job?.regime || null,
      ro_enfant6:
        customerInformation.find(
          (el) => el.relationship === "children" && el.relationship_rank === 16
        )?.job.regime || null,
      situation_pro: tempProspect?.situation,
      NumeroSecuriteSocial: null,
      NumeroSecuriteSocialConjoint: null,
      NumeroSecuriteSocialEnfant1: null,
      NumeroSecuriteSocialEnfant2: null,
      NumeroSecuriteSocialEnfant3: null,
      NumeroSecuriteSocialEnfant4: null,
      NumeroSecuriteSocialEnfant5: null,
      eSignRef: null,
      YOUSIGN_URL: "",
    };
    var tab = [];
    if (customerInformation.length > 1) {
      customerInformation
        .filter((element) => element.relationship_rank >= 2)
        .forEach((element) => {
          tab = [
            ...tab,
            {
              name: "inconnu",
              surname: "inconnu",
              dn: moment(element?.birth_info.date, "DD/MM/YYYY").format(
                "YYYY-MM-DD"
              ),
              id: "",
              id_opp: "",
              subs_tag:
                element?.relationship_rank === 2
                  ? "Conjoint"
                  : "Enfant" + (element?.relationship_rank - 10),
              campagne_id: "",
              post: "",
              interlocuteur: "",
              ville:
                SessionStorageService().getCustomerInfo()[0]?.address?.locality,
              // situation: element?.relationship === "married" ? "married" : "",
              situation: element?.job?.situation_pro,
              users_nom: "",
              users_prenom: "",
              adresse_mail: "",
              civilite: "",
              prospects_civ: "",

              prospects_situation: "",
              nb_enfants: "",
              adresses_adresse: "",
              streetNumber: "",
              CP: element?.address?.zip_code,
              streetName: "",
              adresses_ville: "",
              complement_adr: "",
              tel: "",
              tel2: "",
              mobile: "",
              first_campagne: "",
              description: "",
              etat: "",
              lat: "",
              latitude: "",
              longitude: "",
              commercieux_id: "",
              commentaire: "",
              geom: "",
              tags: [],
              lng: "",
              date_naiss: moment(element?.birth_info.date, "DD/MM/YYYY").format(
                "YYYY-MM-DD"
              ),
              DN: moment(element?.birth_info.date, "DD/MM/YYYY").format(
                "YYYY-MM-DD"
              ),
              collab: "",
              id_pros: "",
              id_camp: "",
              coment_plus: "",
              code: "",
              rue: "",
              numero: "",
              complement: "",
              mail: "",
              nb_enfant: "",
              infcomp: "",
              relation:
                element.relationship === "married" ? "Conjoint" : "Descendant",
              form: {
                data: {
                  rang: element?.relationship_rank,
                  sec:
                    element?.social_security_number +
                    element?.social_security_number_link,
                  ro: element?.job?.regime,
                },
              },
              pays: "",
              invalidForms: [],
              deleted: false,
              information_bancaire: {
                id: "",
                titulaire_compte: "",
                iban: "",
                bic_swift: "",
                type_iban: "",
                nom_banque: "",
              },
              prospects_id: "",
            },
          ];
        });
    }

    let offre = {
      info_specifiques_campagnes: info_specifiques_campagnes,
      info_specifiques_ligne_produit: info_specifiques_ligne_produit,
      prospects: {
        listProspects: tab,
        mainProspect: {
          interlocuteur: null,
          tags: [],
          invalidForms: [],
          id: null,
          prospects_id: null,
          post: null,
          ville: customerInformation[0]?.address?.locality,
          situation: SessionStorageService().getProspect()?.situation,
          adresse_mail: null,
          civilite: null,
          name: tempProspect?.name,
          surname: tempProspect?.surname,
          DN: moment(
            SessionStorageService().getProspect()?.DN,
            "DD/MM/YYYY"
          ).format("YYYY-MM-DD"),
          nb_enfants:
            customerInformation[1]?.relationship === "married"
              ? customerInformation.length - 2
              : customerInformation.length - 1,
          form: {
            data: {
              rang: customerInformation[0]?.relationship_rank,
              sec:
                customerInformation[0]?.social_security_number +
                customerInformation[0]?.social_security_number_link,
              ro: customerInformation[0]?.job?.regime,
            },
          },
          streetNumber: null,
          longitude: null,
          latitude: null,
          CP: customerInformation[0]?.address?.zip_code,
          streetName: null,
          complement_adr: null,
          tel: null,
          tel2: null,
          mobile: null,
          description: null,
          relation: "principal",
          subs_tag: "Prospect",
        },
      },
    };

    sessionStorage.setItem("addOffre", JSON.stringify(offre));
    axios
      .post(
        `${import.meta.env.VITE_APP_CLOUD_GEOPROD_APIURL}/offre/add`,
        offre,
        {
          headers: {
            idSession: localStorage.getItem("token"),
          },
        }
      )
      .then((res) => {
        SessionStorageService().setId(res.data.id_opportunite_md5);
        SessionStorageService().setIdProspect(res.data.id_prospect_md5);
        sessionStorage.setItem(
          "_subs_tags",
          JSON.stringify(res.data?._subs_tags)
        );

        if (navigateTo === "adhesion")
          navigate("/adhesion", {
            state: {
              proposition: changedProduct ? changedProduct : proposition,
              optionHorsSante,
            },
          });
        if (navigateTo === "devis") {
          setOpenbackdrop(false);
          navigate("/devisTarificateur", {
            state: {
              proposition: changedProduct ? changedProduct : proposition,
              baseTarif: baseTarif,
              param_id: param_id,
              tarif: tarifFinal,
            },
          });
        }
      })
      .catch((err) => {
        setOpenbackdrop(false);
        console.log(err);
      });
  };

  const getNewTarif = (gamme, option) => {
    if (!calculated) {
      setCalculating(true);
      axios
        .post(
          `${
            import.meta.env.VITE_APP_CLOUD_GEOPROD_APIURL
          }/prod_garanties/get_produit_details_v2`,
          {
            game: gamme,
            option: option,
          },
          {
            headers: {
              idSession: localStorage.getItem("token"),
            },
          }
        )
        .then((res) => {
          seChangedProduct(res.data.produit[0]);
          sessionStorage.setItem(
            "product",
            JSON.stringify(res.data.produit[0])
          );
          let modify_customer_information = [];
          let customer_info = SessionStorageService().getCustomerInfo();
          customer_info.map((val, idx) =>
            modify_customer_information.push({
              ...val,
              contract_information: {
                ...val.contract_information,
                product: [
                  parseInt(
                    res.data?.produit[0]?.produit?.produit_sante.cotation_id
                  ),
                ],
              },
            })
          );
          let contract_information = {
            effective_date: moment(dateEffet, "DD-MM-YYYY").format(
              "DD/MM/YYYY"
            ),
            mode_splitting: "Mensuel",
            apply_reduction: true,
            prorata: true,
          };

          contract_information = {
            ...contract_information,
            has_modular_collective_contract: false,
            contribution_selected: [
              {
                name: "Frais de dossier distributeur",
                value: fraisLie,
                tax_set: [],
              },
              {
                name: "FCA",
                value: fraisCourtage,
                tax_set: [],
              },
            ],
          };
          axios
            .post(
              `${
                import.meta.env.VITE_APP_CLOUD_GEOPROD_APIURL
              }/pricing/get_pricing`,
              {
                business_introducer: {
                  login: "Z04",
                },
                third_app: {
                  name: "ASolutions",
                  id_external: 476820,
                },
                customer_information: modify_customer_information,
                contract_information: contract_information,
                products: {
                  0: Array(
                    SessionStorageService().getCustomerInfo().length
                  ).fill([
                    parseInt(
                      res.data?.produit[0]?.produit?.produit_sante.cotation_id
                    ),
                  ]),
                },
              },
              {
                headers: {
                  idSession: localStorage.getItem("token"),
                },
              }
            )
            .then((data) => {
              try {
                setCalculated(true);
                let contributions =
                  data.data.data.product_ranges[0]?.contracts[0]?.contributions
                    ?.contributions;
                setMalo(
                  parseFloat(
                    String(
                      contributions.find(
                        (val) =>
                          val.id == import.meta.env.VITE_APP_ID_FRAIS_MALO
                      )?.raw_total
                    ).replace(",", ".")
                  )
                );
                setFraisAssociatif(
                  parseFloat(
                    String(
                      contributions.find(
                        (val) =>
                          val.id == import.meta.env.VITE_APP_ID_FRAIS_ASSOCIATIF
                      )?.raw_total
                    ).replace(",", ".")
                  )
                );
                setFraisPj(
                  parseFloat(
                    String(
                      contributions.find(
                        (val) =>
                          val.id == import.meta.env.VITE_APP_ID_FRAIS_PJ_CFDP
                      )?.raw_total
                    ).replace(",", ".")
                  )
                );
                setFraisMutuaide(
                  parseFloat(
                    String(
                      contributions.find(
                        (val) =>
                          val.id == import.meta.env.VITE_APP_ID_FRAIS_MUTUIDE
                      )?.raw_total
                    ).replace(",", ".")
                  )
                );
                setFraisQuittancement(
                  parseFloat(
                    String(
                      contributions.find(
                        (val) =>
                          val.id ==
                          import.meta.env.VITE_APP_ID_FRAIS_QUITTANCEMENT
                      )?.raw_total
                    ).replace(",", ".")
                  )
                );
                setAlturgence(
                  parseFloat(
                    String(
                      contributions.find(
                        (val) =>
                          val.id ==
                          import.meta.env.VITE_APP_ID_FRAIS_ALTURGENCES
                      )?.raw_total
                    ).replace(",", ".")
                  )
                );
              } catch (e) {
                throw new ErrorBoundary();
              }
              setCalculating(false);
              setCots(data.data.data);
              data.data.data?.total &&
                setBaseTarif(
                  parseFloat(String(data.data.data.total).replace(",", "."))
                );
              setTarifLoader(false);
              setTarifFinal(
                parseFloat(String(data?.data?.data?.total)?.replace(",", "."))?.toFixed(2)
              );
            })
            .catch((err) => {
              console.log(err);
              // const search = window.location.search;
              // window.location = `/error${search}`;
            });
        })
        .catch((err) => console.log(err));
    } 
    else {
      setCalculating(false);
      setTarifLoader(false);
    }
  };


  const updateTarif = (gamme, option,fraisC) => {
    setCalculating(true);
    axios
      .post(
        `${
          import.meta.env.VITE_APP_CLOUD_GEOPROD_APIURL
        }/prod_garanties/get_produit_details_v2`,
        {
          game: gamme,
          option: option,
        },
        {
          headers: {
            idSession: localStorage.getItem("token"),
          },
        }
      )
      .then((res) => {
        seChangedProduct(res.data.produit[0]);
        sessionStorage.setItem(
          "product",
          JSON.stringify(res.data.produit[0])
        );
        let modify_customer_information = [];
        let customer_info = SessionStorageService().getCustomerInfo();
        customer_info.map((val, idx) =>
          modify_customer_information.push({
            ...val,
            contract_information: {
              ...val.contract_information,
              product: [
                parseInt(
                  res.data?.produit[0]?.produit?.produit_sante.cotation_id
                ),
              ],
            },
          })
        );
        let contract_information = {
          effective_date: moment(dateEffet, "DD-MM-YYYY").format(
            "DD/MM/YYYY"
          ),
          mode_splitting: "Mensuel",
          apply_reduction: true,
          prorata: true,
        };

        contract_information = {
          ...contract_information,
          has_modular_collective_contract: false,
          contribution_selected: [
            {
              name: "Frais de dossier distributeur",
              value: fraisLie,
              tax_set: [],
            },
            {
              name: "FCA",
              value: fraisC,
              tax_set: [],
            },
          ],
        };
        axios
          .post(
            `${
              import.meta.env.VITE_APP_CLOUD_GEOPROD_APIURL
            }/pricing/get_pricing`,
            {
              business_introducer: {
                login: "Z04",
              },
              third_app: {
                name: "ASolutions",
                id_external: 476820,
              },
              customer_information: modify_customer_information,
              contract_information: contract_information,
              products: {
                0: Array(
                  SessionStorageService().getCustomerInfo().length
                ).fill([
                  parseInt(
                    res.data?.produit[0]?.produit?.produit_sante.cotation_id
                  ),
                ]),
              },
            },
            {
              headers: {
                idSession: localStorage.getItem("token"),
              },
            }
          )
          .then((data) => {
            try {
              // setCalculated(true);
              let contributions =
                data.data.data.product_ranges[0]?.contracts[0]?.contributions
                  ?.contributions;
              setMalo(
                parseFloat(
                  String(
                    contributions.find(
                      (val) =>
                        val.id == import.meta.env.VITE_APP_ID_FRAIS_MALO
                    )?.raw_total
                  ).replace(",", ".")
                )
              );
              setFraisAssociatif(
                parseFloat(
                  String(
                    contributions.find(
                      (val) =>
                        val.id == import.meta.env.VITE_APP_ID_FRAIS_ASSOCIATIF
                    )?.raw_total
                  ).replace(",", ".")
                )
              );
              setFraisPj(
                parseFloat(
                  String(
                    contributions.find(
                      (val) =>
                        val.id == import.meta.env.VITE_APP_ID_FRAIS_PJ_CFDP
                    )?.raw_total
                  ).replace(",", ".")
                )
              );
              setFraisMutuaide(
                parseFloat(
                  String(
                    contributions.find(
                      (val) =>
                        val.id == import.meta.env.VITE_APP_ID_FRAIS_MUTUIDE
                    )?.raw_total
                  ).replace(",", ".")
                )
              );
              setFraisQuittancement(
                parseFloat(
                  String(
                    contributions.find(
                      (val) =>
                        val.id ==
                        import.meta.env.VITE_APP_ID_FRAIS_QUITTANCEMENT
                    )?.raw_total
                  ).replace(",", ".")
                )
              );
              setAlturgence(
                parseFloat(
                  String(
                    contributions.find(
                      (val) =>
                        val.id ==
                        import.meta.env.VITE_APP_ID_FRAIS_ALTURGENCES
                    )?.raw_total
                  ).replace(",", ".")
                )
              );
            } catch (e) {
              throw new ErrorBoundary();
            }
            setCalculating(false);
            setCots(data.data.data);
            data.data.data?.total &&
              setBaseTarif(
                parseFloat(String(data.data.data.total).replace(",", "."))
              );
            setTarifLoader(false);
            setTarifFinal(
              parseFloat(String(data?.data?.data?.total)?.replace(",", "."))?.toFixed(2)
            );
          })
          .catch((err) => {
            console.log(err);
            // const search = window.location.search;
            // window.location = `/error${search}`;
          });
      })
      .catch((err) => console.log(err));
  };

  // React.useEffect(() => {
  //   const updateTarif = (gamme, option) => {
  //     setCalculating(true);
  //     axios
  //       .post(
  //         `${
  //           import.meta.env.VITE_APP_CLOUD_GEOPROD_APIURL
  //         }/prod_garanties/get_produit_details_v2`,
  //         {
  //           game: gamme,
  //           option: option,
  //         },
  //         {
  //           headers: {
  //             idSession: localStorage.getItem("token"),
  //           },
  //         }
  //       )
  //       .then((res) => {
  //         seChangedProduct(res.data.produit[0]);
  //         sessionStorage.setItem(
  //           "product",
  //           JSON.stringify(res.data.produit[0])
  //         );
  //         let modify_customer_information = [];
  //         let customer_info = SessionStorageService().getCustomerInfo();
  //         customer_info.map((val, idx) =>
  //           modify_customer_information.push({
  //             ...val,
  //             contract_information: {
  //               ...val.contract_information,
  //               product: [
  //                 parseInt(
  //                   res.data?.produit[0]?.produit?.produit_sante.cotation_id
  //                 ),
  //               ],
  //             },
  //           })
  //         );
  //         let contract_information = {
  //           effective_date: moment(dateEffet, "DD-MM-YYYY").format(
  //             "DD/MM/YYYY"
  //           ),
  //           mode_splitting: "Mensuel",
  //           apply_reduction: true,
  //           prorata: true,
  //         };

  //         contract_information = {
  //           ...contract_information,
  //           has_modular_collective_contract: false,
  //           contribution_selected: [
  //             {
  //               name: "Frais de dossier distributeur",
  //               value: fraisLie,
  //               tax_set: [],
  //             },
  //             {
  //               name: "FCA",
  //               value: fraisCourtage,
  //               tax_set: [],
  //             },
  //           ],
  //         };
  //         axios
  //           .post(
  //             `${
  //               import.meta.env.VITE_APP_CLOUD_GEOPROD_APIURL
  //             }/pricing/get_pricing`,
  //             {
  //               business_introducer: {
  //                 login: "Z04",
  //               },
  //               third_app: {
  //                 name: "ASolutions",
  //                 id_external: 476820,
  //               },
  //               customer_information: modify_customer_information,
  //               contract_information: contract_information,
  //               products: {
  //                 0: Array(
  //                   SessionStorageService().getCustomerInfo().length
  //                 ).fill([
  //                   parseInt(
  //                     res.data?.produit[0]?.produit?.produit_sante.cotation_id
  //                   ),
  //                 ]),
  //               },
  //             },
  //             {
  //               headers: {
  //                 idSession: localStorage.getItem("token"),
  //               },
  //             }
  //           )
  //           .then((data) => {
  //             try {
  //               setCalculated(true);
  //               let contributions =
  //                 data.data.data.product_ranges[0]?.contracts[0]?.contributions
  //                   ?.contributions;
  //               setMalo(
  //                 parseFloat(
  //                   String(
  //                     contributions.find(
  //                       (val) =>
  //                         val.id == import.meta.env.VITE_APP_ID_FRAIS_MALO
  //                     )?.raw_total
  //                   ).replace(",", ".")
  //                 )
  //               );
  //               setFraisAssociatif(
  //                 parseFloat(
  //                   String(
  //                     contributions.find(
  //                       (val) =>
  //                         val.id == import.meta.env.VITE_APP_ID_FRAIS_ASSOCIATIF
  //                     )?.raw_total
  //                   ).replace(",", ".")
  //                 )
  //               );
  //               setFraisPj(
  //                 parseFloat(
  //                   String(
  //                     contributions.find(
  //                       (val) =>
  //                         val.id == import.meta.env.VITE_APP_ID_FRAIS_PJ_CFDP
  //                     )?.raw_total
  //                   ).replace(",", ".")
  //                 )
  //               );
  //               setFraisMutuaide(
  //                 parseFloat(
  //                   String(
  //                     contributions.find(
  //                       (val) =>
  //                         val.id == import.meta.env.VITE_APP_ID_FRAIS_MUTUIDE
  //                     )?.raw_total
  //                   ).replace(",", ".")
  //                 )
  //               );
  //               setFraisQuittancement(
  //                 parseFloat(
  //                   String(
  //                     contributions.find(
  //                       (val) =>
  //                         val.id ==
  //                         import.meta.env.VITE_APP_ID_FRAIS_QUITTANCEMENT
  //                     )?.raw_total
  //                   ).replace(",", ".")
  //                 )
  //               );
  //               setAlturgence(
  //                 parseFloat(
  //                   String(
  //                     contributions.find(
  //                       (val) =>
  //                         val.id ==
  //                         import.meta.env.VITE_APP_ID_FRAIS_ALTURGENCES
  //                     )?.raw_total
  //                   ).replace(",", ".")
  //                 )
  //               );
  //             } catch (e) {
  //               throw new ErrorBoundary();
  //             }
  //             setCalculating(false);
  //             setCots(data.data.data);
  //             data.data.data?.total &&
  //               setBaseTarif(
  //                 parseFloat(String(data.data.data.total).replace(",", "."))
  //               );
  //             setTarifLoader(false);
  //             setTarifFinal(
  //               parseFloat(String(data.data.data.total).replace(",", "."))
  //             );
  //           })
  //           .catch((err) => {
  //             console.log(err);
  //             // const search = window.location.search;
  //             // window.location = `/error${search}`;
  //           });
  //       })
  //       .catch((err) => console.log(err));
  //   };
  //   setTarifLoader(true);
  //   updateTarif(
  //     sessionStorage.getItem("selectedGamme") ||
  //       proposition?.produit.produit_sante.game,
  //     SessionStorageService()?.getProspect()?.formule ||
  //       proposition?.produit?.produit_sante?.option
  //   );
  // }, [fraisCourtage]);




  React.useEffect(() => {
    //setProducts(products);
    sessionStorage.setItem("reset", "1");
    if (!SessionStorageService().getProspect()) {
      SessionStorageService().setProspect({
        ...SessionStorageService().getProspect(),
        ...prospect,
      });
    } else {
      SessionStorageService().setProspect({
        ...SessionStorageService().getProspect(),
      });
    }
    // SessionStorageService().setCustomerInfo(customerInfo);
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  React.useEffect(() => {
    sessionStorage.setItem(
      "product",
      changedProduct
        ? JSON.stringify(changedProduct)
        : JSON.stringify(proposition)
    );
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [changedProduct]);

  React.useEffect(() => {
    if (tarifs) {
      // let temp = parseFloat(String(baseTarif).replace(",", "."));
      let firstAmount = baseTarif + fraisLie + fraisCourtage;
      setFirstEcheance(firstAmount?.toFixed(2));
      // setTarifFinal(temp?.toFixed(2));
    }
    optionHorsSante &&
      sessionStorage.setItem(
        "OptionHorsSante",
        JSON.stringify(optionHorsSante)
      );
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    optionHorsSante,
    baseTarif,
    repriseData,
    tarifFinal,
    fraisLie,
    fraisCourtage,
  ]);

  return repriseData && Object.keys(repriseData).length > 0 ? (
    <div ref={containerDiv} className={classes.container}>
      <GarantiesModal
        toggle={toggle}
        modal={modal}
        proposition={changedProduct ? changedProduct : proposition}
        tarif={baseTarif}
      />
      <Row className={classes.btnRow} gutter={24}>
        <Col
          xs={24}
          sm={24}
          md={12}
          lg={12}
          xl={12}
          className={classes.btnCol1}
        >
          <Button
            size="large"
            type="primary"
            classNames={classes.adhesionButton}
            style={{ backgroundColor: "#faad14", color: "white" }}
            className={classes.btn}
            disabled={calculating}
            onClick={() => {
              if (
                !SessionStorageService()
                  .getEmailProspect()
                  .name?.replaceAll(" ", "") ||
                !SessionStorageService()
                  .getProspect()
                  .surname?.replaceAll(" ", "")
              ) {
                alertMessage(
                  "Veuillez remplir les champs nom et prénom.",
                  "error"
                );
              }
              //  else if (
              //   /\d/.test(SessionStorageService().getEmailProspect().name)
              // ) {
              //   alertMessage(
              //     "Attention ! Présence des chiffres dans le champ nom.",
              //     "error"
              //   );
              // } else if (
              //   /\d/.test(SessionStorageService().getProspect().surname)
              // ) {
              //   alertMessage(
              //     "Attention ! Présence des chiffres dans le champ prénom.",
              //     "error"
              //   );
              // }
               else if (
                !isValidName(SessionStorageService().getProspect().surname)
              ) {
                alertMessage("Veuillez vérifier votre prénom.", "error");
              } else if (
                !isValidName(SessionStorageService().getEmailProspect().name)
              ) {
                alertMessage("Veuillez vérifier votre nom.", "error");
              }

              // else if (
              //   hasLeadingOrTrailingSpace(
              //     SessionStorageService().getEmailProspect().name
              //   )
              // ) {
              //   alertMessage(
              //     "Attention ! Présence d'espace ou de caractère interdit dans le champ nom.",
              //     "error"
              //   );
              // }
              //  else if (
              //   hasLeadingOrTrailingSpace(
              //     SessionStorageService().getProspect().surname
              //   )
              // ) {
              //   alertMessage(
              //     "Attention ! Présence d'espace ou de caractère interdit dans le champ prenom.",
              //     "error"
              //   );
              // }
              else {
                notification.destroy();
                !sessionStorage.getItem("SessionURLID") && createOpp();
                let search = window.location.search;
                navigate(`/adhesion${search}`, {
                  state: {
                    proposition: changedProduct ? changedProduct : proposition,
                    optionHorsSante,
                  },
                });
              }
            }}
          >
            Adhésion
          </Button>

          <Button
            color="info"
            size="large"
            disabled={calculating}
            type="primary"
            style={{ backgroundColor: "#36cfc9", color: "white" }}
            onClick={() => {
              if (
                !SessionStorageService()
                  .getProspect()
                  ?.name?.replaceAll(" ", "") ||
                !SessionStorageService()
                  .getProspect()
                  ?.surname?.replaceAll(" ", "")
              ) {
                alertMessage(
                  "Veuillez remplir les champs nom et prénom.",
                  "error"
                );
              } else if (
                /\d/.test(SessionStorageService().getEmailProspect().name)
              ) {
                alertMessage(
                  "Attention ! Présence des chiffres dans le champ nom.",
                  "error"
                );
              } else if (
                /\d/.test(SessionStorageService().getProspect().surname)
              ) {
                alertMessage(
                  "Attention ! Présence des chiffres dans le champ prénom.",
                  "error"
                );
              }
              else if (
                !isValidName(SessionStorageService().getProspect().surname)
              ) {
                alertMessage("Veuillez vérifier votre prénom.", "error");
              } else if (
                !isValidName(SessionStorageService().getEmailProspect().name)
              ) {
                alertMessage("Veuillez vérifier votre nom.", "error");
              }

              // else if (
              //   hasLeadingOrTrailingSpace(
              //     SessionStorageService().getEmailProspect().name
              //   )
              // ) {
              //   alertMessage(
              //     "Attention ! Présence d'espace ou de caractère interdit dans le champ nom.",
              //     "error"
              //   );
              // }
              //  else if (
              //   hasLeadingOrTrailingSpace(
              //     SessionStorageService().getProspect().surname
              //   )
              // ) {
              //   alertMessage(
              //     "Attention ! Présence d'espace ou de caractère interdit dans le champ prenom.",
              //     "error"
              //   );
              // }
              else {
                notification.destroy();
                setOpenbackdrop(true);

                if (!sessionStorage.getItem("SessionURLID")) {
                  createOpp("devis");
                } else {
                  setOpenbackdrop(false);
                  let apiKey = window.location.search;
                  navigate(`/devisTarificateur${apiKey}`, {
                    state: {
                      proposition: changedProduct
                        ? changedProduct
                        : proposition,
                      baseTarif: baseTarif,
                      param_id: param_id,
                      tarif: tarifFinal,
                    },
                  });
                }
              }
            }}
            className={classes.btn}
          >
            Devis
          </Button>

          <Button
            color="success"
            size="large"
            type="primary"
            style={{ backgroundColor: "#389e0d", color: "white" }}
            className={classes.btn}
            onClick={toggle}
          >
            Garanties
          </Button>
        </Col>
      </Row>
      <UserInfo
        getNewTarif={getNewTarif}
        proposition={proposition}
        prospect={prospect}
      />
      <GroupMember />
      {/* <TotalCotisation
        cout={firstEcheance}
        name="Total de la cotisation mensuelle initiale :"
      /> */}
      <FraisTarificateur
        options_frais_courtage={options_frais_courtage}
        baseTarif={baseTarif}
        updateTarif={updateTarif}
        proposition={proposition}
      />
      {/* <Form
        layout="vertical"
        style={{
          paddingInline: "3rem",
          margin: "1rem",
        }}
      >
        <Row
          style={{
            justifyContent: "space-around",
            alignItems: "center",
          }}
        >
          <Col xs={24} sm={24} md={24} xl={8} lg={8}>
            <Form.Item label="Frais de Dossier :">
              <Select
                size="large"
                // options={options_frais_courtage}
                value={"10 €"}
                // onSelect={(option) => {
                //   setFraisCourtage(option);
                // }}
                disabled
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={24} lg={8} xl={8}>
            <Form.Item label="Frais Complémentaire :" required>
              <Select
                value={fraisAesio}
                size="large"
                options={frais_complementaire_options}
                onSelect={(option) => {
                  setFraisAesio(option);
                }}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form> */}
      <TotalCotisation
        cout={tarifFinal}
        name="Total de la cotisation mensuelle :"
        final={true}
      />
      <Backdrop
        sx={{
          color: "#fff",
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
        open={openbackdrop}
        /* onClick={handleCloseBackdrop} */
      >
        <div className={classes.loaderContainer}>
          <Box sx={{ width: "100%" }}>
            <CircularProgress style={{ width: "4rem", height: "4rem" }} />
          </Box>
          <h2>Veuillez patienter un instant.</h2>
        </div>
      </Backdrop>
    </div>
  ) : (
    <></>
  );
};

export default Tarificateur;
