import React from "react";
import classes from "./Garanties.module.css";
// import { Row, Col, Form, Label, Input } from 'reactstrap';
import { Row, Col, Form, Input, Select } from "antd";
import GlobalContext from "../../../contexts/GlobalContext";
import moment from "moment";
import SessionStorageService from "../../../utils/sessionStorageService";
import { checkSelectedGamme } from "../../../utils/utils";

const Garanties = ({ data, setData }) => {
  const {
    optionHorsSante,
    fraisLie,
    fraisCourtage,
    malo,
    fraisAssociatif,
    fraisPj,
    fraisMutuaide,
    alturgence,
    fraisAesio,
    setFraisAesio,
    fraisQuittancement,
  } = React.useContext(GlobalContext);
  const options = [
    { label: <span style={{ color: "black" }}>Oui</span>, value: "Oui" },
    { label: <span style={{ color: "black" }}>Non</span>, value: "Non" },
  ];
  const frais_dossier_aesio = [
    { label: "0 €", value: 0 },
    { label: "10 €", value: 10 },
    { label: "20 €", value: 20 },
    { label: "30 €", value: 30 },
    { label: "40 €", value: 40 },
  ];
  const modeSignatureOptions = [
    {
      label: (
        <span style={{ color: "black" }}>Electronique (SMS ou EMAIL)</span>
      ),
      value: "Electronique (SMS ou EMAIL)",
    },
    {
      label: (
        <span style={{ color: "black" }}>
          Electronique Face2Face (Stylet tablette ou télephone)
        </span>
      ),
      value: "Electronique Face2Face (Stylet tablette ou télephone)",
    },
    {
      label: (
        <span style={{ color: "black" }}>
          Papier (Signature manuscrite classique)
        </span>
      ),
      value: "Papier (Signature manuscrite classique)",
    },
  ];
  // const paymentMethodOptions = [
  //   { label: "Prélèvement automatique", value: "Prélèvement automatique" },
  //   { label: "PRELEVEMENT SEPA", value: "PRELEVEMENT SEPA" },
  //   { label: "CHEQUE", value: "CHEQUE" },
  // ];
  // const datePrelevementOptions = [
  //   { label: "5", value: "5" },
  //   { label: "10", value: "10" },
  //   { label: "15", value: "15" },
  // ];
  const handleChange = (e, changeType, name) => {
    let prospect = SessionStorageService().getProspect();
    prospect[name] = changeType === "change" ? e.target.value : e;
    SessionStorageService().setProspect(prospect);
    // setData([...data], (data[DataIndex][name] = e.target.value));
    /* let newdata = dataAdhesion
    newdata[`${name}`] = e.target.value */
    let key = name;
    setData((prev) => {
      return {
        ...prev,
        [key]: changeType === "change" ? e.target.value : e,
      };
    });
  };

  React.useEffect(() => {
    let nomproduit_sante = "";
    if (
      sessionStorage.getItem("selectedGamme") === "605" ||
      sessionStorage.getItem("selectedGamme") === "7799"
    ) {
      nomproduit_sante = "Aesio";
    } else if (
      sessionStorage.getItem("selectedGamme") === "8473" ||
      sessionStorage.getItem("selectedGamme") === "7942"
    ) {
      nomproduit_sante = "CCMO";
    } else {
      nomproduit_sante = "Seyna";
    }

    setData({
      ...data,
      "nomproduit_sante||Gamme||2": "ACHEEL",
      "formuleproduit_sante||Formule||3": JSON.parse(
        sessionStorage.getItem("product")
      ).produit.produit_sante.option,
      "assistance_sante||Assistance||4": optionHorsSante.includes("Assistance")
        ? "Oui"
        : "Non",
      "pj_sante||Protection juridique||5": optionHorsSante.includes(
        "Protection juridique"
      )
        ? "Oui"
        : "Non",
      val_assistance_text: optionHorsSante.includes("Assistance")
        ? "Oui"
        : "Non",
      val_pj_text: optionHorsSante.includes("Protection juridique")
        ? "Oui"
        : "Non",
      // 'modesignature_sante||Mode de signature||9':
      //   'Electronique (SMS ou EMAIL)',
      "fraisd_sante||Frais de dossier||21":
        SessionStorageService().getProspect()[
          "fraisd_sante||Frais de dossier||21"
        ]
          ? SessionStorageService().getProspect()[
              "fraisd_sante||Frais de dossier||21"
            ]
          : "",
      "fraisq_sante||Frais de quittancement||19": Number(4.5 * 12).toFixed(2),
      "modepc_sante||Mode de paiement du comptant||22":
        "Prélèvement automatique",
      "date_prelevement_sante||Date de prélèvement||24":
        SessionStorageService().getProspect()[
          "date_prelevement_sante||Date de prélèvement||24"
        ] || "5",
      "ds_sante||Date de souscription||0": moment(Date.now()).format(
        "DD-MM-YYYY"
      ),
      "date_effet_sante||Date d'effet||1":
        SessionStorageService().getProspect()?.dateEffet,
    });
    /*  setData(
      [...data],
      (data[6]['nomproduit_sante||Gamme||2'] = 'Aesio'),
      (data[7]['formuleproduit_sante||Formule||3'] = JSON.parse(
        sessionStorage.getItem('product')
      ).produit.produit_sante.option),
      (data[8]['assistance_sante||Assistance||4'] = optionHorsSante.includes(
        'Assistance'
      )
        ? 'Oui'
        : 'Non'),
      (data[9]['pj_sante||Protection juridique||5'] = optionHorsSante.includes(
        'Protection juridique'
      )
        ? 'Oui'
        : 'Non'),
      (data[75]['val_assistance_text'] = optionHorsSante.includes('Assistance')
        ? 'Oui'
        : 'Non'),
      (data[74]['val_pj_text'] = optionHorsSante.includes(
        'Protection juridique'
      )
        ? 'Oui'
        : 'Non'),
      (data[81]['modesignature_sante||Mode de signature||9'] =
        'Electronique ou papier'),
      (data[93]['fraisd_sante||Frais de dossier||21'] = '10'),
      (data[89]['fraisq_sante||Frais de quittancement||19'] = Number(
        4.5 * 12
      ).toFixed(2)),
      (data[94]['modepc_sante||Mode de paiement du comptant||22'] =
        'Prélèvement automatique'),
      (data[96]['date_prelevement_sante||Date de prélèvement||24'] =
        SessionStorageService().getProspect()[
          'date_prelevement_sante||Date de prélèvement||24'
        ] || '5'),
      (data[161]['ds_sante||Date de souscription||0'] = moment(
        Date.now()
      ).format('DD-MM-YYYY')),
      (data[77]["date_effet_sante||Date d'effet||1"] =
        SessionStorageService().getProspect()?.dateEffet)
    ); */
    // eslint-disable-next-line
  }, []);

  return (
    <Form layout="vertical" className={classes.form}>
      <Row gutter={16}>
        <Col xs={24} sm={24} md={24} lg={12} xl={12}>
          <Form.Item label={"Date de souscription"}>
            <Input
              size="large"
              style={{ color: "black" }}
              // value={moment(data["ds_sante||Date de souscription||0"],"DD-MM-YYYY").format("DD/MM/YYYY")}
              value={moment().format("DD/MM/YYYY")}
              name="ds_sante||Date de souscription||0"
              // onChange={(e) =>
              //   handleChange(e, "change", "ds_sante||Date de souscription||0")
              // }
              disabled
            />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={24} lg={12} xl={12}>
          <Form.Item label={"Date d'effet"}>
            <Input
              size="large"
              style={{ color: "black" }}
              value={
                moment(
                  data["date_effet_sante||Date d'effet||1"],
                  "DD-MM-YYYY"
                ).format("DD/MM/YYYY") ||
                data["date_effet_sante||Date d'effet||1"]
              }
              name="date_effet_sante||Date d'effet||1"
              // onChange={(e) =>
              //   handleChange(e, "change", "date_effet_sante||Date d'effet||1")
              // }
              disabled
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col xs={24} sm={24} md={24} lg={12} xl={12}>
          <Form.Item label={"Gamme"}>
            <Input
              size="large"
              style={{ color: "black" }}
              name="nomproduit_sante||Gamme||2"
              value={"ACHEEL"}
              onChange={(e) =>
                handleChange(e, "change", "nomproduit_sante||Gamme||2")
              }
              disabled
            />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={24} lg={12} xl={12}>
          <Form.Item label={"Formule"}>
            <Input
              size="large"
              style={{ color: "black" }}
              name="formuleproduit_sante||Formule||3"
              value={data["formuleproduit_sante||Formule||3"]}
              disabled
              onChange={(e) =>
                handleChange(e, "change", "formuleproduit_sante||Formule||3")
              }
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col xs={24} sm={24} md={24} lg={12} xl={12}>
          <Form.Item label={"Assistance"}>
            <Select
              size="large"
              name="assistance_sante||Assistance||4"
              options={options}
              value={data["assistance_sante||Assistance||4"]}
              onSelect={(e) =>
                handleChange(e, "select", "assistance_sante||Assistance||4")
              }
              disabled
            />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={24} lg={12} xl={12}>
          <Form.Item label={"Protection juridique"}>
            <Select
              size="large"
              name="pj_sante||Protection juridique||5"
              options={options}
              value={data["pj_sante||Protection juridique||5"]}
              onSelect={(e) =>
                handleChange(e, "select", "pj_sante||Protection juridique||5")
              }
              disabled
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        {/* <Col xs={24} sm={24} md={24} lg={12} xl={12}>
            <Form.Item label="Frais Complémentaire:">
              <Select
                disabled
                value={`${fraisAesio} €`}
                size="large"
                onSelect={(option) => {
                  setFraisAesio(option);
                }}
              />
            </Form.Item>
          </Col> */}

        <Col xs={24} sm={24} md={24} lg={12} xl={12}>
          <Form.Item label={"Frais de dossier"}>
            <Input
              size="large"
              style={{ color: "black" }}
              value={"10 €"}
              onChange={(e) =>
                handleChange(e, "change", "fraisd_sante||Frais de dossier||21")
              }
              name="fraisd_sante||Frais de dossier||21"
              //value={data["fraisd_sante||Frais de dossier||21"]}
              disabled
            />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={24} lg={12} xl={12}>
          <Form.Item label={"Frais de Courtage Accessoires"}>
            <Input
              disabled
              size="large"
              style={{ color: "black" }}
              name="Frais de Courtage Accessoires"
              value={`${fraisCourtage} €`}
            />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={24} lg={12} xl={12}>
          <Form.Item label={"Frais de Dossier Distributeur"}>
            <Input
              disabled
              size="large"
              style={{ color: "black" }}
              name="Frais de Dossier Distributeur"
              value={`${fraisLie} €`}
            />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={24} lg={12} xl={12}>
          <Form.Item label={"Malo"}>
            <Input
              style={{ color: "black" }}
              size="large"
              name="malo"
              value={`${malo || '-'} €`}
              disabled
            />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={24} lg={12} xl={12}>
          <Form.Item label={"Alturgence"}>
            <Input
              disabled
              size="large"
              style={{ color: "black" }}
              name="Alturgence"
              value={`${alturgence || '-'} €`}
            />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={24} lg={12} xl={12}>
          <Form.Item label={"Frais de quittancement"}>
            <Input
              disabled
              size="large"
              style={{ color: "black" }}
              name="Frais liés à la Distribution"
              value={`${fraisQuittancement || '-'} €`}
            />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={24} lg={12} xl={12}>
          <Form.Item label={"PJ CFDP"}>
            <Input
              disabled
              size="large"
              style={{ color: "black" }}
              name="PJ CFDP"
              value={`${fraisPj || '-'} €`}
            />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={24} lg={12} xl={12}>
          <Form.Item label={"Assistance MUTUAIDE"}>
            <Input
              disabled
              size="large"
              style={{ color: "black" }}
              name="Assistance MUTUAIDE"
              value={`${fraisMutuaide || '-'} €`}
            />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={24} lg={12} xl={12}>
              <Form.Item label={"Cotisation 4AS"}>
                <Input
                  disabled
                  size="large"
                  style={{ color: "black" }}
                  name="Cotisation 4AS"
                  value={`${fraisAssociatif || '-'} €`}
                />
              </Form.Item>
            </Col>


        <Col xs={24} sm={24} md={24} lg={12} xl={12}>
          <Form.Item label={"Mode de signature"}>
            <Select
              size="large"
              name="modesignature_sante||Mode de signature||9"
              options={modeSignatureOptions}
              value={data["modesignature_sante||Mode de signature||9"]}
              onSelect={(e) => {
                handleChange(
                  e,
                  "select",
                  "modesignature_sante||Mode de signature||9"
                );
              }}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        {/* <Col xs={24} sm={24} md={24} lg={12} xl={12}>
          <Form.Item label={"Jour de prélèvement"}>
            <Select
              size="large"
              onSelect={(e) =>
                handleChange(
                  e,
                  "select",
                  "date_prelevement_sante||Date de prélèvement||24"
                )
              }
              options={datePrelevementOptions}
              name="date_prelevement_sante||Date de prélèvement||24"
              value={data["date_prelevement_sante||Date de prélèvement||24"]}
            />
          </Form.Item>
        </Col> */}
      </Row>
    </Form>
  );
};

export default Garanties;
