import React from "react";
import classes from "./NotFound.module.css";
function NotFound() {
  return (
    <div id={classes.main}>
      <div className={classes.fof}>
        <h1>Erreur 404</h1>
      </div>
    </div>
  );
}

export default NotFound;
