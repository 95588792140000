import React from "react";
import { useState, useEffect } from "react";
import classes from "./Userinfo.module.css";
import { Row, Col, Form, Input } from "antd";
import SessionStorageService from "../../../utils/sessionStorageService";
import axios from "../../../utils/axios";
import moment from "moment";
import GlobalContext from "../../../contexts/GlobalContext";
import Swal from "sweetalert2";

function UserInfo(props) {
  // eslint-disable-next-line
  const { setNom, setSurname, products, setTarifLoader, setOptionHorsSante } =
    React.useContext(GlobalContext);
  // const productsOptions = products?.map((product) => ({
  //   label: `${product?.nom_cie} - ${product?.nom_produit}`,
  //   value: product?.gamme,
  // }));
  const { getNewTarif, proposition, prospect } = props;
  const [prospectData, setProspectData] = useState(prospect);
  const [, /* departement */ setDepartement] = useState([]);
  // eslint-disable-next-line
  const [options, setOptions] = useState([]);
  // eslint-disable-next-line
  const [renforts, setRenforts] = useState(null);
  // eslint-disable-next-line
  const [selectedGamme, setSelectedGamme] = useState(
    sessionStorage.getItem("selectedGamme") ||
      proposition?.produit.produit_sante.game
  );
  useEffect(() => {
    sessionStorage.setItem(
      "selectedGamme",
      sessionStorage.getItem("selectedGamme") ||
        proposition?.produit.produit_sante.game
    );
    SessionStorageService().setProductData({
      ...SessionStorageService().getProductData(),
      productData: products.find(
        (product) => product.gamme === proposition.produit.produit_sante.game
      ),
    });
    let arrdep = [];
    for (let i = 1; i < 98; i++) {
      arrdep.push(i);
    }

    setDepartement(arrdep);
    axios
      .post(
        `${
          import.meta.env.VITE_APP_CLOUD_GEOPROD_APIURL
        }/game_prod/get_statut_gamme`,
        {
          nom: "FULL SANTE",
        }
      )
      .then((res) => {
        if (res.data.description) {
          Swal.fire("Status de la gamme", res.data.description, "warning");
        }
      })
      .catch((err) => {
        console.log(err);
      });

    axios
      .post(
        `${
          import.meta.env.VITE_APP_CLOUD_GEOPROD_APIURL
        }/produit_sante/get_game_options`,
        {
          game: selectedGamme,
        },
        {
          headers: {
            idSession: localStorage.getItem("token"),
          },
        }
      )
      .then((res) => {
        const selectOptions = res.data.options.map((option) => ({
          value: option,
          label: option,
        }));
        setOptions(selectOptions);
        setProspectData({ ...prospectData, options: res.data.options });
      })
      .catch((err) => {
        console.log(err);
      });
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!SessionStorageService().getUserInfo())
      SessionStorageService().setUserInfo(prospectData);
    // eslint-disable-next-line
  }, []);

  const getOptionsRenfort = (option, gamme) => {
    setTarifLoader(true);
    if (option == null) {
      axios
        .post(
          `${
            import.meta.env.VITE_APP_CLOUD_GEOPROD_APIURL
          }/produit_sante/get_game_options`,
          {
            game: gamme,
          },
          {
            headers: {
              idSession: localStorage.getItem("token"),
            },
          }
        )
        .then((res) => {
          const renfortsOptions = res.data.options.map((option) => ({
            value: option,
            label: option,
          }));
          setOptions(renfortsOptions);
          getNewTarif(gamme, renfortsOptions[0].value);
          SessionStorageService().setProspect({
            ...SessionStorageService().getProspect(),
            formule: renfortsOptions[0].value,
          });
          setProspectData({ ...prospectData, options: res.data.options });
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      getNewTarif(gamme, option);
    }
  };

  React.useEffect(() => {
    getOptionsRenfort(
      SessionStorageService()?.getProspect()?.formule ||
        proposition?.produit?.produit_sante?.option,
      sessionStorage.getItem("selectedGamme") ||
        proposition?.produit.produit_sante.game
    );
    // eslint-disable-next-line
  }, []);
  return (
    <Form layout="vertical" className={classes.formulaire}>
      {/* <Row className={classes.title}>
        <Col>
          Il faut choisir au moins un département, une année d'effet, un
          produit, une formule et un assuré
        </Col>
      </Row> */}
      <Row gutter={16}>
        <Col xs={24} sm={24} md={24} lg={12}>
          <Form.Item label="Nom" required>
            <Input
              name="nom"
              size="large"
              defaultValue={
                SessionStorageService().getProspect()?.name
                  ? SessionStorageService().getProspect()?.name
                  : prospectData?.name || ""
              }
              onChange={(e) => {
                setProspectData({ ...prospectData, name: e.target.value });
                SessionStorageService().setProspect({
                  ...SessionStorageService().getProspect(),
                  name: e.target.value,
                });
                setNom(e.target.value);
              }}
            />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={24} lg={12}>
          <Form.Item label="Prénom" required>
            <Input
              size="large"
              defaultValue={
                SessionStorageService().getProspect()?.surname
                  ? SessionStorageService().getProspect()?.surname
                  : prospectData?.surname || ""
              }
              onChange={(e) => {
                setProspectData({ ...prospectData, surname: e.target.value });
                SessionStorageService().setProspect({
                  ...SessionStorageService().getProspect(),
                  surname: e.target.value,
                });
                setSurname(e.target.value);
              }}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col xs={24} sm={24} md={24} lg={12}>
          {/*  <Label for="dept">Département (*) :</Label>
          <Input
            type="select"
            value={
              prospectData?.departement[0] === "0"
                ? prospectData?.departement[1]
                : prospectData?.departement
            }
            onChange={(e) => {
              setProspectData({ ...prospectData, departement: e.target.value });
              SessionStorageService().setProspect({
                ...prospectData,
                departement: e.target.value,
              });
            }}
            name="select"
            id="dept"
          >
            {departement &&
              departement?.map((item, index) => (
                <option key={index} value={item}>
                  {item}
                </option>
              ))}
          </Input> */}
          <Form.Item label="Code Postal" required>
            <Input
              size="large"
              style={{ color: "black" }}
              defaultValue={
                SessionStorageService().getCustomerInfo()
                  ? SessionStorageService().getCustomerInfo()[0]?.address
                      .zip_code || ""
                  : ""
              }
              disabled
            />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={24} lg={12}>
          <Form.Item label="Date d'effet" required>
            <Input
              size="large"
              style={{ color: "black" }}
              defaultValue={
                moment(prospectData?.dateEffet, "DD-MM-YYYY").format(
                  "DD/MM/YYYY"
                ) || ""
              }
              disabled
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col xs={24} sm={24} md={24} lg={12}>
          <Form.Item label="Produit" required>
            {/* <Select
              disabled
              size='large'
              options={productsOptions}
              value={
                productsOptions.length !== 0 ? parseInt(selectedGamme) : ''
              }
              onSelect={(option) => {
                setSelectedGamme(option);
                sessionStorage.setItem('selectedGamme', option);
                getOptionsRenfort(null, option);

                SessionStorageService().setProductData({
                  ...SessionStorageService().getProductData(),
                  // eslint-disable-next-line
                  productData: products.find(
                    (product) => product.gamme === option
                  ),
                });
                if (
                  JSON.parse(import.meta.env.VITE_APP_AESIO_ID_GAMME).includes(
                    option
                  )
                ) {
                  setOptionHorsSante([]);
                }
              }}
            /> */}
            <Input
              disabled
              style={{ color: "black" }}
              size="large"
              defaultValue={`ACHEEL - ${proposition?.produit?.produit_sante?.nom_produit}`}
            />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={24} lg={12}>
          <Form.Item label="Formule" required>
            {/* <Select
              disabled
              size='large'
              value={
                SessionStorageService().getProspect()
                  ? SessionStorageService().getProspect().formule
                  : ''
              }
              options={options}
              onSelect={(option) => {
                getOptionsRenfort(option, selectedGamme);
                let temp = {
                  ...prospectData,
                  ...SessionStorageService().getProspect(),
                };
                temp['formule'] = option;
                SessionStorageService().setProspect(temp);
                SessionStorageService().setUserInfo(temp);
              }}
            /> */}
            <Input
              disabled
              style={{ color: "black" }}
              size="large"
              defaultValue={proposition?.produit?.produit_sante?.option}
            />
          </Form.Item>
        </Col>
      </Row>
      {/* <Row>
        {renforts && renforts[0] !== "" && (
          <Col xs={24} sm={12} lg={12}>
            <Form.Item label="Renfort (*) :">
              <Select size="large" options={renforts} />
            </Form.Item>
          </Col>
        )}
      </Row> */}
      {/* <Row>
        <p style={{ marginTop: "1rem" }}>
          <span style={{ color: "red" }}>*</span>La date d'effet ne dépasse pas
          4 mois à compter de la date de souscription.
        </p>
      </Row> */}
    </Form>
  );
}

export default UserInfo;
