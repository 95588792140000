import React, { useState, useEffect } from 'react';
import Accordion from 'react-bootstrap/Accordion';
import { Form, Select, Row, Col } from 'antd';
import classes from './Member.module.css';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import SessionStorageService from '../../../../utils/sessionStorageService';
function Member({ name, birth_date, RO, member }) {
  let ladate = new Date();

  const [age, setAge] = useState(
    ladate.getFullYear() - birth_date.split('/')[2]
  );
  const [birthYear, setBirthYear] = useState(birth_date?.split('/')[2]);
  const [ro, setRo] = useState(RO);
  const [annee, setAnnee] = useState([]);

  useEffect(() => {
    let arr = [];
    for (let i = 1900; i <= ladate.getFullYear(); i++) {
      arr.push(i);
    }
    const anneOptions = arr.map((a) => ({
      label: a,
      value: a,
    }));
    setAnnee(anneOptions);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChangeToSessionStorage = (item) => {
    const newArray = SessionStorageService()
      .getCustomerInfo()
      .map((customerInfo) => {
        if (customerInfo.number === item.number) {
          customerInfo = item;
        }
        return customerInfo;
      });
    SessionStorageService().setCustomerInfo(newArray);
  };

  const ageHandler = (e) => {
    setAge(ladate.getFullYear() - e.target.value);
    setBirthYear(e.target.value);
    if (member.relationship === 'primary subscriber') {
      SessionStorageService().setProspect({
        ...SessionStorageService().getProspect(),
        DN: e.target.value,
      });
    }
    member = { ...member, birth_info: { date: '01/01/' + e.target.value } };
    handleChangeToSessionStorage(member);
  };
  const roHandler = (option) => {
    setRo(option);
    member = { ...member, job: { regime: option } };
    handleChangeToSessionStorage(member);
  };
  const roOptions = [
    { label: 'Régime général', value: 'Sécurité' },
    { label: 'Travailleurs non salariés', value: 'Travailleurs non salariés' },
    { label: 'Agricole exploitant', value: 'Agricole exploitant' },
    { label: 'Régime local', value: 'Alsace mosellle' },
  ];
  return (
    <Row>
      <Col xs={24} sm={24} md={24} lg={24} xl={24} className={classes.acord}>
        <Accordion defaultActiveKey='1'>
          <Accordion.Item eventKey='0'>
            <Accordion.Header>
              <Row style={{ display: 'flex', flexDirection: 'row' }}>
                <AddCircleOutlineIcon />
                <h6 className={classes.titre}> {name} </h6>

                {age !== '' && <h6 className={classes.ro}> {age} ans </h6>}
                <h6 className={classes.ro}> {ro}</h6>
              </Row>
            </Accordion.Header>
            <Accordion.Body>
              <Form className={classes.family} colon={false} >
                <Row className={classes.rowro}>
                  <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                    <Form.Item
                      label={
                        <div className={classes.labelStyle}>
                          <span>Régime obligatoire </span>
                        </div>
                      }
                    >
                      <Select
                        size='large'
                        value={ro}
                        defaultValue={ro}
                        options={roOptions}
                        onSelect={(option) => roHandler(option)}
                        disabled
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                    <Form.Item
                      label={
                        <div className={classes.labelStyle}>
                          <span>Année de naissance</span>
                        </div>
                      }
                    >
                      <Select
                        size='large'
                        value={birthYear}
                        defaultValue={birthYear}
                        options={annee}
                        onSelect={(option) => ageHandler(option)}
                        disabled
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </Col>
    </Row>
  );
}

export default Member;
