import React, { useState } from "react";
import classes from "./Assure.module.css";
// import { Row, Col, Form, Label, Input } from "reactstrap";
import {
  Row,
  Col,
  Form,
  Input,
  Select,
  Space,
  Tooltip,
  AutoComplete,
} from "antd";
import SessionStorageService from "../../../utils/sessionStorageService";
import moment from "moment";
import { PhoneOutlined } from "@material-ui/icons";
import { InfoCircleOutlined } from "@ant-design/icons";
import listPays from "../../../constants/listPays.json";

const Assure = ({
  setData,
  isTrueSS,
  isValidSS,
  isValidEmail,
  dataAdhesion,
  setDataAdhesion,
  jumeauOptions,
}) => {
  // eslint-disable-next-line
  const [assure, setAssure] = useState(
    SessionStorageService().getEmailProspect()
  );
  const [ville, setVille] = useState(false);
  // eslint-disable-next-line
  const [address, setAddress] = useState(false);
  const genderOptions = [
    { label: "M", value: "M" },
    { label: "Mme", value: "Mme" },
  ];
  const options = Object.entries(listPays)
    .map(([code, name]) => ({
      value: name,
      key: name,
    }))
    .sort((a, b) => a.value.localeCompare(b.value));
  const [filteredOptions, setFilteredOptions] = useState(options);
  const [form] = Form.useForm();
  const regimeOptions = [
    {
      label: <span style={{ color: "black" }}>Sécurité sociale</span>,
      value: "Régime général",
    },
    {
      label: <span style={{ color: "black" }}>Travailleurs non salariés</span>,
      value: "Travailleurs non salariés",
    },
    {
      label: <span style={{ color: "black" }}>Agricole salarié</span>,
      value: "Agricole salarié",
    },
    {
      label: <span style={{ color: "black" }}>Agricole exploitant</span>,
      value: "Agricole exploitant",
    },
    {
      label: <span style={{ color: "black" }}>Alsace Moselle</span>,
      value: "Régime local",
    },
  ];

  React.useEffect(() => {
    let newdata = dataAdhesion;
    newdata["nom_assure||Nom||1"] = assure?.name || "";
    newdata["prenom_assure||Prénom||2"] = assure?.surname || "";
    newdata["mailassure_text"] = assure?.mailassure_text || "";
    newdata["email"] = assure?.mailassure_text || "";
    newdata["mobileassure_text"] = assure?.mobile || "";
    newdata["suffix_mobile_assure"] = assure["suffix_mobile_assure"] || "";
    newdata["lieu_naissance_principal"] = SessionStorageService().getProspect()[
      "lieu_naissance_principal"
    ]
      ? SessionStorageService().getProspect()["lieu_naissance_principal"]
      : "";
    newdata["adresse_assure||Adresse||3"] =
      SessionStorageService().getCustomerInfo()[0].address?.fullAddress +
      ", " +
      SessionStorageService().getCustomerInfo()[0].address.locality +
      ", " +
      SessionStorageService().getCustomerInfo()[0].address.zip_code;
    newdata["dn_assure||Date de naissance||3"] = assure?.DN
      ? assure?.DN
      : "01/01/1980";
    newdata["cp_assure||Code postal||4"] =
      SessionStorageService().getCustomerInfo()[0].address.zip_code;
    newdata["ro_assure||Régime||4"] =
      SessionStorageService().getCustomerInfo()[0].job.regime;
    newdata["ville_assure||Ville||5"] =
      SessionStorageService().getCustomerInfo()[0].address.locality || "";
    newdata["num_sec_assure||N° sécurité sodiale||6"] =
      assure["num_sec_assure||N° sécurité sodiale||6"] || "";
    newdata["exerceequalite_assure||Exercée en qualité de||17"] =
      assure["exerceequalite_assure||Exercée en qualité de||17"] || "Salarié";
    newdata["civilite_assure||Civilité||0"] =
      assure["civilite_assure||Civilité||0"] || "M";
    newdata["prefix_mobile_assure"] = assure["prefix_mobile_assure"] || "+33";
    setDataAdhesion(newdata);
    if (dataAdhesion["adresse_assure||Adresse||3"]) {
      setAddress(true);
    }
    if (dataAdhesion["ville_assure||Ville||5"]) {
      setVille(true);
    }

    // eslint-disable-next-line
  }, [assure]);
  const handleChange = (e, changeType, name) => {
    let prospect = SessionStorageService().getProspect();
    prospect[name] = changeType === "change" ? e.target.value : e;
    if (name === "nom_assure||Nom||1") {
      prospect["name"] = e.target.value;
    }
    if (name === "prenom_assure||Prénom||2") {
      prospect["surname"] = e.target.value;
    }
    SessionStorageService().setProspect(prospect);

    let key = name;
    setDataAdhesion((prev) => {
      return {
        ...prev,
        [key]: changeType === "change" ? e.target.value : e,
      };
    });
  };
  const onSelectPay = (value, option) => {
    handleChange(option.key, "select", "lieu_naissance_principal");
  };
  return dataAdhesion["civilite_assure||Civilité||0"] ? (
    <Form layout="vertical" className={classes.form}>
      <Row gutter={16}>
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <Form.Item label="Civilité" required>
            <Select
              size="large"
              defaultValue={dataAdhesion["civilite_assure||Civilité||0"] || ""}
              options={genderOptions}
              onSelect={(e) => {
                handleChange(e, "select", "civilite_assure||Civilité||0");
              }}
            />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <Form.Item label={"Nom"} required>
            <Input
              defaultValue={SessionStorageService().getProspect()?.name}
              size="large"
              onChange={(e) => handleChange(e, "change", "nom_assure||Nom||1")}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <Form.Item label={"Prénom"} required>
            <Input
              size="large"
              onChange={(e) =>
                handleChange(e, "change", "prenom_assure||Prénom||2")
              }
              defaultValue={SessionStorageService().getProspect()?.surname}
            />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <Form.Item label={"Email"} required>
            <Input
              size="large"
              defaultValue={dataAdhesion["mailassure_text"]}
              onChange={(e) => {
                handleChange(e, "change", "mailassure_text");
                isValidEmail(e.target.value);
              }}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <Form.Item label={"Numéro de téléphone"} required>
            <Space.Compact style={{ width: "100%" }}>
              <Input
                size="large"
                className={classes.antdInput}
                onChange={(e) => {
                  const inputValue = e.target.value;
                  const numericValue = inputValue.replace(/[^+\d]/g, "");
                  handleChange(
                    { ...e, target: { ...e.target, value: numericValue } },
                    "change",
                    "prefix_mobile_assure"
                  );
                }}
                defaultValue={dataAdhesion["prefix_mobile_assure"]}
                style={{ width: "20%" }}
                value={dataAdhesion["prefix_mobile_assure"]}
              />
              <Input
                style={{ width: "80%" }}
                addonAfter={<PhoneOutlined style={{ color: "black" }} />}
                size="large"
                onChange={(e) => {
                  const inputValue = e.target.value;
                  const numericValue = inputValue.replace(/\D/g, "");
                  handleChange(
                    { ...e, target: { ...e.target, value: numericValue } },
                    "change",
                    "suffix_mobile_assure"
                  );
                }}
                value={dataAdhesion["suffix_mobile_assure"]}
                defaultValue={dataAdhesion["suffix_mobile_assure"]}
              />
            </Space.Compact>
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <Form.Item label={"Numéro de voie"} required name="num_voie">
            <Input
              size="large"
              style={{ color: "black" }}
              maxLength={128}
              defaultValue={
                SessionStorageService().getCustomerInfo()[0].address?.num_voie
              }
              onChange={(e) => {
                let newCustomer = SessionStorageService().getCustomerInfo();
                newCustomer[0].address.num_voie = e.target.value;
                SessionStorageService().setCustomerInfo(newCustomer);
                let key = "num_voie";
                setDataAdhesion((prev) => {
                  return {
                    ...prev,
                    [key]: e.target.value,
                  };
                });
              }}
            />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <Form.Item label={"Nom de la voie"} required name="nom_voie">
            <Input
              size="large"
              style={{ color: "black" }}
              maxLength={128}
              defaultValue={
                SessionStorageService().getCustomerInfo()[0].address?.nom_voie
              }
              onChange={(e) => {
                let newCustomer = SessionStorageService().getCustomerInfo();
                newCustomer[0].address.nom_voie = e.target.value;
                SessionStorageService().setCustomerInfo(newCustomer);
                let key = "nom_voie";
                setDataAdhesion((prev) => {
                  return {
                    ...prev,
                    [key]: e.target.value,
                  };
                });
              }}
            />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <Form.Item label={"Complément 1"} name="Complément_1">
            <Input
              size="large"
              style={{ color: "black" }}
              maxLength={128}
              defaultValue={
                SessionStorageService().getCustomerInfo()[0].address
                  ?.address_complement_primaire
              }
              onChange={(e) => {
                let newCustomer = SessionStorageService().getCustomerInfo();
                newCustomer[0].address.address_complement_primaire =
                  e.target.value;
                SessionStorageService().setCustomerInfo(newCustomer);
                let key = "address_complement_primaire";
                setDataAdhesion((prev) => {
                  return {
                    ...prev,
                    [key]: e.target.value,
                  };
                });
              }}
            />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <Form.Item label={"Complément 2"} name="Complément_2">
            <Input
              size="large"
              style={{ color: "black" }}
              maxLength={128}
              defaultValue={
                SessionStorageService().getCustomerInfo()[0].address
                  ?.address_complement_secondaire
              }
              onChange={(e) => {
                let newCustomer = SessionStorageService().getCustomerInfo();
                newCustomer[0].address.address_complement_secondaire =
                  e.target.value;
                SessionStorageService().setCustomerInfo(newCustomer);
                let key = "address_complement_secondaire";
                setDataAdhesion((prev) => {
                  return {
                    ...prev,
                    [key]: e.target.value,
                  };
                });
              }}
            />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <Form.Item label={"Code postal"} required>
            <Input
              size="large"
              style={{ color: "black" }}
              onChange={(e) =>
                handleChange(e, "change", "cp_assure||Code postal||4")
              }
              defaultValue={dataAdhesion["cp_assure||Code postal||4"]}
              disabled
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <Form.Item label={"Ville"} required>
            <Input
              size="large"
              style={{ color: "black" }}
              defaultValue={dataAdhesion["ville_assure||Ville||5"]}
              onChange={(e) =>
                handleChange(e, "change", "ville_assure||Ville||5")
              }
              disabled={ville}
            />
          </Form.Item>
        </Col>

        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <Form.Item label={"Date de naissance"} required>
            <Input
              size="large"
              style={{ color: "black" }}
              defaultValue={moment(
                SessionStorageService()?.getCustomerInfo()[0]?.birth_info?.date,
                "DD/MM/YYYY"
              ).format("DD/MM/YYYY")}
              onChange={(e) => handleChange(e, "change", "")}
              disabled
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <Form.Item
            label={"Pays de naissance"}
            required
            name="lieu_naissance_principal"
          >
            <AutoComplete
              allowClear
              onClear={() => {
                handleChange("", "select", "lieu_naissance_principal");
              }}
              options={filteredOptions}
              size="large"
              onSearch={(text) => {
                if (text !== "") {
                  const filteredOptions = options.filter((option) =>
                    option.value.toLowerCase().includes(text.toLowerCase())
                  );
                  setFilteredOptions(filteredOptions);
                } else {
                  setFilteredOptions(options);
                }
              }}
              onSelect={onSelectPay}
              onBlur={(e) => {
                const isTextFound = Object.values(listPays).some((value) =>
                  value.toLowerCase().includes(e.target.value.toLowerCase())
                );

                if (!isTextFound) {
                  form.setFieldsValue({
                    ...form.getFieldsValue(),
                    lieu_naissance_principal: "",
                  });
                }
              }}
              defaultValue={
                SessionStorageService().getProspect()[
                  "lieu_naissance_principal"
                ]
                  ? SessionStorageService().getProspect()[
                      "lieu_naissance_principal"
                    ]
                  : ""
              }
            />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <Form.Item label={"Régime"} required>
            <Select
              size="large"
              options={regimeOptions}
              onSelect={(e) => handleChange(e, "change", "")}
              defaultValue={
                SessionStorageService().getCustomerInfo()[0].job.regime
              }
              disabled
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <Form.Item label={"N° sécurité sociale"} required>
            <Input
              size="large"
              onChange={(e) => {
                handleChange(
                  e,
                  "change",
                  "num_sec_assure||N° sécurité sodiale||6"
                );
                isValidSS(e.target.value);
                isTrueSS(e.target.value);
              }}
              defaultValue={
                dataAdhesion["num_sec_assure||N° sécurité sodiale||6"]
              }
            />
          </Form.Item>
        </Col>

        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <Form.Item label={"Code organisme"} required>
            <Input
              size="large"
              onChange={(e) => {
                handleChange(e, "change", "code_organisme_principal");
              }}
              // minLength={0}
              // maxLength={9}
              defaultValue={
                SessionStorageService().getProspect()[
                  "code_organisme_principal"
                ]
              }
              suffix={
                <Tooltip
                  placement="top"
                  title={"Référez-vous à l'attestation de votre Carte Vitale"}
                >
                  <InfoCircleOutlined style={{ color: "rgba(0,0,0,.45)" }} />
                </Tooltip>
              }
            />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <Form.Item label={"Etes-vous un jumeau de même sexe ?"} required>
            <Select
              size="large"
              options={jumeauOptions}
              onSelect={(e) =>
                handleChange(e, "select", "jumeau_meme_sexe_principal")
              }
              defaultValue={
                SessionStorageService().getProspect()[
                  "jumeau_meme_sexe_principal"
                ]
              }
            />
          </Form.Item>
        </Col>
        {dataAdhesion["jumeau_meme_sexe_principal"] === "Oui" && (
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <Form.Item label={"Rang"} required>
              <Input
                size="large"
                onChange={(e) => {
                  handleChange(e, "change", "rang_jumeau_meme_sexe_principal");
                }}
                defaultValue={
                  SessionStorageService().getProspect()[
                    "rang_jumeau_meme_sexe_principal"
                  ]
                }
                suffix={
                  <Tooltip
                    placement="top"
                    title={"Référez-vous à l'attestation de votre Carte Vitale"}
                  >
                    <InfoCircleOutlined style={{ color: "rgba(0,0,0,.45)" }} />
                  </Tooltip>
                }
              />
            </Form.Item>
          </Col>
        )}
      </Row>
      <Row gutter={16}></Row>
    </Form>
  ) : (
    <></>
  );
};

export default Assure;
