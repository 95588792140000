import React from "react";
import classes from "./Enfant.module.css";
import moment from "moment";
import SessionStorageService from "../../../utils/sessionStorageService";
import { Form, Select, Input, Row, Col, Tooltip, AutoComplete } from "antd";
import GlobalContext from "../../../contexts/GlobalContext";
import { InfoCircleOutlined } from "@ant-design/icons";
import listPays from "../../../constants/listPays.json";
const Enfant = (props) => {
  const [form] = Form.useForm();
  const {
    IDS,
    idx,
    fils,
    isTrueSSEnfant,
    isValidSS,
    jumeauOptions,
    // dataAdhesion,
    // setDataAdhesion,
  } = props;
  const { attach, setAttach, dataAdhesion, setDataAdhesion } =
    React.useContext(GlobalContext);
  const options = [
    { label: "M", value: "M" },
    { label: "Mme", value: "Mme" },
  ];
  const regimeOptions = [
    { label: "Sécurité sociale", value: "Régime général" },
    { label: "Travailleurs non salariés", value: "Travailleurs non salariés" },
    { label: "Agricole salarié", value: "Agricole salarié" },
    { label: "Agricole exploitant", value: "Agricole exploitant" },
    { label: "Alsace mosellle", value: "Régime local" },
  ];
  const optionsPays = Object.entries(listPays)
    .map(([code, name]) => ({
      value: name,
      key: name,
    }))
    .sort((a, b) => a.value.localeCompare(b.value));
  const [filteredOptions, setFilteredOptions] = React.useState(optionsPays);
  React.useEffect(() => {
    setDataAdhesion((dataAdhesion) => {
      return {
        ...dataAdhesion,
        [IDS.regime]: fils.job.regime,
        [IDS.civilite]:
          SessionStorageService().getProspect()[IDS.civilite] || "M",
        [IDS.DN]: fils.birth_info.date,
        [IDS.nom]: SessionStorageService().getProspect()[IDS.nom] || "",
        [IDS.prenom]: SessionStorageService().getProspect()[IDS.prenom] || "",
        [IDS.Qui]: SessionStorageService().getProspect()[IDS.Qui]
          ? SessionStorageService().getProspect()[IDS.Qui]
          : "Vous",
        [IDS.LIEU_NAISSANCE]: SessionStorageService().getProspect()[
          IDS.LIEU_NAISSANCE
        ]
          ? SessionStorageService().getProspect()[IDS.LIEU_NAISSANCE]
          : "",
        [IDS.nsociale]:
          SessionStorageService().getProspect()[IDS.nsociale] ||
          SessionStorageService().getProspect()[IDS.nsociale] === ""
            ? SessionStorageService().getProspect()[IDS.nsociale]
            : attach[`attach${idx + 1}`]
            ? dataAdhesion["num_sec_assure||N° sécurité sodiale||6"]
            : "",
        [IDS.code_organisme]:
          SessionStorageService().getProspect()[IDS.code_organisme] ||
          SessionStorageService().getProspect()[IDS.code_organisme] === ""
            ? SessionStorageService().getProspect()[IDS.code_organisme]
            : attach[`attach${idx + 1}`]
            ? dataAdhesion["code_organisme_principal"]
            : "",
      };
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const handleChange = (e, changeType, name) => {
    let prospect = SessionStorageService().getProspect();
    prospect[name] = changeType === "change" ? e.target.value : e;
    SessionStorageService().setProspect(prospect);
    let key = name;
    setDataAdhesion((prev) => {
      return {
        ...prev,
        [key]: changeType === "change" ? e.target.value : e,
      };
    });
  };
  const onSelectPay = (value, option) => {
    handleChange(option.key, "select", IDS.LIEU_NAISSANCE);
  };
  const existeConjoint = SessionStorageService()
    ?.getCustomerInfo()
    ?.find((e) => e?.relationship_rank === 2)
    ? true
    : false;

  return (
    dataAdhesion[IDS.DN] && (
      <Form
        layout="vertical"
        form={form}
        className={classes.form}
        style={{ paddingBottom: "1rem" }}
      >
        <h3
          style={{
            textAlign: "center",
            paddingTop: "1rem",
            borderBottom: "1px solid #adb5bd",
          }}
        >
          Enfant {idx + 1}
        </h3>
        <Row gutter={24} style={{ display: "flex", justifyContent: "center" }}>
          <Col style={{ margin: "0.4rem 0", width: "50%" }}>
            <Form.Item
              name={IDS.civilite}
              label="Civilité"
              required
              initialValue={dataAdhesion[IDS.civilite]}
            >
              <Select
                name={IDS.civilite}
                defaultValue={dataAdhesion[IDS.civilite]}
                options={options}
                onChange={(e) => handleChange(e, "select", IDS.civilite)}
                size="large"
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col
            xs={24}
            sm={24}
            md={24}
            lg={12}
            xl={12}
            style={{ margin: "0.4rem 0" }}
          >
            <Form.Item name={IDS.nom} label="Nom" required>
              <Input
                onChange={(e) => handleChange(e, "change", IDS.nom)}
                name={IDS.nom}
                defaultValue={dataAdhesion[IDS.nom]}
                size="large"
              />
            </Form.Item>
          </Col>
          <Col
            xs={24}
            sm={24}
            md={24}
            lg={12}
            xl={12}
            style={{ margin: "0.4rem 0" }}
          >
            <Form.Item required name={IDS.prenom} label="Prénom">
              <Input
                onChange={(e) => handleChange(e, "change", IDS.prenom)}
                name={IDS.prenom}
                defaultValue={dataAdhesion[IDS.prenom]}
                size="large"
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col
            xs={24}
            sm={24}
            md={24}
            lg={12}
            xl={12}
            style={{ margin: "0.4rem 0" }}
          >
            <Form.Item
              name={IDS.DN}
              label="Date de naissance"
              initialValue={moment(dataAdhesion[IDS.DN], "DD/MM/YYYY").format(
                "DD/MM/YYYY"
              )}
              required
            >
              <Input
                onChange={(e) => handleChange(e, "change", IDS.DN)}
                name={IDS.DN}
                defaultValue={moment(dataAdhesion[IDS.DN], "DD/MM/YYYY").format(
                  "DD/MM/YYYY"
                )}
                disabled
                size="large"
                style={{
                  color: "black",
                }}
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={24} lg={12} xl={12}>
            <Form.Item
              label={"Pays de naissance"}
              required
              name={IDS.LIEU_NAISSANCE}
            >
              <AutoComplete
                allowClear
                onClear={() => {
                  handleChange("", "select", IDS.LIEU_NAISSANCE);
                }}
                options={filteredOptions}
                size="large"
                onSearch={(text) => {
                  if (text !== "") {
                    const filteredOptions = optionsPays.filter((option) =>
                      option.value.toLowerCase().includes(text.toLowerCase())
                    );
                    setFilteredOptions(filteredOptions);
                  } else {
                    setFilteredOptions(optionsPays);
                  }
                }}
                onBlur={(e) => {
                  const isTextFound = Object.values(listPays).some((value) =>
                    value.toLowerCase().includes(e.target.value.toLowerCase())
                  );
                  if (!isTextFound) {
                    form.setFieldsValue({
                      ...form.getFieldsValue(),
                      [IDS.LIEU_NAISSANCE]: "",
                    });
                  }
                }}
                onSelect={onSelectPay}
                defaultValue={
                  SessionStorageService().getProspect()[IDS.LIEU_NAISSANCE]
                    ? SessionStorageService().getProspect()[IDS.LIEU_NAISSANCE]
                    : ""
                }
              />
            </Form.Item>
          </Col>
          <Col
            xs={24}
            sm={24}
            md={24}
            lg={12}
            xl={12}
            style={{ margin: "0.4rem 0" }}
          >
            <Form.Item
              name={IDS.regime}
              label="Régime"
              initialValue={dataAdhesion[IDS.regime]}
              required
            >
              <Select
                onChange={(e) => handleChange(e, "select", IDS.regime)}
                name={IDS.regime}
                defaultValue={dataAdhesion[IDS.regime]}
                options={regimeOptions}
                disabled
                size="large"
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={24} lg={12} xl={12}>
            <Form.Item label={"Votre enfant est-il handicapé ?"} required>
              <Select
                size="large"
                onSelect={(e) => handleChange(e, "select", IDS.HANDICAPE)}
                defaultValue={dataAdhesion[IDS.HANDICAPE]}
                options={[
                  { label: "Oui", value: "Oui" },
                  { label: "Non", value: "Non" },
                ]}
              />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={24}>
          <Col
            xs={24}
            sm={24}
            md={24}
            lg={12}
            xl={12}
            style={{ margin: "0.4rem 0" }}
          >
            <Form.Item
              name={IDS.nsociale}
              label="N° sécurité sociale"
              required
              initialValue={dataAdhesion[IDS.nsociale]}
            >
              <Input
                onChange={(e) => {
                  handleChange(e, "change", IDS.nsociale);
                  isValidSS(e.target.value);
                  isTrueSSEnfant(e.target.value);
                }}
                name={IDS.nsociale}
                // defaultValue={IDS.nsociale}
                size="large"
              />
            </Form.Item>
          </Col>

          <Col
            xs={24}
            sm={24}
            md={24}
            lg={12}
            xl={12}
            style={{ margin: "0.4rem 0" }}
          >
            <Form.Item
              name={IDS.code_organisme}
              label="Code organisme"
              required
              initialValue={dataAdhesion[IDS.code_organisme]}
            >
              <Input
                onChange={(e) => {
                  handleChange(e, "change", IDS.code_organisme);
                }}
                name={IDS.code_organisme}
                // value={dataAdhesion[IDS?.code_organisme]}
                // defaultValue={IDS?.code_organisme}
                size="large"
                suffix={
                  <Tooltip
                    placement="top"
                    title={"Référez-vous à l'attestation de votre Carte Vitale"}
                  >
                    <InfoCircleOutlined style={{ color: "rgba(0,0,0,.45)" }} />
                  </Tooltip>
                }
              />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={24}>
          <Col xs={24} sm={24} md={24} lg={12} xl={12}>
            <Form.Item label={"S'agit-il d'un jumeau de même sexe ?"} required>
              <Select
                size="large"
                options={jumeauOptions}
                onSelect={(e) => handleChange(e, "select", IDS.JUMEAU)}
                defaultValue={dataAdhesion[IDS.JUMEAU]}
              />
            </Form.Item>
          </Col>
          {dataAdhesion[IDS.JUMEAU] === "Oui" && (
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <Form.Item label={"Rang"} required>
                <Input
                  size="large"
                  onChange={(e) => {
                    handleChange(e, "change", IDS.Rang);
                  }}
                  defaultValue={dataAdhesion[IDS.Rang]}
                  suffix={
                    <Tooltip
                      placement="top"
                      title={
                        "Référez-vous à l'attestation de votre Carte Vitale"
                      }
                    >
                      <InfoCircleOutlined
                        style={{ color: "rgba(0,0,0,.45)" }}
                      />
                    </Tooltip>
                  }
                />
              </Form.Item>
            </Col>
          )}
          <Col
            xs={24}
            sm={24}
            md={24}
            lg={12}
            xl={12}
            style={{ margin: "0.4rem 0" }}
          >
            <Form.Item
              name={IDS.attach}
              label={
                existeConjoint
                  ? "L'enfant est-il rattaché à vous ou à votre conjoint ?"
                  : "L'enfant est-il rattaché à vous ?"
              }
              initialValue={dataAdhesion[IDS.attach]}
              required
            >
              <Select
                onChange={(e) => handleChange(e, "select", IDS.attach)}
                name={IDS.attach}
                defaultValue={attach[`attach${idx + 1}`] ? "Oui" : "Non"}
                options={[
                  {
                    label: "Oui",
                    value: true,
                  },
                  {
                    label: "Non",
                    value: false,
                  },
                ]}
                size="large"
                onSelect={(e) => {
                  // setAttach(e);
                  setAttach({
                    ...attach,
                    [`attach${idx + 1}`]: e,
                  });
                  if (e) {
                    if (dataAdhesion[IDS.Qui] === "Vous") {
                      handleChange(
                        dataAdhesion["num_sec_assure||N° sécurité sodiale||6"],
                        "",
                        IDS.nsociale
                      );
                      handleChange(
                        dataAdhesion["code_organisme_principal"],
                        "",
                        IDS.code_organisme
                      );
                      // handleChange(
                      //   dataAdhesion[IDS.nsociale],
                      //   "",
                      //   dataAdhesion["num_sec_assure||N° sécurité sodiale||6"]
                      // );

                      form.setFieldsValue({
                        ...form.getFieldsValue(),
                        [IDS.nsociale]:
                          dataAdhesion[
                            "num_sec_assure||N° sécurité sodiale||6"
                          ],
                        [IDS.code_organisme]:
                          dataAdhesion["code_organisme_principal"],
                      });
                    } else {
                      handleChange(
                        dataAdhesion[
                          "num_sec_conjoint||N° sécurité sodiale||6"
                        ],
                        "",
                        IDS.nsociale
                      );
                      handleChange(
                        dataAdhesion["code_organisme_conjoint"],
                        "",
                        IDS.code_organisme
                      );

                      form.setFieldsValue({
                        ...form.getFieldsValue(),
                        [IDS.nsociale]:
                          dataAdhesion[
                            "num_sec_conjoint||N° sécurité sodiale||6"
                          ],
                        [IDS.code_organisme]:
                          dataAdhesion["code_organisme_conjoint"],
                      });
                    }
                  } else {
                    handleChange("", "", IDS.code_organisme);
                    handleChange("", "", IDS.nsociale);
                    handleChange("Vous", "", IDS.Qui);

                    form.setFieldsValue({
                      ...form.getFieldsValue(),
                      [IDS.code_organisme]: "",
                      [IDS.nsociale]: "",
                      [IDS.Qui]: "Vous",
                    });
                  }
                }}
              />
            </Form.Item>
          </Col>
          {attach[`attach${idx + 1}`] && existeConjoint && (
            <Col
              xs={24}
              sm={24}
              md={24}
              lg={12}
              xl={12}
              style={{ margin: "0.4rem 0" }}
            >
              <Form.Item
                name={IDS.Qui}
                label="À qui est-il rattaché ?"
                initialValue={dataAdhesion[IDS.Qui]}
                required
              >
                <Select
                  onChange={(e) => handleChange(e, "select", IDS.Qui)}
                  name={IDS.Qui}
                  // defaultValue={IDS.Qui}
                  options={[
                    {
                      label: "Vous",
                      value: "Vous",
                    },
                    {
                      label: "Votre Conjoint",
                      value: "Votre Conjoint",
                    },
                  ]}
                  onSelect={(e) => {
                    handleChange(e, "", IDS.Qui);
                    if (e === "Vous") {
                      // handleChange(
                      //   dataAdhesion[IDS.nsociale],
                      //   "",
                      //   dataAdhesion["num_sec_assure||N° sécurité sodiale||6"]
                      // );
                      handleChange(
                        dataAdhesion["num_sec_assure||N° sécurité sodiale||6"],
                        "",
                        IDS.nsociale
                      );
                      handleChange(
                        dataAdhesion["code_organisme_principal"],
                        "",
                        IDS.code_organisme
                      );
                      form.setFieldsValue({
                        ...form.getFieldsValue(),
                        [IDS.nsociale]:
                          dataAdhesion[
                            "num_sec_assure||N° sécurité sodiale||6"
                          ],
                        [IDS.code_organisme]:
                          dataAdhesion["code_organisme_principal"],
                      });
                    } else {
                      handleChange(
                        dataAdhesion["code_organisme_conjoint"],
                        "",
                        IDS.code_organisme
                      );
                      // handleChange(
                      //   dataAdhesion[IDS.nsociale],
                      //   "",
                      //   dataAdhesion["num_sec_conjoint||N° sécurité sodiale||6"]
                      // );
                      handleChange(
                        dataAdhesion[
                          "num_sec_conjoint||N° sécurité sodiale||6"
                        ],
                        "",
                        IDS.nsociale
                      );
                      form.setFieldsValue({
                        ...form.getFieldsValue(),
                        [IDS.code_organisme]:
                          dataAdhesion["code_organisme_conjoint"],
                        [IDS.nsociale]:
                          dataAdhesion[
                            "num_sec_conjoint||N° sécurité sodiale||6"
                          ],
                      });
                    }
                  }}
                  size="large"
                />
              </Form.Item>
            </Col>
          )}
        </Row>
      </Form>
    )
  );
};

export default Enfant;
