// src/ErrorPage.js
import React from "react";
import { Row, Col, Button } from "antd";
import styles from "./ErrorPage.module.css";
import ErrorImage from "../../assets/ErrorImage.svg";

function ErrorPage() {
  const search = window.location.search;
  const navigateToAnotherPage = () => {
    window.location.href = `/tarificateur${search}`;
  };

  return (
    <div className={styles.container}>
      <Row justify="center" align="middle" className={styles.row}>
        <Col span={24}>
          <img src={ErrorImage} alt="Error" className={styles.image} />
          <h5 className={styles.oups}>Oups !</h5>
          <h2 className={styles.text}>Quelque chose s'est mal passé...</h2>
          <p className={styles.explication}>
            Nous sommes désolés, mais il semble qu'une erreur inattendue soit
            survenue lors du traitement de votre demande.
          </p>
          <p className={styles.explication}>
            Afin de résoudre ce problème rapidement et de vous offrir la
            meilleure expérience utilisateur, nous vous invitons à contacter
            notre équipe du service technique. Ils sont là pour vous aider et
            vous guider.
          </p>
          <p className={styles.explication}>
            Nous vous prions de bien vouloir accepter nos excuses pour la gêne
            occasionnée. Votre patience et votre compréhension sont grandement
            appréciées.
          </p>
          <Button
            size="large"
            className={styles.button}
            onClick={navigateToAnotherPage}
          >
            Revenir à la page d'acceuil
          </Button>
        </Col>
      </Row>
    </div>
  );
}

export default ErrorPage;
